import { apiRequests } from '../utilities/axios-factory'
import {
  D_FIND_VENDOR_TRANSACTIONS,
  D_FIND_VENDOR_TRANSACTION_TOTAL
} from '../utilities/action-types'

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    [D_FIND_VENDOR_TRANSACTIONS] ({ commit, getters }, { vendor, filter, searchPhone, stUserKeywords }) {
      return apiRequests.get('/v1/passenger-transaction-log/vendor/' + vendor + '/', {
        params: { filter, searchPhone, stUserKeywords },
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    },
    [D_FIND_VENDOR_TRANSACTION_TOTAL] ({ commit, getters }, { vendor, where, searchPhone, stUserKeywords }) {
      return apiRequests.get('/v1/passenger-transaction-log/vendor/' + vendor + '/count/', {
        params: { where, searchPhone, stUserKeywords },
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    }
  }
}
