import mapboxgl from 'mapbox-gl'
import _round from 'lodash.round'
import _random from 'lodash.random'
import maputilities from '@smarttransit/map-utilities'
import turfDistance from '@turf/distance'
import * as turfHelpers from '@turf/helpers'
import turfBooleanWithin from '@turf/boolean-within'
import turfBboxPolygon from '@turf/bbox-polygon'
import turfCircle from '@turf/circle'

const BUS_STOPS_LAYER_ID = 'busstops'
const BUS_STOPS_SOURCE_ID = 'busstopsSource'
const Z_INDEX_LAYER_BASE_ID = 'zIndexLayer'
const OTP_ROUTE_COLOR = '#4c68d2'
const MAPBOX_ROUTE_COLOR = '#90cd51'
const ALTERNATE_ROUTE_COLOR = '#999'

const LAYER_SOURCES = {
  COORDINATES: 'coordinates',
  POLYLINES: 'polylines',
  LEGS: 'legs',
  MAPBOX_LEGS: 'mapbox_legs'
}

class MapboxInstance {
  /**
   * @param {object} options
   * @param {string} accessToken
   * @param {string} options.container
   * @param {object} options.style
   * @param {number} options.zoom
   * @param {array} options.center - [lon,lat]
   * @param {string} options.navigationControl - Can be 'top-left', 'top-right', 'bottom-left' etc. Set to null to hide
   * @param {function} onMapLoadCallback
   */
  constructor ({ accessToken, container, style, zoom = 11, center = [-0.1996, 5.5837], navigationControl }, onMapLoadCallback) {
    if (!mapboxgl.accessToken) {
      mapboxgl.accessToken = accessToken
    }

    let options = {
      container,
      style,
      zoom,
      hash: true,
      center // long,lat
    }

    options.hash = !location.hash || location.hash.indexOf('NaN') === -1
    this.currentMap = new mapboxgl.Map(options)
    console.log('created a new map')

    if (navigationControl === undefined) {
      const nav = new mapboxgl.NavigationControl()
      this.currentMap.addControl(nav, 'top-right')
    } else if (typeof navigationControl === 'string') {
      const nav = new mapboxgl.NavigationControl()
      this.currentMap.addControl(nav, navigationControl)
    }

    this.startEndMarkers = {}
    this.createdMarkers = []
    this.routeLayers = []
    this.destroyCallbacks = []
    this.onClickBusStopCallbacks = {}
    this.onBusStopHoverCallbacks = {}
    this.onBusStopMouseLeaveCallbacks = {}

    const onLoad = () => {
      this.initZIndexLayers()
      this.onMapLoadCompleted = true
      onMapLoadCallback()
      console.log('completed map onLoad')
    }

    this.currentMap.once('load', onLoad)
    this.onDestroy(() => (this.currentMap.off('load', onLoad)))
  }

  setStartMarker ({ lng, lat, markerClassName, markerOptions }, requestRouteCallback) {
    this.setMarker('start', { lng, lat, markerClassName, markerOptions }, requestRouteCallback)
  }

  setEndMarker ({ lng, lat, markerClassName, markerOptions }, requestRouteCallback) {
    this.setMarker('end', { lng, lat, markerClassName, markerOptions }, requestRouteCallback)
  }

  getMapRadius () {
    const bounds = this.currentMap.getBounds()
    const p1 = turfHelpers.point([bounds.getSouthWest().lng, bounds.getSouthWest().lat])
    const p2 = turfHelpers.point([bounds.getNorthEast().lng, bounds.getNorthEast().lat])
    const radius = Math.round(turfDistance(p1, p2, { units: 'kilometers' }) / 2)
    return radius || 1
  }

  getRouteLayers () {
    return this.routeLayers
  }

  getBusStopsByCoordinates (coordinates) {
    let features = []

    if (this.currentMap?.getLayer(BUS_STOPS_LAYER_ID)) {
      console.log('features being searched')
      features = this.currentMap.queryRenderedFeatures(null, { layers: [BUS_STOPS_LAYER_ID] })

      if (features?.length) {
        let foundFeatures = features.filter(o => (_round(o.geometry.coordinates[0], 5) === _round(coordinates[0], 5) && _round(o.geometry.coordinates[1], 5) === _round(coordinates[1], 5)))

        if (!foundFeatures.length) {
          foundFeatures = features.filter(o => (o.properties.lon === coordinates[0] && o.properties.lat === coordinates[1]))
        }

        return foundFeatures
      }
    }

    return features
  }

  clearAllRouteLayers () {
    if (this.routeLayers.length) {
      this.routeLayers.forEach((layer) => {
        this.currentMap.removeLayer(layer.id)
      })
      this.routeLayers = []
    }
  }

  isWithinMapBounds (longLat, radiusFactorInKm) {
    let bounds = this.currentMap.getBounds()
    bounds = [bounds.getSouthWest().lng, bounds.getSouthWest().lat, bounds.getNorthEast().lng, bounds.getNorthEast().lat]
    const polygon = turfBboxPolygon(bounds)
    const circleFromPoint = turfCircle(longLat, radiusFactorInKm)
    return turfBooleanWithin(circleFromPoint, polygon)
  }

  addLayerImage (imageUrl, id) {
    return new Promise((resolve, reject) => {
      this.currentMap.loadImage(imageUrl, (err, image) => {
        if (err) {
          return reject(err)
        }

        try {
          if (!this.currentMap.hasImage(id)) {
            this.currentMap.addImage(id, image)
          }

          resolve(image)
        } catch (err) {
          reject(err)
        }
      })
    })
  }

  /**
   *
   * @param {array} pointsData
   * @param {number} displayAtZoomLevel
   * @param {string} layerId
   * @param {string} sourceId
   * @param {string} imageId
   * @param {number} imageSize
   * @param {number} clusterMaxZoom
   * @param {function} onMouseEnterCallback
   * @param {function} onMouseLeaveCallback
   * @param {function} onClickCallback
   * @param {function} onClusterMouseEnterCallback
   * @param {function} onClusterMouseLeaveCallback
   * @param {function} onClusterClickCallback
   */
  updateImageLayer ({
    pointsData,
    displayAtZoomLevel,
    layerId,
    sourceId,
    imageId,
    imageSize,
    clusterMaxZoom,
    onMouseEnterCallback,
    onMouseLeaveCallback,
    onClickCallback,
    onClusterMouseEnterCallback,
    onClusterMouseLeaveCallback,
    onClusterClickCallback
  }) {
    const hasCluster = !!(clusterMaxZoom || clusterMaxZoom === 0)
    const clusterLayerId = layerId + '-clusters'
    const clusterCountLayerId = layerId + '-clusters-count'
    const trackLayerId = layerId + '-track'

    const sourceObj = {
      type: 'FeatureCollection',
      features: pointsData.map(o => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [o.lng, o.lat]
        },
        properties: o
      }))
    }

    if (this.currentMap.getSource(sourceId)) {
      this.currentMap.getSource(sourceId).setData(sourceObj)
    } else {
      this.currentMap.addSource(sourceId, {
        'type': 'geojson',
        'data': sourceObj,
        cluster: hasCluster,
        clusterMaxZoom: (clusterMaxZoom || clusterMaxZoom === 0 ? clusterMaxZoom : undefined),
        clusterRadius: 50
      })
      // register all the events for this layer
      let onClick
      if (typeof onClickCallback === 'function') {
        onClick = (e) => {
          this.onClickImageLayer(e, layerId, onClickCallback)
        }
        this.currentMap.on('click', layerId, onClick)
        this.onDestroy(() => {
          this.currentMap.off('click', layerId, onClick)
        })
        this.currentMap.on('touchend', layerId, onClick)
        this.onDestroy(() => {
          this.currentMap.off('touchend', layerId, onClick)
        })
      }
      if (onClick || typeof onMouseEnterCallback === 'function') {
        const onMouseEnter = (e) => {
          this.onImageLayerMouseHover(e, layerId, onMouseEnterCallback)
        }
        this.currentMap.on('mouseenter', layerId, onMouseEnter)
        this.onDestroy(() => {
          this.currentMap.off('mouseenter', layerId, onMouseEnter)
        })
      }
      if (onClick || typeof onMouseLeaveCallback === 'function') {
        const onMouseLeave = (e) => {
          this.onImageLayerMouseLeave(e, onMouseLeaveCallback)
        }
        this.currentMap.on('mouseleave', layerId, onMouseLeave)
        this.onDestroy(() => {
          this.currentMap.off('mouseleave', layerId, onMouseLeave)
        })
      }
      let onClusterClick
      if (typeof onClusterClickCallback === 'function') {
        onClusterClick = (e) => {
          this.onClickImageLayer(e, clusterLayerId, onClusterClickCallback)
        }
        this.currentMap.on('click', clusterLayerId, onClusterClick)
        this.onDestroy(() => {
          this.currentMap.off('click', clusterLayerId, onClusterClick)
        })
        this.currentMap.on('touchend', clusterLayerId, onClusterClick)
        this.onDestroy(() => {
          this.currentMap.off('touchend', clusterLayerId, onClusterClick)
        })
      }
      if (onClusterClick || typeof onClusterMouseEnterCallback === 'function') {
        const onMouseEnter = (e) => {
          this.onImageLayerMouseHover(e, clusterLayerId, onClusterMouseEnterCallback)
        }
        this.currentMap.on('mouseenter', clusterLayerId, onMouseEnter)
        this.onDestroy(() => {
          this.currentMap.off('mouseenter', clusterLayerId, onMouseEnter)
        })
      }
      if (onClusterClick || typeof onClusterMouseLeaveCallback === 'function') {
        const onMouseLeave = (e) => {
          this.onImageLayerMouseLeave(e, onClusterMouseLeaveCallback)
        }
        this.currentMap.on('mouseleave', clusterLayerId, onMouseLeave)
        this.onDestroy(() => {
          this.currentMap.off('mouseleave', clusterLayerId, onMouseLeave)
        })
      }
    }
    if (!this.currentMap.getLayer(layerId)) {
      this.currentMap.addLayer({
        id: layerId,
        type: 'symbol',
        source: sourceId,
        filter: hasCluster ? ['!', ['has', 'point_count']] : undefined,
        minzoom: displayAtZoomLevel || 0,
        // 'icon-ignore-placement': true,
        'icon-allow-overlap': true,
        layout: {
          'icon-image': imageId,
          'icon-size': imageSize || 0.1
        }
      }, Z_INDEX_LAYER_BASE_ID + '-2')
    }

    if (!this.currentMap.getLayer(clusterLayerId)) {
      this.currentMap.addLayer({
        id: clusterLayerId,
        type: 'circle',
        source: sourceId,
        filter: ['has', 'point_count'],
        paint: {
          // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
          // with three steps to implement three types of circles:
          //   * Light, 20px circles when point count is less than 30
          //   * Dark, 30px circles when point count is between 30 and 100
          //   * Darker, 40px circles when point count is greater than or equal to 100
          'circle-color': [
            'step',
            ['get', 'point_count'],
            '#728dc2',
            30, // step amount
            '#3b67bb',
            100,
            '#204da2'
          ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            15,
            30, // step amount
            20, // radius in px
            100,
            30
          ]
        }
      }, Z_INDEX_LAYER_BASE_ID + '-2')

      this.currentMap.addLayer({
        id: clusterCountLayerId,
        type: 'symbol',
        source: sourceId,
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['Roboto', 'sans-serif'],
          'text-size': 13
        },
        paint: {
          'text-color': '#fff'
        }
      }, Z_INDEX_LAYER_BASE_ID + '-2')
    }

    if (!this.currentMap.getLayer(trackLayerId)) {
      this.currentMap.addLayer({
        id: trackLayerId,
        type: 'circle',
        source: sourceId,
        filter: ['has', 'tracked'],
        paint: {
          'circle-color': '#fd4f20',
          'circle-radius': 15
        }
      }, layerId)
    }
  }

  removeImageLayer (layerId) {
    if (this.currentMap.getLayer(layerId)) {
      this.currentMap.removeLayer(layerId)
    }
    const clusterLayerId = layerId + '-clusters'
    const clusterCountLayerId = layerId + '-clusters-count'
    const trackLayerId = layerId + '-track'
    if (this.currentMap.getLayer(clusterLayerId)) {
      this.currentMap.removeLayer(clusterLayerId)
    }
    if (this.currentMap.getLayer(clusterCountLayerId)) {
      this.currentMap.removeLayer(clusterCountLayerId)
    }
    if (this.currentMap.getLayer(trackLayerId)) {
      this.currentMap.removeLayer(trackLayerId)
    }
  }

  updateBusStops (busStops, displayAtZoomLevel) {
    const layerId = BUS_STOPS_LAYER_ID
    const sourceId = BUS_STOPS_SOURCE_ID
    const sourceObj = {
      'type': 'FeatureCollection',
      'features': busStops.map(o => ({
        'type': 'Feature',
        'geometry': {
          'type': 'Point',
          'coordinates': [o.lon, o.lat]
        },
        'properties': o
      }))
    }
    if (this.currentMap.getSource(sourceId)) {
      this.currentMap.getSource(sourceId).setData(sourceObj)
    } else {
      this.currentMap.addSource(sourceId, {
        'type': 'geojson',
        'data': sourceObj
      })
      // register all the events for this layer
      this.currentMap.on('click', layerId, this.onClickBusStop.bind(this))
      this.onDestroy(() => {
        this.currentMap.off('click', layerId, this.onClickBusStop)
        this.onClickBusStopCallbacks = {}
      })
      this.currentMap.on('touchend', layerId, this.onClickBusStop.bind(this))
      this.onDestroy(() => {
        this.currentMap.off('touchend', layerId, this.onClickBusStop)
        this.onClickBusStopCallbacks = {}
      })
      this.currentMap.on('mouseenter', layerId, this.onBusStopMouseHover.bind(this))
      this.onDestroy(() => {
        this.currentMap.off('mouseenter', layerId, this.onBusStopMouseHover)
        this.onBusStopHoverCallbacks = {}
      })
      this.currentMap.on('mouseleave', layerId, this.onBusStopMouseLeave.bind(this))
      this.onDestroy(() => {
        this.currentMap.off('mouseleave', layerId, this.onBusStopMouseLeave)
        this.onBusStopMouseLeaveCallbacks = {}
      })
    }
    if (!this.currentMap.getLayer(layerId)) {
      this.currentMap.addLayer({
        'id': layerId,
        'type': 'circle',
        'source': sourceId,
        'minzoom': displayAtZoomLevel,
        'icon-allow-overlap': true,
        'paint': {
          // make circles larger as the user zooms from z12 to z22
          'circle-radius': {
            'base': 2,
            'stops': [[displayAtZoomLevel, 3], [22, 180]]
          },
          // https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-match
          'circle-color': '#fff',
          'circle-stroke-width': 1,
          'circle-stroke-color': '#204da2'
        }
      }, Z_INDEX_LAYER_BASE_ID + '-1')
    }
  }

  initZIndexLayers () {
    const emptySourceId = '_empty'
    this.currentMap.addSource(emptySourceId, {
      type: 'geojson',
      data: { type: 'FeatureCollection', features: [] }
    })

    this.currentMap.addLayer({
      id: Z_INDEX_LAYER_BASE_ID + '-3',
      type: 'symbol',
      source: emptySourceId
    })

    this.currentMap.addLayer({
      id: Z_INDEX_LAYER_BASE_ID + '-2',
      type: 'symbol',
      source: emptySourceId
    }, Z_INDEX_LAYER_BASE_ID + '-3')

    this.currentMap.addLayer({
      id: Z_INDEX_LAYER_BASE_ID + '-1',
      type: 'symbol',
      source: emptySourceId
    }, Z_INDEX_LAYER_BASE_ID + '-2')
    console.log('currentMap layers added')
  }

  createPopup (options) {
    return new mapboxgl.Popup(options)
  }

  onClickBusStop (e) {
    if (this.currentMap && this.currentMap.getLayer(BUS_STOPS_LAYER_ID)) {
      const features = this.currentMap.queryRenderedFeatures(e.point, { layers: [BUS_STOPS_LAYER_ID] })

      if (features && features.length) {
        // console.log('features', e.features)
        if (Object.keys(this.onClickBusStopCallbacks).length) {
          for (const key in this.onClickBusStopCallbacks) {
            const callback = this.onClickBusStopCallbacks[key]
            callback(features[0], e)
          }
        }
      }
    }
  }

  addClickBusStopCallback (callback) {
    const id = Date.now() + '-' + _random(100000)
    this.onClickBusStopCallbacks[id] = callback
    return id
  }

  onBusStopMouseHover (e) {
    if (this.currentMap && this.currentMap.getLayer(BUS_STOPS_LAYER_ID)) {
      const features = this.currentMap.queryRenderedFeatures(e.point, { layers: [BUS_STOPS_LAYER_ID] })

      if (features && features.length) {
        this.currentMap.getCanvas().style.cursor = 'pointer'
        if (Object.keys(this.onBusStopHoverCallbacks).length) {
          for (const key in this.onBusStopHoverCallbacks) {
            const callback = this.onBusStopHoverCallbacks[key]
            callback(features[0], e)
          }
        }
      }
    }
  }

  addBusStopMouseHoverCallback (callback) {
    const id = Date.now() + '-' + _random(100000)
    this.onBusStopHoverCallbacks[id] = callback
    return id
  }

  onBusStopMouseLeave () {
    this.currentMap.getCanvas().style.cursor = ''
    if (Object.keys(this.onBusStopMouseLeaveCallbacks).length) {
      for (const key in this.onBusStopMouseLeaveCallbacks) {
        const callback = this.onBusStopMouseLeaveCallbacks[key]
        callback()
      }
    }
  }

  addBusStopMouseLeaveCallback (callback) {
    const id = Date.now() + '-' + _random(100000)
    this.onBusStopMouseLeaveCallbacks[id] = callback
    return id
  }

  onClickImageLayer (e, layerId, callback) {
    if (this.currentMap && this.currentMap.getLayer(layerId)) {
      const features = this.currentMap.queryRenderedFeatures(e.point, { layers: [layerId] })
      if (features?.length) {
        // console.log('features', e.features)
        callback(e, features)
      }
    }
  }

  onImageLayerMouseHover (e, layerId, callback) {
    if (this.currentMap && this.currentMap.getLayer(layerId)) {
      const features = this.currentMap.queryRenderedFeatures(e.point, { layers: [layerId] })
      if (features && features.length) {
        this.currentMap.getCanvas().style.cursor = 'pointer'
        if (typeof callback === 'function') {
          callback(e, features)
        }
      }
    }
  }

  onImageLayerMouseLeave (e, callback) {
    this.currentMap.getCanvas().style.cursor = ''
    if (typeof callback === 'function') {
      callback(e)
    }
  }

  destroyListeners () {
    if (this.destroyCallbacks.length) {
      this.destroyCallbacks.forEach((callback) => {
        callback()
      })
      this.destroyCallbacks = []
    }
  }

  onDestroy (callback) {
    this.destroyCallbacks.push(callback)
  }

  showLayer (layerId) {
    this.currentMap.setLayoutProperty(layerId, 'visibility', 'visible')
    const layer = this.currentMap.getLayer(layerId)
    let lineColor
    if (layer.metadata.index === 0) {
      lineColor = layer.metadata.routeIdList && layer.metadata.routeIdList.indexOf('mapbox') > -1 ? MAPBOX_ROUTE_COLOR : OTP_ROUTE_COLOR
    } else {
      lineColor = ALTERNATE_ROUTE_COLOR
    }
    this.currentMap.setPaintProperty(layerId, 'line-color', lineColor)
  }

  hideLayer (layerId) {
    this.currentMap.setLayoutProperty(layerId, 'visibility', 'none')
  }

  generateRouteLines (routeItineraries) {
    // this.clearAllRouteLayers()
    let layerIds = []

    routeItineraries.forEach((itinerary, key) => {
      let routeLegs, mapOfCoordinates, mapOfPolylines, props, source

      if (Array.isArray(itinerary)) {
        mapOfCoordinates = itinerary
        source = LAYER_SOURCES.COORDINATES
      } else if (typeof itinerary === 'string') {
        mapOfPolylines = itinerary
        source = LAYER_SOURCES.POLYLINES
      } else if (itinerary && 'legs' in itinerary) {
        routeLegs = itinerary.legs
        source = LAYER_SOURCES.LEGS
      } else {
        throw new Error('invalid itinerary in routeItineraries')
      }

      const lineColor = key === 0 ? OTP_ROUTE_COLOR : ALTERNATE_ROUTE_COLOR
      let layer = maputilities.generateRouteLayer(null, lineColor)

      if (routeLegs) {
        props = { ...itinerary }
        delete props.legs
      }

      props = Object.assign({ index: key, mode: 'BUS', source }, props || {})
      layer.metadata = props

      layer.source.data = {
        type: 'FeatureCollection',
        features: []
      }

      let walkLayer = maputilities.generateRouteLayer(null, lineColor)
      walkLayer.paint['line-dasharray'] = [4, 2]
      walkLayer.metadata = props ? { ...props } : {}
      walkLayer.metadata.mode = 'WALK'

      walkLayer.source.data = {
        type: 'FeatureCollection',
        features: []
      }

      if (routeLegs) {
        routeLegs.forEach((leg) => {
          const mapboxGeoJSON = maputilities.convertPolylineToGeoJSON(leg.legGeometry.points)

          if (mapboxGeoJSON) {
            let props = { ...leg }
            delete props.steps

            const geoJSON = {
              type: 'Feature',
              geometry: mapboxGeoJSON,
              properties: props
            }

            if (leg.mode.toLowerCase() === 'walk') {
              walkLayer.source.data.features.push(geoJSON)
            } else {
              layer.source.data.features.push(geoJSON)
            }
          }
        })
      } else if (mapOfCoordinates) {
        const mapboxGeoJSON = { type: 'LineString', coordinates: mapOfCoordinates }

        const geoJSON = {
          type: 'Feature',
          geometry: mapboxGeoJSON
        }

        layer.source.data.features.push(geoJSON)
      } else if (mapOfPolylines) {
        const mapboxGeoJSON = maputilities.convertPolylineToGeoJSON(mapOfPolylines)

        const geoJSON = {
          type: 'Feature',
          geometry: mapboxGeoJSON
        }

        layer.source.data.features.push(geoJSON)
      }

      layer.layout = {
        visibility: 'visible'
      }

      let layerIdGroup = []

      if (walkLayer.source.data.features.length) {
        this.currentMap.addLayer(walkLayer, Z_INDEX_LAYER_BASE_ID + '-1')
        this.routeLayers.push(walkLayer)
        layerIdGroup.push(walkLayer.id)
      }

      if (layer.source.data.features.length) {
        this.currentMap.addLayer(layer, Z_INDEX_LAYER_BASE_ID + '-1')
        this.routeLayers.push(layer)
        layerIdGroup.push(layer.id)
      }

      if (layerIdGroup.length) {
        layerIds.push(layerIdGroup)
      }
    })

    return layerIds
  }

  getRouteLayersBySource (source) {
    if (!source) {
      throw new Error('Invalid source key')
    }

    return this.routeLayers.filter(o => (o.metadata.source === source))
  }

  generateRouteLinesFromMapbox (routeItineraries) {
    console.log('mapbox routes', routeItineraries)
    let layerIds = []
    routeItineraries.forEach((itinerary, index) => {
      let layer = maputilities.generateRouteLayer(null, index === 0 ? MAPBOX_ROUTE_COLOR : ALTERNATE_ROUTE_COLOR)
      layerIds.push(layer.id)
      let props = { ...itinerary }
      delete props.legs
      delete props.geometry
      layer.metadata = Object.assign({ index, source: LAYER_SOURCES.MAPBOX_LEGS }, props)

      layer.source.data = {
        type: 'FeatureCollection',
        features: []
      }

      layer.source.data.features.push({
        type: 'Feature',
        geometry: itinerary.geometry
      })

      layer.layout = {
        visibility: 'visible'
      }

      this.currentMap.addLayer(layer)
      this.routeLayers.push(layer)
    })
    return layerIds
  }

  removeLayer (layerId) {
    if (this.currentMap.getLayer(layerId)) {
      this.currentMap.removeLayer(layerId)
    }

    if (this.routeLayers.length && this.routeLayers.find((o) => (o.id === layerId))) {
      this.routeLayers = this.routeLayers.filter((o) => (o.id !== layerId))
    }
  }

  setMarker (markerContext, { lng, lat, markerClassName, markerOptions, id }, requestRouteCallback) {
    let lngLat = [Number(lng), Number(lat)]

    if (lngLat.length) {
      const options = markerOptions ? { ...markerOptions } : {}

      if (markerClassName) {
        const el = document.createElement('div')
        el.className = markerClassName
        options.element = el
      }

      const marker = new mapboxgl.Marker(options).setLngLat(lngLat).addTo(this.currentMap)

      if (['start', 'end'].includes(markerContext)) {
        id = id || 'm-' + markerContext
        let zoom = location.hash.replace('#', '').split('/')
        console.log('hash fragments', zoom)

        if (zoom && zoom.length >= 3) {
          zoom = Number(zoom[0])
        } else {
          zoom = 11.72
        }

        if (markerContext === 'start') {
          if (this.startEndMarkers.start) {
            this.startEndMarkers.start.remove()
          }

          this.startEndMarkers.start = marker
        } else if (markerContext === 'end') {
          if (this.startEndMarkers.end) {
            this.startEndMarkers.end.remove()
          }

          this.startEndMarkers.end = marker
        }

        this.currentMap.panTo(lngLat)
        this.currentMap.setZoom(zoom)

        if (typeof requestRouteCallback === 'function' && this.startEndMarkers.start && this.startEndMarkers.end) {
          // need to clear the route layer here if any exist
          requestRouteCallback({ lng: this.startEndMarkers.start.getLngLat().lng, lat: this.startEndMarkers.start.getLngLat().lat },
            { lng: this.startEndMarkers.end.getLngLat().lng, lat: this.startEndMarkers.end.getLngLat().lat })
        }
      }

      this.createdMarkers[id || 'm-' + Date.now()] = marker
    }
  }
}

MapboxInstance.LAYER_SOURCES = LAYER_SOURCES

export {
  MapboxInstance
}
