import '@fortawesome/fontawesome-pro/css/all.css'
import Vue from 'vue'
import {
  Vuetify,
  VApp,
  VNavigationDrawer,
  VFooter,
  VList,
  VBtn,
  VIcon,
  VGrid,
  VToolbar,
  VCard,
  VChip,
  VForm,
  VTextField,
  VSelect,
  VSwitch,
  VTimePicker,
  VDatePicker,
  VMenu,
  VBtnToggle,
  VAlert,
  VSnackbar,
  VAutocomplete,
  VDivider,
  VDialog,
  VDataTable,
  VTooltip,
  VTabs,
  VTextarea,
  VCombobox,
  VProgressLinear,
  VProgressCircular,
  VPagination,
  VBreadcrumbs,
  VAvatar,
  VCheckbox,
  transitions
} from 'vuetify'
import 'vuetify/src/stylus/app.styl'
import { Resize } from 'vuetify/es5/directives'
import { VRadio, VRadioGroup } from 'vuetify/es5/components'

Vue.use(Vuetify, {
  components: {
    VApp,
    VNavigationDrawer,
    VFooter,
    VList,
    VBtn,
    VIcon,
    VGrid,
    VToolbar,
    VCard,
    VChip,
    VForm,
    VTextField,
    VSelect,
    VRadio,
    VRadioGroup,
    VSwitch,
    VTimePicker,
    VDatePicker,
    VMenu,
    VBtnToggle,
    VAlert,
    VSnackbar,
    VAutocomplete,
    VDivider,
    VDialog,
    VDataTable,
    VTooltip,
    VTabs,
    VTextarea,
    VCombobox,
    VProgressLinear,
    VProgressCircular,
    VPagination,
    VBreadcrumbs,
    VAvatar,
    VCheckbox,
    transitions
  },
  directives: {
    Resize
  },
  theme: {
    primary: '#204da2',
    secondary: '#67be7b',
    accent: '#82B1FF',
    error: '#f65122',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FFC107'
  },
  iconfont: 'fal',
  icons: {
    'cancel': 'fal fa-ban',
    'menu': 'fal fa-ellipsis-v-alt'
  }
})
