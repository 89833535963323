import { apiRequests } from '../utilities/axios-factory'
import {
  D_GET_TRANSPORTATION_EQUIPMENT,
  D_FIND_TRANSPORTATION_EQUIPMENT,
  D_FIND_TRANSPORTATION_EQUIPMENT_TOTAL,
  D_CREATE_TRANSPORTATION_EQUIPMENT,
  D_UPDATE_TRANSPORTATION_EQUIPMENT,
  D_DELETE_TRANSPORTATION_EQUIPMENT
} from '../utilities/action-types'
// import _ from 'lodash'

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    [D_GET_TRANSPORTATION_EQUIPMENT] ({ commit, getters }, { id, filter }) {
      return apiRequests.get('/v1/transportation-equipment/' + id + '/', {
        params: { filter },
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    },
    [D_FIND_TRANSPORTATION_EQUIPMENT] ({ commit, getters }, filter) {
      return apiRequests.get('/v1/transportation-equipment/join/', {
        params: { filter },
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    },
    [D_FIND_TRANSPORTATION_EQUIPMENT_TOTAL] ({ commit, getters }, { where, join }) {
      return apiRequests.get('/v1/transportation-equipment/count/join/', {
        params: { where, join },
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    },
    [D_CREATE_TRANSPORTATION_EQUIPMENT] ({ commit, getters }, data) {
      return apiRequests.post('/v1/transportation-equipment/registration', data, {
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    },
    [D_UPDATE_TRANSPORTATION_EQUIPMENT] ({ commit, getters }, data) {
      return apiRequests.patch('/v1/transportation-equipment/' + data.id + '/', data, {
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    },
    [D_DELETE_TRANSPORTATION_EQUIPMENT] ({ commit, getters }, { id }) {
      return apiRequests.delete('/v1/transportation-equipment/' + id + '/', {
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    }
  }
}
