<template>
  <div class="transportation-fares">
    <br />
    <h1 style="text-align: center;">Passenger Transactions</h1>
    <form @submit.prevent="searchTransactions">
      <v-layout row wrap>
        <v-flex sm3 pa-2>
          <v-text-field
            solo
            v-model="searchKeywords"
            append-icon="fal fa-search"
            :rules="[v => !v || v.length >= 2 || 'Min 2 characters']"
            :disabled="apiInProgress"
            type="search"
            placeholder="Search by ticket id or last 7 digits of ticket"
            @keyup.enter="searchTransactions"
            @click:append="searchTransactions"
          />
        </v-flex>
        <v-flex sm2 pa-2>
          <v-text-field
            solo
            clearable
            v-model="searchPhone"
            append-icon="fal fa-phone"
            :rules="[v => !v || (/[\s\d-()+]+/.test(v) && v.length >= 2) || 'Please provide only numbers or \'+\' (min 2 digits)']"
            :disabled="apiInProgress"
            type="search"
            placeholder="Search by phone"
            @keyup.enter="searchTransactions"
            @click:append="searchTransactions"
          />
        </v-flex>
        <v-flex sm4>
          <v-layout>
            <v-flex xs6 pa-2>
              <v-menu
                v-model="dateFromMenu"
                :close-on-content-click="false"
                full-width
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    prepend-icon="fal fa-calendar"
                    :value="computedDateFromFormatted"
                    @click:clear="dateFrom = null"
                    clearable
                    label="Date From"
                    readonly
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  no-title
                  :max="computedDateFromMax"
                  v-model="dateFrom"
                  @change="dateFromMenu = false"
                />
              </v-menu>
            </v-flex>
            <v-flex xs6 pa-2>
              <v-menu
                v-model="dateToMenu"
                :close-on-content-click="false"
                full-width
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    prepend-icon="fal fa-calendar"
                    :value="computedDateToFormatted"
                    @click:clear="dateTo = null"
                    clearable
                    label="Date To"
                    readonly
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  no-title
                  :min="computedDateToMin"
                  v-model="dateTo"
                  @change="dateToMenu = false"
                />
              </v-menu>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex sm2 pa-2>
          <v-select
            :items="vendors"
            label="Vendor"
            :value="selectedVendor"
            @change="onFilterByVendor"
          />
        </v-flex>
        <v-flex sm1 pa-2>
          <v-btn small fab @click="searchTransactions"><v-icon small>fal fa-filter</v-icon></v-btn>
        </v-flex>
      </v-layout>
    </form>
    <v-data-table
      :headers="headers"
      :items="transactions"
      :pagination.sync="pagination"
      :total-items="totalTransactions"
      :loading="apiInProgress"
      :rows-per-page-items="[10, 25, 50]"
      @update:pagination="onPagination"
      class="elevation-1"
      must-sort
    >
      <template v-slot:items="props">
        <tr @click="viewMetadata(props.item)">
          <td :title="props.item.phoneLabel">
            {{props.item.phoneLabel}}
          </td>
          <td class="text-xs-right" :title="props.item.stUser.email">
            <v-text-field
              class="text-xs-right text-field--flat"
              :value="props.item.stUser.email"
              readonly
              hide-details
            />
          </td>
          <td class="text-xs-right" v-html="props.item.amountLabel" />
          <td class="text-xs-right" v-html="props.item.profileBalanceBeforeLabel" />
          <td class="text-xs-right" v-html="props.item.profileBalanceAfterLabel" />
          <td class="text-xs-right">
            <span v-if="props.item.logTypeIcon"><v-icon small>{{props.item.logTypeIcon}}</v-icon> &nbsp;</span>
            <small v-html="props.item.logType" />
          </td>
          <td class="text-xs-right">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon small v-on="on">{{props.item.logStatusIcon}}</v-icon>
              </template>
              <span v-html="props.item.logStatusTooltip" />
            </v-tooltip>
          </td>
          <td class="text-xs-right" v-html="props.item.dateUpdatedLabel" />
        </tr>
      </template>
    </v-data-table>
    <v-dialog
      transition="dialog-bottom-transition"
      scrollable
      max-width="600"
      v-model="metadataLoaded">
      <v-card v-if="selectedMetadata">
        <v-card-title class="headline grey lighten-2 text-xs-center">
          {{selectedMetadata.phoneLabel}} Transaction Metadata
        </v-card-title>
        <v-card-text>
          <v-layout row wrap>
            <v-flex sm4 pr-1 class="text-xs-right">
              <strong>dateCreated</strong>
            </v-flex>
            <v-flex sm8 pl-1>
              <div v-html="selectedMetadata.dateCreatedLabel"></div>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex sm4 pr-1 class="text-xs-right">
              <strong>dateUpdated</strong>
            </v-flex>
            <v-flex sm8 pl-1>
              <div v-html="selectedMetadata.dateUpdatedLabel"></div>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex sm4 pr-1 class="text-xs-right">
              <strong>logType</strong>
            </v-flex>
            <v-flex sm8 pl-1>
              <div v-html="selectedMetadata.logType"></div>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex sm4 pr-1 class="text-xs-right">
              <strong>logStatus</strong>
            </v-flex>
            <v-flex sm8 pl-1>
              <div v-html="selectedMetadata.logStatus"></div>
            </v-flex>
          </v-layout>
          <v-layout row wrap v-for="(value, propertyName, index) in selectedMetadata.logMetadataParsed" :key="`metadata${index}`">
            <v-flex sm4 pr-1 class="text-xs-right">
              <strong>{{ propertyName }}</strong>
            </v-flex>
            <v-flex sm8 pl-1>
              <div v-html="value"></div>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="layout justify-end">
          <v-btn @click="metadataLoaded = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import src from './src'
export default src
</script>
