<template>
  <div>
    <v-btn
      :loading="isDownloading"
      :disabled="isDownloading || currentDisabled"
      v-bind="currentButtonAttributes"
      @click="getDownloadData()">
      <v-icon left v-if="currentLabelIcon" v-text="currentLabelIcon" />
      <span v-html="currentLabel" />
    </v-btn>
    <a v-if="enableDownloadStub" ref="downloadStub" target="_blank" :href="downloadBlob" :download="currentFileName" class="download-stub">
      <img v-if="currentDownloadType && currentDownloadType.indexOf('image/') > -1" :src="downloadBlob" alt="" />
    </a>
    <v-dialog v-model="modalDownloadError" max-width="500px">
      <v-card>
        <v-card-title class="text-xs-center">
          Download Error
        </v-card-title>
        <v-card-text style="overflow-y: scroll; max-height: 300px;" class="px-2 pb-2">
          <div v-if="downloadError" v-html="downloadError" />
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" flat @click="modalDownloadError = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  a.download-stub {
    opacity: 0;
    height: 0;
    line-height: 0;
    width: 0;
  }
</style>

<script>
import src from './src'
export default src
</script>
