export const D_RENEW_TOKEN = 'dispatchRenewToken'

export const D_SIGN_OUT = 'dispatchSignOut'
export const D_SIGN_IN = 'dispatchSignIn'
export const D_GET_SIGNED_IN_USER = 'dispatchGetSignedInUser'
export const D_FORGOT_PASSWORD_REQUEST = 'dispatchForgotPasswordRequest'
export const D_FORGOT_PASSWORD_SUBMIT = 'dispatchPasswordRequestSubmit'

export const D_GET_FROM_CACHE = 'dispatchGetCache'
export const D_GET_FROM_USER_CACHE = 'dispatchGetUserCache'
export const D_SET_TO_CACHE = 'dispatchSetCache'
export const D_SET_TO_USER_CACHE = 'dispatchSetUserCache'
export const D_GET_FROM_BROWSER_SETTING = 'dispatchGetBrowserSetting'

export const D_ADD_SITE_ALERT = 'dispatchSiteAlert'

export const D_GET_BUS_STOP_DATA = 'dispatchGetBusStopData'
export const D_GET_MAP_REGIONS = 'dispatchGetMapRegions'

export const D_GET_COUNTRY_CURRENCY = 'dispatchGetCountryCurrency'
export const D_FIND_COUNTRY_CURRENCIES = 'dispatchFindCountryCurrencies'

export const D_FIND_ROUTE_FARES = 'dispatchFindRouteFares'
export const D_GET_ROUTE_FARE_TOTAL = 'dispatchGetRouteFareTotal'
export const D_GET_ROUTE_FARE = 'dispatchGetRouteFareById'
export const D_CREATE_ROUTE_FARE = 'dispatchCreateRouteFare'
export const D_UPDATE_ROUTE_FARE = 'dispatchUpdateRouteFare'
export const D_UPDATE_ROUTE_FARE_PERCENT = 'dispatchUpdateRouteFarePercent'
export const D_DELETE_ROUTE_FARE = 'dispatchDeleteRouteFare'

export const D_GET_ROUTE = 'dispatchGetRoute'
export const D_GET_ROUTE_BY_ROUTE_ID_LIST = 'dispatchGetRouteByRouteIdList'

export const D_FIND_TRANSPORTATION_OWNERS = 'dispatchFindTransportationOwners'
export const D_FIND_TRANSPORTATION_OWNERS_TOTAL = 'dispatchFindTransportationOwnersTotal'
export const D_FIND_ARCHIVED_TRANSPORTATION_OWNERS = 'dispatchFindArchivedTransportationOwners'
export const D_FIND_ARCHIVED_TRANSPORTATION_OWNERS_TOTAL = 'dispatchFindArchivedTransportationOwnersTotal'
export const D_GET_TRANSPORTATION_OWNER = 'dispatchGetTransportationOwnerById'
export const D_VERIFY_TRANSPORTATION_OWNER_REGISTRATION = 'dispatchVerifyOwnerRegistration'
export const D_ARCHIVE_TRANSPORTATION_OWNER = 'dispatchArchiveTransportationOwner'
export const D_UNARCHIVE_TRANSPORTATION_OWNER = 'dispatchUnArchiveTransportationOwner'
export const D_DELETE_TRANSPORTATION_OWNER = 'dispatchDeleteTransportationOwner'
export const D_REGISTER_TRANSPORTATION_OWNER = 'dispatchRegisterTransportationOwner'
export const D_UPDATE_TRANSPORTATION_OWNER = 'dispatchUpdateTransportationOwner'

export const D_GET_USER = 'dispatchGetUserById'
export const D_FIND_STAFF = 'dispatchFindStaff'
export const D_UPDATE_STAFF = 'dispatchUpdateStaff'
export const D_DELETE_STAFF = 'dispatchDeleteStaff'
export const D_UNDO_DELETE_STAFF = 'dispatchUndoDeleteStaff'
export const D_FIND_STAFF_TOTAL = 'dispatchFindStaffTotal'
export const D_FIND_INVITED_STAFF = 'dispatchInvitedFindStaff'
export const D_FIND_INVITED_STAFF_TOTAL = 'dispatchInvitedFindStaffTotal'
export const D_FIND_TRANSPORT_USERS = 'dispatchFindTransportUsers'
export const D_FIND_TRANSPORT_USERS_TOTAL = 'dispatchFindTransportUsersTotal'
export const D_FIND_INVITED_TRANSPORT_USERS = 'dispatchFindInvitedTransportUsers'
export const D_FIND_INVITED_TRANSPORT_USERS_TOTAL = 'dispatchFindInvitedTransportUsersTotal'
export const D_VERIFY_TRANSPORT_USER_INVITE = 'dispatchVerifyTransportUserInvite'

export const D_RESEND_INVITE_TRANSPORT_USER = 'dispatchResendInviteTransportUser'
export const D_INVITE_TRANSPORT_USER = 'dispatchInviteTransportUser'
export const D_RESEND_INVITE_STAFF = 'dispatchResendInviteStaff'
export const D_INVITE_STAFF = 'dispatchInviteStaff'
export const D_VERIFY_STAFF_INVITE = 'dispatchVerifyStaffInvite'
export const D_DELETE_INVITE_STAFF = 'dispatchDeleteInvitedStaff'
export const D_DELETE_INVITE_TRANSPORT_USER = 'dispatchDeleteInvitedTransportUser'

export const D_GET_TRANSPORTATION_PROFILE = 'dispatchGetTransportProfile'
export const D_FIND_TRANSPORTATION_PROFILES = 'dispatchFindTransportProfiles'
export const D_FIND_TRANSPORTATION_PROFILES_TOTAL = 'dispatchFindTransportProfilesTotal'
export const D_CREATE_TRANSPORTATION_PROFILE = 'dispatchCreateTransportProfile'
export const D_UPDATE_TRANSPORTATION_PROFILE = 'dispatchUpdateTransportProfile'
export const D_DELETE_TRANSPORTATION_PROFILE = 'dispatchDeleteTransportProfile'

export const D_FIND_TRANSPORTATION_OWNER_USER = 'dispatchFindTransportOwnerUser'

export const D_GET_TRANSPORTATION_EQUIPMENT = 'dispatchGetTransportEquipment'
export const D_FIND_TRANSPORTATION_EQUIPMENT = 'dispatchFindTransportEquipment'
export const D_FIND_TRANSPORTATION_EQUIPMENT_TOTAL = 'dispatchFindTransportEquipmentTotal'
export const D_CREATE_TRANSPORTATION_EQUIPMENT = 'dispatchCreateTransportEquipment'
export const D_UPDATE_TRANSPORTATION_EQUIPMENT = 'dispatchUpdateTransportEquipment'
export const D_DELETE_TRANSPORTATION_EQUIPMENT = 'dispatchDeleteTransportEquipment'

export const D_CREATE_PROFILE_ROUTE = 'dispatchCreateTransportProfileRoute'
export const D_UPDATE_PROFILE_ROUTE = 'dispatchUpdateTransportProfileRoute'
export const D_DELETE_PROFILE_ROUTE = 'dispatchDeleteTransportProfileRoute'
export const D_FIND_PROFILE_ROUTES = 'dispatchFindTransportProfileRoute'
export const D_FIND_PROFILE_ROUTES_TOTAL = 'dispatchFindTransportProfileRouteTotal'
export const D_GET_PROFILE_ROUTE = 'dispatchGetTransportProfileRoute'

export const D_UPDATE_BUS_STOP = 'dispatchUpdateBusStop'
export const D_FIND_BUS_STOPS = 'dispatchFindBusStops'
export const D_FIND_BUS_STOPS_TOTAL = 'dispatchFindBusStopsTotal'
export const D_GET_BUS_STOP_JOB_STATUS = 'dispatchGetBusStopJobStatus'
export const D_STOP_BUS_STOP_JOB = 'dispatchStopBusStopJob'
export const D_START_BUS_STOP_JOB = 'dispatchStartBusStopJob'
export const D_CLEAR_BUS_STOP_JOB_ERROR = 'dispatchClearBusStopJobError'

export const D_FIND_VENDOR_TRANSACTIONS = 'dispatchFindVendorTransactions'
export const D_FIND_VENDOR_TRANSACTION_TOTAL = 'dispatchFindVendorTransactionTotal'

export const D_START_AUDIT_ROUTE_FARE_JOB = 'dispatchStartAuditRouteFareJob'
export const D_STOP_AUDIT_ROUTE_FARE_JOB = 'dispatchStopAuditRouteFareJob'
export const D_CLEAR_AUDIT_ROUTE_FARE_JOB_ERROR = 'dispatchClearRouteFareJobError'
export const D_GET_AUDIT_ROUTE_FARE_JOB_STATUS = 'dispatchAuditRouteFareJobStatus'

export default {
  D_RENEW_TOKEN,
  D_SIGN_OUT,
  D_SIGN_IN,
  D_GET_SIGNED_IN_USER,
  D_FORGOT_PASSWORD_REQUEST,
  D_FORGOT_PASSWORD_SUBMIT,
  D_ADD_SITE_ALERT,

  D_GET_FROM_CACHE,
  D_GET_FROM_USER_CACHE,
  D_SET_TO_CACHE,
  D_SET_TO_USER_CACHE,
  D_GET_FROM_BROWSER_SETTING,

  D_GET_BUS_STOP_DATA,
  D_GET_MAP_REGIONS,

  D_GET_COUNTRY_CURRENCY,
  D_FIND_COUNTRY_CURRENCIES,

  D_GET_ROUTE_FARE,
  D_FIND_ROUTE_FARES,
  D_GET_ROUTE_FARE_TOTAL,
  D_CREATE_ROUTE_FARE,
  D_UPDATE_ROUTE_FARE,
  D_DELETE_ROUTE_FARE,

  D_GET_ROUTE,
  D_GET_ROUTE_BY_ROUTE_ID_LIST,

  D_FIND_TRANSPORTATION_OWNERS,
  D_FIND_TRANSPORTATION_OWNERS_TOTAL,
  D_FIND_ARCHIVED_TRANSPORTATION_OWNERS,
  D_FIND_ARCHIVED_TRANSPORTATION_OWNERS_TOTAL,
  D_ARCHIVE_TRANSPORTATION_OWNER,
  D_UNARCHIVE_TRANSPORTATION_OWNER,
  D_DELETE_TRANSPORTATION_OWNER,
  D_REGISTER_TRANSPORTATION_OWNER,
  D_UPDATE_TRANSPORTATION_OWNER,
  D_GET_TRANSPORTATION_OWNER,
  D_VERIFY_TRANSPORTATION_OWNER_REGISTRATION,

  D_GET_USER,
  D_FIND_STAFF,
  D_FIND_STAFF_TOTAL,
  D_UPDATE_STAFF,
  D_DELETE_STAFF,
  D_UNDO_DELETE_STAFF,
  D_FIND_TRANSPORT_USERS,
  D_FIND_TRANSPORT_USERS_TOTAL,
  D_FIND_INVITED_TRANSPORT_USERS,
  D_FIND_INVITED_TRANSPORT_USERS_TOTAL,
  D_VERIFY_TRANSPORT_USER_INVITE,

  D_RESEND_INVITE_STAFF,
  D_INVITE_STAFF,
  D_VERIFY_STAFF_INVITE,
  D_RESEND_INVITE_TRANSPORT_USER,
  D_INVITE_TRANSPORT_USER,

  D_GET_TRANSPORTATION_PROFILE,
  D_FIND_TRANSPORTATION_PROFILES,
  D_FIND_TRANSPORTATION_PROFILES_TOTAL,
  D_CREATE_TRANSPORTATION_PROFILE,
  D_UPDATE_TRANSPORTATION_PROFILE,
  D_DELETE_TRANSPORTATION_PROFILE,

  D_FIND_TRANSPORTATION_OWNER_USER,

  D_GET_TRANSPORTATION_EQUIPMENT,
  D_FIND_TRANSPORTATION_EQUIPMENT,
  D_FIND_TRANSPORTATION_EQUIPMENT_TOTAL,
  D_CREATE_TRANSPORTATION_EQUIPMENT,
  D_UPDATE_TRANSPORTATION_EQUIPMENT,
  D_DELETE_TRANSPORTATION_EQUIPMENT,

  D_CREATE_PROFILE_ROUTE,
  D_UPDATE_PROFILE_ROUTE,
  D_DELETE_PROFILE_ROUTE,
  D_FIND_PROFILE_ROUTES,
  D_FIND_PROFILE_ROUTES_TOTAL,
  D_GET_PROFILE_ROUTE,

  D_UPDATE_BUS_STOP,
  D_FIND_BUS_STOPS,
  D_FIND_BUS_STOPS_TOTAL,
  D_GET_BUS_STOP_JOB_STATUS,
  D_STOP_BUS_STOP_JOB,
  D_START_BUS_STOP_JOB,
  D_CLEAR_BUS_STOP_JOB_ERROR,

  D_FIND_VENDOR_TRANSACTIONS,
  D_FIND_VENDOR_TRANSACTION_TOTAL,

  D_START_AUDIT_ROUTE_FARE_JOB,
  D_STOP_AUDIT_ROUTE_FARE_JOB,
  D_CLEAR_AUDIT_ROUTE_FARE_JOB_ERROR,
  D_GET_AUDIT_ROUTE_FARE_JOB_STATUS
}
