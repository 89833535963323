module.exports = require('./utilities')
module.exports.stableStringify = require('./vendors/stable-stringify')

// const turf = require('@turf/turf')
//
// // eslint-disable-next-line no-undef
// if (typeof window === 'object' && window) {
//   // eslint-disable-next-line no-undef
//   window.maputilities = module.exports
//   // eslint-disable-next-line no-undef
//   window.turf = turf
// }
