import axios from 'axios'
import store from '../store'
import { D_ADD_SITE_ALERT, D_RENEW_TOKEN } from './action-types'
import { C_ADD_SITE_ALERT } from './mutation-types'

function isTokenExpired () {
  const token = store.getters.getToken
  return (!token || Date.now() > token.expires)
}

function isTokenReadyForRenew () {
  const token = store.getters.getToken
  return (token && Date.now() > token.renews)
}

function renewTokenIfNeeded (config) {
  const urlPath = config.url.replace(config.baseUrl, '')
  if (['/st-user/token/renew', '/st-user/logout'].indexOf(urlPath) === -1 &&
    !TOKEN_RENEW_IN_PROGRESS && !isTokenExpired() && isTokenReadyForRenew()) {
    console.log('renewing token...')
    TOKEN_RENEW_IN_PROGRESS = true
    store.dispatch(D_RENEW_TOKEN).catch((err) => {
      if (err && err.error.statusCode === 401) {
        store.commit(C_ADD_SITE_ALERT, {
          message: 'Session expired, please <a href="/signout/">sign in</a> again'
        })
      }
      return Promise.reject(err)
    }).finally(() => {
      TOKEN_RENEW_IN_PROGRESS = false
    })
  }
}

const _apiRequests = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}`,
  timeout: 15000,
  headers: {}
})

_apiRequests.postFormData = function (url, data, config = {}) {
  let bodyFormData = new FormData()

  for (const key in data) {
    let value = data[key]
    value = value && typeof value === 'object' ? JSON.stringify(value) : value
    bodyFormData.set(key, encodeURIComponent(value))
  }

  config.headers = config.headers || {}
  config.headers['Content-Type'] = `multipart/form-data; boundary=${bodyFormData._boundary}`
  return _apiRequests.post(url, bodyFormData, config)
}

let TOKEN_EXPIRED_ALERT_ID
let TOKEN_RENEW_IN_PROGRESS = false

_apiRequests.interceptors.request.use(function (config) {
  // Do something before request is sent
  renewTokenIfNeeded(config)
  return config
}, function (error) {
  // Do something with request error
  return Promise.reject(error)
})

_apiRequests.interceptors.response.use(function (config) {
  return config
}, function (error) {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    // Do something with request error
    const errorObj = error.response?.data?.error
    if (errorObj) {
      store.commit(C_ADD_SITE_ALERT, {
        message: `Error ${errorObj.status}: ${errorObj.message || 'Unknown'}`
      })
    }
    if (error.response.status === 401 && isTokenExpired()) {
      TOKEN_EXPIRED_ALERT_ID = store.dispatch(D_ADD_SITE_ALERT, { message: 'Session expired, please <a href="/signout/">sign in</a> again', timeout: null, id: TOKEN_EXPIRED_ALERT_ID })
    }
    return Promise.reject(error.response.data)
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log('No response', error.request)
    const err = new Error('No response from server')
    err.status = error.request.status
    return Promise.reject(err)
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Request not completed', error.message)
  }
})

const _mapApiRequests = axios.create({
  baseURL: `${process.env.VUE_APP_MAP_API_URL}`,
  timeout: 15000,
  headers: {}
})

_mapApiRequests.interceptors.request.use(function (config) {
  renewTokenIfNeeded(config)
  return config
}, function (error) {
  return Promise.reject(error)
})

_mapApiRequests.interceptors.response.use(function (config) {
  return config
}, function (error) {
  if (error.response) {
    const errorObj = error.response?.data?.error

    if (errorObj) {
      store.commit(C_ADD_SITE_ALERT, {
        message: `Error ${errorObj.status}: ${errorObj.message || 'Unknown'}`
      })
    }
    if (error.response.status === 401 && isTokenExpired()) {
      TOKEN_EXPIRED_ALERT_ID = store.dispatch(D_ADD_SITE_ALERT, { message: 'Session expired, please <a href="/signout/">sign in</a> again', timeout: null, id: TOKEN_EXPIRED_ALERT_ID })
    }
    return Promise.reject(error.response.data)
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log('No response', error.request)
    const err = new Error('No response from server')
    err.status = error.request.status
    return Promise.reject(err)
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Request not completed', error.message)
  }
})

const _genericApiRequests = axios.create({
  timeout: 15000,
  headers: {}
})

// _genericApiRequests.interceptors.response.use(function (config) {
//   return config
// }, function (error) {
//   if (error.response) {
//     console.log('error.response', error.response)
//     // The request was made and the server responded with a status code
//     // that falls out of the range of 2xx
//     return Promise.reject(error.response)
//   } else if (error.request) {
//     // The request was made but no response was received
//     // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
//     // http.ClientRequest in node.js
//     console.log('No response', error.request)
//   } else {
//     // Something happened in setting up the request that triggered an Error
//     console.log('Request not completed', error.message)
//   }
// })

export const apiRequests = _apiRequests
export const mapApiRequests = _mapApiRequests
export const genericApiRequests = _genericApiRequests
