import { apiRequests } from '../utilities/axios-factory'
import store from '../store'

function getTransportationOwnerUser ({ id, filter }) {
  return apiRequests.get('/v1/transportation-owner-user/' + id + '/', {
    params: { filter },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function findTransportationOwnerUsers (filter) {
  return apiRequests.get('/v1/transportation-owner-user/join/', {
    params: { filter },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function findTotalTransportationOwnerUsers ({ where, join }) {
  return apiRequests.get('/v1/transportation-owner-user/count/join/', {
    params: { where, join },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function deleteTransportationOwnerUser ({ id }) {
  return apiRequests.delete('/v1/transportation-owner-user/' + id + '/', {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function createTransportationOwnerUser ({ transportationOwnerId, firstName, lastName, phone, email, roles, verificationType, details, noVerificationNotice }) {
  return apiRequests.post('/v1/transportation-owner/' + transportationOwnerId + '/transportation-owner-user/', { firstName, lastName, phone, email, roles, verificationType, details, noVerificationNotice }, {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function resendTransportationOwnerUserInvite ({ transportationOwnerUserId, transportationOwnerId }) {
  return apiRequests.put('/v1/transportation-owner/' + transportationOwnerId + '/transportation-owner-user/' + transportationOwnerUserId + '/', {}, {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function updateTransportationOwnerUser ({ id, firstName, lastName, phone, email, role, details, excludeFeeLabel }) {
  return apiRequests.patch('/v1/transportation-owner-user/' + id + '/', { firstName, lastName, phone, email, role, details, excludeFeeLabel }, {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function verifyTransportationOwnerUserInvite ({ verificationToken, password, confirmedPassword }) {
  return apiRequests.put('/v1/transportation-owner-user/invitation/verification/', { verificationToken, password, confirmedPassword }, {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function deleteTransportationOwnerUserInvite ({ transportationOwnerUserId }) {
  return apiRequests.delete('/v1/transportation-owner-user/' + transportationOwnerUserId + '/invitation/', {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function createScheduledPayout ({ transportationOwnerUserId, scheduledDate, frequency, frequencyDate, notifyOnCompletion, notifyOnCompletionContacts, transportationUserMomoAccountId, transportationUserMoneyAccountId, agencyId, lastDayOfMonth, timezone }) {
  return apiRequests.post('/v1/transportation-owner-user/' + transportationOwnerUserId + '/scheduled-payout/', { scheduledDate, frequency, frequencyDate, notifyOnCompletion, notifyOnCompletionContacts, transportationUserMomoAccountId, transportationUserMoneyAccountId, agencyId, lastDayOfMonth, timezone }, {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function updateScheduledPayout ({ transportationOwnerUserId, frequency, frequencyDate, notifyOnCompletion, notifyOnCompletionContacts, transportationUserMomoAccountId, transportationUserMoneyAccountId, agencyId, lastDayOfMonth, archived, timezone }) {
  return apiRequests.patch('/v1/transportation-owner-user/' + transportationOwnerUserId + '/scheduled-payout/', { frequency, frequencyDate, notifyOnCompletion, notifyOnCompletionContacts, transportationUserMomoAccountId, transportationUserMoneyAccountId, agencyId, lastDayOfMonth, archived, timezone }, {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function getScheduledPayout ({ transportationOwnerUserId }) {
  return apiRequests.get('/v1/transportation-owner-user/' + transportationOwnerUserId + '/scheduled-payout/', {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function deleteScheduledPayout ({ transportationOwnerUserId }) {
  return apiRequests.delete('/v1/transportation-owner-user/' + transportationOwnerUserId + '/scheduled-payout/', {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

export {
  getTransportationOwnerUser,
  findTransportationOwnerUsers,
  findTotalTransportationOwnerUsers,
  deleteTransportationOwnerUser,
  createTransportationOwnerUser,
  updateTransportationOwnerUser,
  resendTransportationOwnerUserInvite,
  verifyTransportationOwnerUserInvite,
  deleteTransportationOwnerUserInvite,
  createScheduledPayout,
  updateScheduledPayout,
  getScheduledPayout,
  deleteScheduledPayout
}
