const { DateTime } = require('luxon')

/**
 * @typedef {{1:[string,string][]?,2:[string,string][]?,3:[string,string][]?,4:[string,string][]?,5:[string,string][]?,6:[string,string][]?,7:[string,string][]?}} HoursOfOperationHours - Object where property is the day of the week (1-7) and value is an array of arrays of start and end times
 */

/**
 * Returns true if the current time is within the hours of operation
 * @param {HoursOfOperationHours} hours
 * @param {string} timezone - e.g. Africa/Accra
 * @returns {boolean}
 */
function isInHoursOfOperation (hours, timezone) {
  const dateTime = DateTime.now().setZone(timezone)
  const currentDayOfWeek = dateTime.toFormat('c')
  const currentHours = hours[currentDayOfWeek]

  if (!currentHours) {
    throw Error(`No hours entry found for ${dateTime.toFormat('cccc, ZZZZ')} (${currentDayOfWeek})`)
  }

  for (let i = 0; i < currentHours.length; i++) {
    let startTime = DateTime.now().setZone(timezone)
    let endTime = DateTime.now().setZone(timezone)
    const currentTime = DateTime.now().setZone(timezone)

    startTime = startTime.set({
      hour: Number(currentHours[i][0].split(':')[0]),
      minute: Number(currentHours[i][0].split(':')[1])
    })
    endTime = endTime.set({
      hour: Number(currentHours[i][1].split(':')[0]),
      minute: Number(currentHours[i][1].split(':')[1])
    })

    if (startTime.valueOf() <= currentTime.valueOf() && endTime.valueOf() >= currentTime.valueOf()) {
      return true
    }
  }

  return false
}

/**
 * Returns the next available hour of operation
 * @param {HoursOfOperationHours} hours
 * @param {string} timezone - e.g. Africa/Accra
 * @param {DateTime} [nextProvidedDate] - Optional date to start from, only really used in recursive call
 * @returns {Date|undefined}
 */
function getNextHourOfOperation (hours, timezone, nextProvidedDate) {
  const dateTime = nextProvidedDate || DateTime.now().setZone(timezone)
  const currentDayOfWeek = dateTime.toFormat('c')
  const currentHours = hours[currentDayOfWeek]

  if (!currentHours) {
    throw Error(`No hours entry found for ${dateTime.toFormat('cccc, ZZZZ')} (${currentDayOfWeek})`)
  }

  let nextDate = null
  const targetTimestamp = dateTime.valueOf()

  for (let i = 0; i < currentHours.length; i++) {
    if (!currentHours[i]) {
      continue
    }

    let startTime = DateTime.now().setZone(timezone)
    let endTime = DateTime.now().setZone(timezone)

    if (nextProvidedDate) {
      startTime = DateTime.fromMillis(nextProvidedDate.valueOf()).setZone(timezone).set({
        hour: Number(currentHours[i][0].split(':')[0]),
        minute: Number(currentHours[i][0].split(':')[1])
      })
    } else {
      startTime = startTime.set({
        hour: Number(currentHours[i][0].split(':')[0]),
        minute: Number(currentHours[i][0].split(':')[1])
      })
      endTime = endTime.set({
        hour: Number(currentHours[i][1].split(':')[0]),
        minute: Number(currentHours[i][1].split(':')[1])
      })
    }

    if (nextProvidedDate) {
      // if in recursive func, just return the next time slot
      nextDate = startTime
      break
    } else {
      // we are looking for the slot for the current date so that we can iterate from there
      // check if within this timeslot, if so then the use the next available slot on this week day (if available)
      if (startTime.valueOf() <= targetTimestamp && endTime.valueOf() >= targetTimestamp) {
        if ((i + 2) <= currentHours.length) {
          let nextStartTime = DateTime.fromMillis(targetTimestamp).setZone(timezone)
          nextStartTime = nextStartTime.set({
            hour: Number(currentHours[i + 1][0].split(':')[0]),
            minute: Number(currentHours[i + 1][0].split(':')[1])
          })
          nextDate = nextStartTime
          break
        }
      } else if (targetTimestamp < startTime.valueOf()) {
        nextDate = startTime
      }
    }

    // console.log('startDate', startTime && startTime.toJSDate(), 'endDate', endTime && endTime.toJSDate(), 'nextDate', nextDate && nextDate.toJSDate(), 'nextDateLocalized', nextDate && nextDate.setZone('America/Toronto').toJSDate())
  }

  if (nextDate) {
    return nextDate.toJSDate()
  }

  // if no target nextDate found, then go to next slot by recursive call
  return getNextHourOfOperation(hours, timezone, dateTime.plus({ days: 1 }))
}

function convertHoursToRelativeTimeLabel (lengthInHours) {
  let lengthInAppropriateUnits


  if (lengthInHours < (1 / 60)) {
    const secs = Math.round(lengthInHours * 60 * 60)
    lengthInAppropriateUnits = `${secs} sec${secs === 1 ? '' : 's'}`
  } else if (lengthInHours < 1) {
    const mins = Math.round(lengthInHours * 60)
    lengthInAppropriateUnits = `${mins} min${mins === 1 ? '' : 's'}`
  } else {
    const parts = (lengthInHours + '').split('.')

    if (parts.length > 1) {
      const mins = Math.round(Number(`0.${parts[1]}`) * 60)
      lengthInAppropriateUnits = `${parts[0]} hr${Number(parts[0]) === 1 ? '' : 's'} ${mins} min${mins === 1 ? '' : 's'}`
    } else {
      lengthInAppropriateUnits = `${lengthInHours} hr${lengthInHours === 1 ? '' : 's'}`
    }
  }

  return lengthInAppropriateUnits
}

module.exports = {
  isInHoursOfOperation,
  getNextHourOfOperation,
  convertHoursToRelativeTimeLabel
}
