<template>
  <div class="transportation-user-management">
    <v-layout row wrap>
      <v-flex md7 sm5 pa-2>
        <form @submit.prevent="searchUsers">
          <v-text-field
              solo
              v-model="searchKeywords"
              append-icon="fal fa-search"
              :rules="[v => !v || v.length >= 2 || 'Min 2 characters']"
              :disabled="apiInProgress"
              type="search"
              placeholder="Search by name, email address, phone number"
              @click:append="searchUsers"
          ></v-text-field>
        </form>
      </v-flex>
      <v-flex md3 sm4 pa-2>
        <v-select
          clearable
          :items="transportationRoles"
          label="Account Roles"
          :value="selectedRole"
          @change="onFilterByRole"
        />
      </v-flex>
      <v-flex md2 sm3 pa-2>
        <v-btn block color="primary" v-if="hasUserType('admin') || hasTransportationRole('ADMIN')" :disabled="apiInProgress" @click="loadCreateUserModal()"><v-icon>fal fa-plus</v-icon> &nbsp; Create User</v-btn>
      </v-flex>
    </v-layout>
    <v-tabs icons-and-text @change="onTabChanged">
      <v-tabs-slider color="primary"></v-tabs-slider>
      <v-tab ripple href="#users">
        Users {{selectedUserTab !== 'users' ? `(${totalUsers})` : ''}}
        <v-icon>fal fa-user</v-icon>
      </v-tab>
      <v-tab ripple href="#invited-users" v-if="hasUserType('admin') || hasTransportationRole('ADMIN')">
        Invited Users {{selectedUserTab !== 'invited-users' ? `(${totalInvitedUsers})` : ''}}
        <v-icon>fal fa-user-plus</v-icon>
      </v-tab>
      <v-tabs-items v-model="selectedUserTab">
        <v-tab-item value="users">
          <v-data-table
              v-if="selectedUserTab === 'users'"
              :headers="headers"
              :items="users"
              :pagination.sync="pagination"
              :total-items="getTotal(selectedUserTab)"
              :loading="apiInProgress"
              :rows-per-page-items="[10, 25, 50]"
              @update:pagination="onPagination"
              class="elevation-1"
              must-sort
          >
            <template v-slot:items="props">
              <tr class="tr--pointer" @click="loadUpdateUser(props.item)">
                <td class="layout justify-start">
                  <v-tooltip v-if="signedInUser && props.item.email === signedInUser.email" top>
                    <template v-slot:activator="{ on }">
                      <v-btn style="margin: 0" flat small icon><v-icon small class="info--text" v-on="on">far fa-hand-point-right</v-icon></v-btn>
                    </template>
                    <span>Current user signed in</span>
                  </v-tooltip>
                  <v-text-field
                    class="text-field--flat"
                    v-model="props.item.stUser.lastName"
                    hide-details
                  />
                </td>
                <td>
                  <v-text-field
                    class="text-field--flat"
                    v-model="props.item.stUser.firstName"
                    hide-details
                  />
                </td>
                <td class="text-xs-left">
                  {{ props.item.phoneNumber }}
                  <img v-if="props.item.mobileMoneyAccountType" style="height: 30px;" :title="props.item.mobileMoneyAccountType" :src="moneyAccountLogos[props.item.mobileMoneyAccountType]" :alt="props.item.mobileMoneyAccountType" />
                  <div v-if="!props.item.mobileMoneyAccountType"><small class="amber--text">No mobile money</small></div>
                </td>
                <td class="text-xs-left" :title="props.item.stUser.email">
                  <v-text-field
                    class="text-field--flat"
                    v-model="props.item.stUser.email"
                    hide-details
                  />
                </td>
                <td class="text-xs-left"><small>{{ props.item.userTypeLabel }}</small></td>
                <td class="text-xs-left">{{ props.item.stUser.rating }}</td>
                <td class="text-xs-left">{{ props.item.dateCreatedLabel }}</td>
                <td class="text-xs-left">{{ props.item.dateUpdatedLabel }}</td>
                <td class="justify-center layout px-0">
                  <v-btn flat icon :disabled="!props.item.isEditable" title="Mobile money wallet" @click.stop="loadMobileMoneyModal(props.item)">
                    <v-icon small>
                      far fa-wallet
                    </v-icon>
                  </v-btn> &nbsp; <v-btn flat icon :class="props.item.isOwner || !props.item.isEditable ? 'text--muted' : ''" :loading="props.item.isArchiveInProgress" :title="props.item.isOwner > -1 ? 'Account owner cannot be deleted, please archive the account' : 'Delete user'" @click.stop="deleteUser(props.item)">
                  <v-icon small>
                    fas fa-trash-alt
                  </v-icon>
                </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item value="invited-users" v-if="hasUserType('admin') || hasTransportationRole('ADMIN')">
          <v-data-table
              v-if="selectedUserTab === 'invited-users'"
              :headers="invitationHeaders"
              :items="users"
              :pagination.sync="pagination"
              :total-items="getTotal(selectedUserTab)"
              :loading="apiInProgress"
              :rows-per-page-items="[10, 25, 50]"
              @update:pagination="onPagination"
              class="elevation-1"
              must-sort
          >
            <template v-slot:items="props">
              <tr :class="props.item.isExpired ? 'invited-user--expired' : ''">
                <td :title="props.item.stUser.lastName">
                  <v-text-field
                    class="text-field--flat"
                    v-model="props.item.stUser.lastName"
                    hide-details
                  />
                </td>
                <td :title="props.item.stUser.firstName">
                  <v-text-field
                    class="text-field--flat"
                    v-model="props.item.stUser.firstName"
                    hide-details
                  />
                </td>
                <td class="text-xs-left">{{ props.item.phoneNumber }}</td>
                <td class="text-xs-left" :title="props.item.stUser.email">
                  <v-text-field
                    class="text-field--flat"
                    v-model="props.item.stUser.email"
                    hide-details
                  />
                </td>
                <td class="text-xs-left">{{ props.item.userTypeLabel }}</td>
                <td class="text-xs-left">{{ props.item.dateCreatedLabel }}</td>
                <td class="text-xs-left">
                  <div>{{ props.item.verificationTokenExpiryLabel }}</div>
                  <div v-if="props.item.isExpired"><small class="error--text">(Expired)</small></div>
                </td>
                <td class="justify-center layout px-0">
                  <v-btn flat icon @click.stop="resendUserInvitation(props.item)" :disabled="!props.item.isEditable" title="Re-send invitation">
                    <v-icon small>
                      fas fa-envelope
                    </v-icon>
                  </v-btn> &nbsp; <v-btn flat icon @click.stop="cancelInvitedUser(props.item)" :disabled="!props.item.isEditable" title="Cancel invitation">
                  <v-icon small>
                    fas fa-trash-alt
                  </v-icon>
                </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
    <v-dialog
        transition="dialog-bottom-transition"
        scrollable
        persistent
        max-width="500"
        v-model="userInviteLoaded">
      <v-card>
        <v-card-title class="headline grey lighten-2 text-xs-center" primary-title>
          Create / invite account user
        </v-card-title>
        <v-form>
          <v-card-text v-if="invitedUser">
            <v-layout row wrap>
              <v-flex sm6 pr-1>
                <v-select
                    pb-2
                    :disabled="userModalApiInProgress"
                    :items="[{text: 'Email', value: 'email'},{text: 'Phone', value: 'phone'}]"
                    label="Create / Invite By"
                    v-model="invitedUser.verificationType"
                />
              </v-flex>
              <v-flex sm6 pl-1>
                <v-select
                  pb-2
                  required
                  :disabled="userModalApiInProgress"
                  :items="userTypesByAuthorization"
                  v-model="invitedUser.userType"
                  label="User Type"
                />
              </v-flex>
            </v-layout>
            <v-text-field
                pb-2
                :box="invitedUser.verificationType === 'email'"
                type="email" v-model="invitedUser.email"
                :rules="[(v) => (!v || /^[^@]+@[^@]+\.[0-9a-zA-Z]{2,}$/.test(v) || 'Valid email format required')]"
                :label="'Email address' + (invitedUser.verificationType === 'phone' ? ' (optional)' : '')">
            </v-text-field>
            <phone-number-field
              pb-2
              :props-prefix="!invitedUser.phone ? phonePrefix : undefined"
              v-model="invitedUser.phone"
              :child-props="{ label: 'Phone' + (invitedUser.verificationType === 'email' ? ' (optional)' : ''), box: invitedUser.verificationType === 'phone' }"
            />
            <v-layout row wrap>
              <v-flex sm6 pr-1>
                <v-text-field v-model="invitedUser.firstName" pb-2 label="First name"></v-text-field>
              </v-flex>
              <v-flex sm6 pl-1>
                <v-text-field v-model="invitedUser.lastName" pb-2 label="Last name"></v-text-field>
              </v-flex>
            </v-layout>
            <div>
              <v-switch v-model="invitedUser.verificationNotice" :disabled="userModalApiInProgress" label="Send invitation" />
            </div>
          </v-card-text>
        </v-form>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warn" flat :disabled="userModalApiInProgress" @click="userInviteLoaded = false">
            Cancel
          </v-btn> &nbsp;
          <v-btn color="primary" :disabled="userModalApiInProgress" :loading="userModalApiInProgress" @click="inviteUser()">
            {{invitedUser && invitedUser.verificationNotice ? 'Send Invite' : 'Create'}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        transition="dialog-bottom-transition"
        scrollable
        persistent
        max-width="500"
        v-model="updateUserLoaded">
      <v-card>
        <v-card-title class="headline grey lighten-2 text-xs-center" primary-title>
          Update account user
        </v-card-title>
        <v-form>
          <v-card-text v-if="updatingUser">
            <v-select
              pb-2
              :disabled="!isUserEditable(updatingUser.user, 'ignoreIfEmailSame')"
              :items="transportationRoles"
              v-model="updatingUser.userType"
              label="User Type"
            ></v-select>
            <v-text-field
                pb-2
                :readonly="!isUserEditable(updatingUser.user)"
                type="email" v-model="updatingUser.email"
                :rules="[(v) => (!v || /^[^@]+@[^@]+\.[0-9a-zA-Z]{2,}$/.test(v) || 'Valid email format required')]"
                label="Email address">
            </v-text-field>
            <v-text-field
              pb-2
              readonly
              v-if="!isUserEditable(updatingUser.user)"
              type="text" v-model="updatingUser.phone"
              label="Phone">
            </v-text-field>
            <phone-number-field
              pb-2
              :props-prefix="!updatingUser.phone ? phonePrefix : undefined"
              v-if="isUserEditable(updatingUser.user)"
              v-model="updatingUser.phone"
              :child-props="{ label: 'Phone' }" />
            <v-text-field
              pb-2
              readonly
              v-if="updatingUser.transportationIdentifier"
              type="text" :value="updatingUser.transportationIdentifier + ' (' + updatingUser.mobileMoneyAccountType + ')'"
              label="Mobile money">
            </v-text-field>
            <v-layout row wrap>
              <v-flex sm6 pr-1>
                <v-text-field v-model="updatingUser.firstName" :readonly="!isUserEditable(updatingUser.user)" pb-2 label="First name"></v-text-field>
              </v-flex>
              <v-flex sm6 pl-1>
                <v-text-field v-model="updatingUser.lastName" :readonly="!isUserEditable(updatingUser.user)" pb-2 label="Last name"></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout v-if="updatingUser && updatingUser.userType === 'OWNER' && hasUserType('admin')" row justify-start align-center wrap>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn style="margin: 0" flat icon><v-icon v-on="on">fal fa-info-circle</v-icon></v-btn>
                </template>
                <span>Will not display the SmartTransit fee deduction in the SMS/email report sent to the account owner</span>
              </v-tooltip>
              <v-switch v-model="updatingUser.excludeFeeLabel" class="ml-2" label="Exclude deduction fee in report" />
            </v-layout>
          </v-card-text>
        </v-form>
        <v-divider></v-divider>
        <v-card-actions v-if="updatingUser">
          <v-btn v-if="isUserEditable(updatingUser.user)" @click="closeAndLoadMobileMoneyModal(updatingUser.user)">
            <v-icon left>far fa-wallet</v-icon>
            Mobile money
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="warn" flat :disabled="updatingUser.isLoading" @click="cancelEdit()">
            Cancel
          </v-btn> &nbsp;
          <v-btn color="primary" :disabled="updatingUser.isLoading || !isUpdatingUserEdited()" :loading="updatingUser.isLoading" @click="updateUser()">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      transition="dialog-bottom-transition"
      scrollable
      persistent
      max-width="500"
      v-model="mobileMoneyModalLoaded">
      <v-card>
        <v-card-title class="headline grey lighten-2 text-xs-center" primary-title>
          Manage user mobile money
        </v-card-title>
        <v-form>
          <v-card-text v-if="mobileMoneyUser">
            <h3 class="mb-2">{{mobileMoneyUser.user.stUser.firstName}} {{mobileMoneyUser.user.stUser.lastName}}</h3>
            <phone-number-field
              pb-2
              :props-prefix="phonePrefix"
              v-model="mobileMoneyUser.phoneNumber"
              :child-props="{ label: 'Mobile money number' }"
            />
            <v-select
              pb-2
              :items="moneyAccountTypes"
              v-model="mobileMoneyUser.moneyAccountTypeId"
              label="Mobile money account"
            >
              <template slot="item" slot-scope="{ item }">
                <div class="layout row justify-start align-center">
                  <div>{{item.text}}</div>
                  <div v-if="item.metadata.src" class="ml-2">
                    <img :src="item.metadata.src" style="height: 30px;" :alt="item.value" />
                  </div>
                </div>
              </template>
              <template slot="selection" slot-scope="{ item }">
                <div class="layout row justify-start align-center">
                  <div class="layout row justify-start align-center">
                    <div>{{item.text}}</div>
                    <div v-if="item.metadata.src" class="ml-2">
                      <img :src="item.metadata.src" style="height: 30px;" :alt="item.value" />
                    </div>
                  </div>
                </div>
              </template>
            </v-select>
            <v-btn v-if="mobileMoneyUser.id" :disabled="mobileMoneyUser.isLoading" :loading="mobileMoneyUser.isLoading" @click="deRegisterMobileMoney()">
              De-Register
            </v-btn>
          </v-card-text>
        </v-form>
        <v-divider></v-divider>
        <v-card-actions v-if="mobileMoneyUser">
          <v-spacer></v-spacer>
          <v-btn color="warn" flat :disabled="mobileMoneyUser.isLoading" @click="cancelMobileMoneyUserEdit()">
            Cancel
          </v-btn> &nbsp;
          <v-btn color="primary" :disabled="mobileMoneyUser.isLoading || !isMobileMoneyUserEdited()" :loading="mobileMoneyUser.isLoading" @click="saveMobileMoneyUser()">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modalSiteAlert" max-width="500px">
      <v-card>
        <v-card-title>
          {{modalSiteAlertData ? modalSiteAlertData.type : ''}}
        </v-card-title>
        <v-card-text style="overflow-y: scroll; max-height: 300px;" class="px-2 pb-2">
          {{modalSiteAlertData ? modalSiteAlertData.message : ''}}
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" flat @click="modalSiteAlert = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import src from './src'
export default src
</script>
