import { atigoghana, mtnghana, vodaghana } from '../assets'

const moneyAccountTypes = [
  { value: 'mtnghana', text: 'MTN Mobile Money', metadata: { src: mtnghana } },
  { value: 'atigoghana', text: 'Airtel/Tigo Money', metadata: { src: atigoghana } },
  { value: 'vodaghana', text: 'Vodafone Cash', metadata: { src: vodaghana } }
]

function getMoneyAccountTypes () {
  return [].concat(moneyAccountTypes)
}

export {
  getMoneyAccountTypes
}
