import moment from 'moment-timezone'
import { formatCurrency, formatDate, formatTime } from '@smarttransit/common'
import { generateKeywordsQueryForOr } from '@smarttransit/common-client'
import { D_GET_COUNTRY_CURRENCY } from '../../utilities/action-types'

import {
  findTotalVendorTransactionLogs,
  findVendorTransactionLogs
} from '../../services/passenger-transaction-logs-service'

export default {
  name: 'passenger-trips',
  props: {
    signedInUser: Object,
    forceRootViewRefresh: Function
  },
  data () {
    return {
      apiInProgress: false,
      searchKeywords: '',
      searchPhone: '',
      selectedVendor: '',
      countryCurrency: null,
      dateFromMenu: null,
      dateFrom: null,
      dateToMenu: null,
      dateTo: null,
      headers: [],
      transactions: [],
      totalTransactions: 0,
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 10,
        sortBy: 'dateUpdated'
      }
    }
  },
  computed: {
    computedDateFromFormatted () {
      return this.dateFrom ? formatDate(this.dateFrom) : ''
    },
    computedDateToFormatted () {
      return this.dateTo ? formatDate(this.dateTo) : ''
    },
    computedDateFromMax () {
      const fromDateAsMoment = this.dateFrom ? moment(this.dateFrom, 'Y-MM-DD') : null
      const toDateAsMoment = this.dateTo ? moment(this.dateTo, 'Y-MM-DD') : null
      if (toDateAsMoment && (!fromDateAsMoment || fromDateAsMoment.valueOf() <= toDateAsMoment.valueOf())) {
        return toDateAsMoment.subtract(1, 'day').format('Y-MM-DD')
      }
      return ''
    },
    computedDateToMin () {
      const fromDateAsMoment = this.dateFrom ? moment(this.dateFrom, 'Y-MM-DD') : null
      const toDateAsMoment = this.dateTo ? moment(this.dateTo, 'Y-MM-DD') : null
      if (fromDateAsMoment && (!toDateAsMoment || fromDateAsMoment.valueOf() <= toDateAsMoment.valueOf())) {
        return fromDateAsMoment.add(1, 'day').format('Y-MM-DD')
      }
      return ''
    }
  },
  mounted () {
    if (this.$props.signedInUser) {
      this.isTransporter = this.$props.signedInUser.roles.find((o) => (o.name === 'transporter'))
    }
    this.headers = [
      {
        text: 'Phone',
        align: 'left',
        sortable: false
      },
      {
        text: 'Cost', // include date paid / prepaid
        align: 'left',
        sortable: true,
        value: 'cost'
      },
      {
        text: 'Amt. Remaining',
        align: 'left',
        sortable: true,
        value: 'balance'
      },
      {
        text: 'From',
        align: 'left',
        sortable: true,
        value: 'originLabel'
      },
      {
        text: 'To',
        align: 'left',
        sortable: true,
        value: 'destinationLabel'
      },
      {
        text: 'Km Travelled',
        align: 'left',
        sortable: true,
        value: 'finalDistanceTravelled'
      },
      {
        text: 'Vehicle',
        align: 'left',
        sortable: true,
        value: 'transportationProfileId'
      },
      {
        text: 'Ended By',
        align: 'left',
        sortable: false
      },
      {
        text: 'Date Updated',
        align: 'left',
        sortable: true,
        value: 'dateUpdated'
      },
      {
        text: 'Date Created',
        align: 'left',
        sortable: true,
        value: 'dateCreated'
      }
    ]
    if (this.$props.signedInUser) {
      this.initCountryCurrency().then(() => {
        this.searchTransactions()
      })
    }
  },
  methods: {
    async initCountryCurrency () {
      let vendor = this.vendors.find((o) => o.value === this.selectedVendor)

      if (!vendor) {
        vendor = this.vendors[0]
        this.selectedVendor = vendor.value
      }

      this.countryCurrency = await this.$store.dispatch(D_GET_COUNTRY_CURRENCY, { id: vendor.metadata.countryId })
    },
    onPagination () {
      if (this.countryCurrency) {
        this.searchTransactions()
      }
    },
    onFilterByVendor () {
      if (this.selectedVendor) {
        this.searchTransactions()
      }
    },
    viewMetadata (item) {
      this.metadataLoaded = true
      this.selectedMetadata = item
      if (this.selectedMetadata) {
        this.selectedMetadata.logMetadataParsed = item && item.logMetadata && Object.keys(item.logMetadata).length ? { ...item.logMetadata } : { notice: 'No metadata available' }
        if (this.selectedMetadata.logMetadata.responseParams && typeof this.selectedMetadata.logMetadata.responseParams === 'object') {
          this.selectedMetadata.logMetadataParsed.responseParams = `<pre>${JSON.stringify(this.selectedMetadata.logMetadataParsed.responseParams, null, 2)}</pre>`
        }
      }
    },
    marshallTransaction (o) {
      const amountClass = o.amount < 0 ? 'error--text' : ''
      const balanceBeforeClass = o.profileBalanceBefore < 0 ? 'error--text' : ''
      const balanceAfterClass = o.profileBalanceAfter < 0 ? 'error--text' : ''
      o.amountLabel = `<span class="${amountClass}">${this.countryCurrency.currencySymbol}${formatCurrency(o.amount)}</span>`
      o.profileBalanceBeforeLabel = `<span class="${balanceBeforeClass}">${formatCurrency(o.profileBalanceBefore)}</span>`
      o.profileBalanceAfterLabel = `<span class="${balanceAfterClass}">${formatCurrency(o.profileBalanceAfter)}</span>`
      o.phoneLabel = o.passengerIdentifier ? o.passengerIdentifier.phoneNumber : o.stUser.phone
      if (o.logType === 'refund') {
        o.logTypeIcon = 'fas fa-undo'
      } else {
        o.logTypeIcon = ''
      }
      switch (o.logStatus) {
        case 'error':
          o.logStatusIcon = 'fas fa-exclamation-triangle'
          o.logStatusTooltip = 'Error: ' + o.logMetadata.error
          break
        case 'completed':
          o.logStatusIcon = 'fas fa-check'
          o.logStatusTooltip = 'Completed'
          break
        case 'timedout':
          o.logStatusIcon = 'fas fa-hourglass-end'
          o.logStatusTooltip = 'Timeout occurred while waiting for vendor feedback'
          break
        case 'expired':
          o.logStatusIcon = 'fas fa-calendar-times'
          o.logStatusTooltip = 'Expired'
          break
        default:
          o.logStatusIcon = 'fas fa-info-circle'
          o.logStatusTooltip = o.logStatus
      }
      o.dateCreatedLabel = `${formatTime(o.dateCreated)}, ${formatDate(o.dateCreated)}`
      o.dateUpdatedLabel = o.dateUpdated ? `${formatTime(o.dateUpdated)}, ${formatDate(o.dateUpdated)}` : o.dateCreatedLabel
      return o
    },
    async searchTransactions () {
      const { sortBy, descending, page, rowsPerPage } = this.pagination
      const offset = page === 1 ? 0 : (page * rowsPerPage) - rowsPerPage
      let params = {
        vendor: null,
        filter: {
          limit: rowsPerPage,
          offset,
          where: {}
        }
      }
      if (!sortBy) {
        params.filter.order = ['dateUpdated DESC', 'dateCreated DESC']
      } else {
        params.filter.order = `${sortBy} ${descending ? 'DESC' : 'ASC'}`
      }
      if (this.searchKeywords) {
        params.stUserKeywords = { or: generateKeywordsQueryForOr(this.searchKeywords, ['value']) }
      }
      if (this.selectedVendor) {
        params.vendor = this.selectedVendor
      }
      if (this.searchPhone) {
        params.searchPhone = this.searchPhone
      }
      if (this.dateFrom) {
        if (!params.filter.where.and) {
          params.filter.where.and = []
        }
        params.filter.where.and.push({ dateCreated: { gte: moment.utc(this.dateFrom, 'Y-MM-DD').toDate().toISOString() } })
      }
      if (this.dateTo) {
        if (!params.filter.where.and) {
          params.filter.where.and = []
        }
        params.filter.where.and.push({ dateCreated: { lte: moment.utc(this.dateTo, 'Y-MM-DD').add(23, 'hours').add(59, 'minutes').toDate().toISOString() } })
      }
      this.apiInProgress = true
      try {
        const [transactions, totalTransactions] = await Promise.all([
          findVendorTransactionLogs(params),
          findTotalVendorTransactionLogs({ where: params.filter.where, stUserKeywords: params.stUserKeywords, searchPhone: params.searchPhone, vendor: params.vendor })
        ])
        this.transactions = transactions.map(o => {
          return this.marshallTransaction(o)
        })
        this.totalTransactions = totalTransactions.count
        this.apiInProgress = false
      } catch (err) {
        this.apiInProgress = false
      }
    }
  },
  beforeDestroy () {}
}
