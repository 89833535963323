<template>
  <div>
    <v-autocomplete
      v-model="selectedUser"
      :items="users"
      :loading="apiInProgress"
      :search-input.sync="searchKeywords"
      return-object
      clearable
      hide-no-data
      hide-selected
      browser-autocomplete="none"
      v-bind="childProps"
    >
      <template slot="item" slot-scope="{ item }">
        <div style="width: 100%">
          <div :title="item.phone || item.email">{{item.phone || item.email}}</div>
          <div>
            <em v-if="item.fullName"><small>{{item.fullName}}</small></em>
            <span v-show="item.userTypeLabel">&nbsp; <small><em>({{item.userTypeLabel}})</em></small></span>
          </div>
          <hr style="width: 100%; border: 0; border-bottom: 1px solid #ccc" />
        </div>
      </template>
      <template slot="selection" slot-scope="{ item }">
        <div>
          <div :title="item.phone || item.email">{{item.phone || item.truncatedEmail}}</div>
          <div>
            <em v-if="item.fullName"><small>{{item.fullName}}</small></em>
            <span v-show="item.userTypeLabel">&nbsp; <small><em>({{item.userTypeLabel}})</em></small></span>
          </div>
        </div>
      </template>
    </v-autocomplete>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

<script>
import src from './src'
export default src
</script>
