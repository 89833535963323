import { apiRequests } from '../utilities/axios-factory'
import store from '../store'

function getTransportationUserIdentifier ({ id, filter }) {
  return apiRequests.get('/v1/transportation-user-identifier/' + id + '/', {
    params: { filter },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function findTransportationUserIdentifiers (filter) {
  return apiRequests.get('/v1/transportation-user-identifier/join/', {
    params: { filter },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function findTotalTransportationUserIdentifiers ({ where, join }) {
  return apiRequests.get('/v1/transportation-user-identifier/count/join/', {
    params: { where, join },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function deleteTransportationUserIdentifier ({ id }) {
  return apiRequests.delete('/v1/transportation-user-identifier/' + id + '/', {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function createTransportationUserIdentifier ({ phoneNumber, transportationOwnerUserId, moneyAccountTypeId }) {
  return apiRequests.post('/v1/transportation-user-identifier/registration/by-admin/', { phoneNumber, transportationOwnerUserId, moneyAccountTypeId }, {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function updateTransportationUserIdentifier ({ id, phoneNumber, moneyAccountTypeId }) {
  return apiRequests.patch('/v1/transportation-user-identifier/' + id + '/', { phoneNumber, moneyAccountTypeId }, {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function deRegisterTransportationUserMomoAccount ({ id }) {
  return apiRequests.delete('/v1/transportation-user-momo-account/' + id + '/', {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

export {
  getTransportationUserIdentifier,
  findTransportationUserIdentifiers,
  findTotalTransportationUserIdentifiers,
  deleteTransportationUserIdentifier,
  createTransportationUserIdentifier,
  updateTransportationUserIdentifier,
  deRegisterTransportationUserMomoAccount
}
