import _cloneDeep from 'lodash.clonedeep'
import _upperFirst from 'lodash.upperfirst'
import _intersection from 'lodash.intersection'
import { formatCurrency, formatDate, formatTime } from '@smarttransit/common'
import { isAuthorized, addAlert } from '../../utilities/helpers'

import {
  archiveAgent,
  cancelAgentInvite,
  findAgents,
  findTotalAgents,
  resendAgentInvite,
  unArchiveAgent
} from '../../services/agents-service'

export default {
  name: 'staff-management',
  props: {
    signedInUser: Object,
    initialSearchKeywords: String,
    onCreateAgentClicked: Function,
    onAgentSelected: Function,
    onArchivedAgentSelected: Function,
    onInvitedAgentSelected: Function,
    onPaginationClicked: Function,
    onTabClicked: Function,
    onSearchSubmitted: Function,
    onActionClicked: Function,
    onError: Function
  },
  data () {
    return {
      agents: [],
      totalAgents: 0,
      totalArchivedAgents: 0,
      totalInvitedAgents: 0,
      selectedCountryId: 'GHA',
      countries: [],
      apiInProgress: false,
      isArchiveInProgress: false,
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 10,
        sortBy: 'dateUpdated'
      },
      searchKeywords: '',
      selectedAgentTab: 'agents',
      userInviteLoaded: false,
      invitedAgent: null,
      headers: [],
      invitationHeaders: [],
      archivedHeaders: []
    }
  },
  computed: {
  },
  watch: {
  },
  mounted: function () {
    this.headers = [
      {
        text: 'Last Name',
        align: 'left',
        sortable: true,
        value: 'lastName'
      },
      {
        text: 'First Name',
        align: 'left',
        sortable: true,
        value: 'firstName'
      },
      {
        text: 'Phone',
        align: 'left',
        sortable: true,
        value: 'phone'
      },
      {
        text: 'Email',
        align: 'left',
        sortable: true,
        value: 'email'
      },
      {
        text: 'Funds',
        align: 'left',
        sortable: true,
        value: 'balance'
      },
      {
        text: 'Date Created',
        align: 'left',
        sortable: true,
        value: 'dateCreated'
      },
      {
        text: 'Date Updated',
        align: 'left',
        sortable: true,
        value: 'dateUpdated'
      },
      { text: 'Actions', sortable: false }
    ]

    this.invitationHeaders = [
      {
        text: 'Last Name',
        align: 'left',
        sortable: true,
        value: 'lastName'
      },
      {
        text: 'First Name',
        align: 'left',
        sortable: true,
        value: 'firstName'
      },
      {
        text: 'Phone',
        align: 'left',
        sortable: true,
        value: 'phone'
      },
      {
        text: 'Email',
        align: 'left',
        sortable: true,
        value: 'email'
      },
      {
        text: 'Date Created',
        align: 'left',
        sortable: true,
        value: 'dateCreated'
      },
      {
        text: 'Invitation Expiry Date',
        align: 'left',
        sortable: true,
        value: 'verificationTokenExpiry'
      },
      { text: 'Actions', sortable: false }
    ]

    this.archivedHeaders = [
      {
        text: 'Last Name',
        align: 'left',
        sortable: true,
        value: 'lastName'
      },
      {
        text: 'First Name',
        align: 'left',
        sortable: true,
        value: 'firstName'
      },
      {
        text: 'Phone',
        align: 'left',
        sortable: true,
        value: 'phone'
      },
      {
        text: 'Email',
        align: 'left',
        sortable: true,
        value: 'email'
      },
      {
        text: 'Funds',
        align: 'left',
        sortable: true,
        value: 'balance'
      },
      {
        text: 'Date Created',
        align: 'left',
        sortable: true,
        value: 'dateCreated'
      },
      {
        text: 'Date Archived',
        align: 'left',
        sortable: true,
        value: 'dateArchived'
      },
      { text: 'Actions', sortable: false }
    ]

    this.$store.getters.getCountryCurrencies.then((results) => {
      this.countryCurrencies = results

      for (const key in results) {
        if (results[key]) {
          this.countries.push({ text: results[key].countryLabel, value: key })
        }
      }
    }).catch((error) => {
      addAlert({ message: error, type: 'error' })
    })

    if (this.$props.initialSearchKeywords) {
      this.searchKeywords = this.$props.initialSearchKeywords
    }

    this.searchAgents().finally(() => {
      this.firstApiCalled = true
    })
  },
  methods: {
    searchAgentsClicked () {
      if (this.$props.onSearchSubmitted) {
        this.$props.onSearchSubmitted({ tab: this.selectedAgentTab, keywords: this.searchKeywords })
      }

      this.searchAgents()
    },
    createAgent () {
      if (this.$props.onCreateAgentClicked) {
        this.$props.onCreateAgentClicked()
      }
    },
    hasUserType (role) {
      const { signedInUser } = this.$props
      return (signedInUser && isAuthorized(signedInUser, role))
    },
    loadAgent (agent) {
      if (agent.isEditable) {
        if (this.$props.onAgentSelected) {
          this.$props.onAgentSelected(_cloneDeep(agent))
        }
      }
    },
    loadArchivedAgent (agent) {
      if (agent.isEditable) {
        if (this.$props.onArchivedAgentSelected) {
          this.$props.onArchivedAgentSelected(_cloneDeep(agent))
        }
      }
    },
    loadInvitedAgent (agent) {
      if (agent.isEditable) {
        if (this.$props.onInvitedAgentSelected) {
          this.$props.onInvitedAgentSelected(_cloneDeep(agent))
        }
      }
    },
    resendAgentInvitation (agent) {
      if (confirm(`Confirm re-sending invitation for ${agent.stUser?.firstName} ${agent.stUser?.lastName}`)) {
        resendAgentInvite(agent.id).then((result) => {
          this.searchAgents('invited-agents')
          addAlert({ message: `Successfully re-sent invitation for ${agent.stUser?.firstName} ${agent.stUser?.lastName}`, type: 'success' })
        }).catch((err) => {
          addAlert({ message: err, type: 'error', isModal: true })
        })
      }
    },
    async cancelInvitedAgent (agent) {
      if (confirm(`Confirm cancelling invitation for ${agent.stUser?.firstName} ${agent.stUser?.lastName}`)) {
        await cancelAgentInvite(agent.id)

        this.searchAgents('invited-agents')
        addAlert({ message: `Successfully deleted invitation for ${agent.stUser?.firstName} ${agent.stUser?.lastName}`, type: 'success' })
      }
    },
    onTabChanged (val) {
      if (this.firstApiCalled) {
        if (this.$props.onTabClicked) {
          this.$props.onTabClicked(val)
        }
      }
    },
    onPagination () {
      if (this.firstApiCalled) {
        if (this.$props.onPaginationClicked) {
          this.$props.onPaginationClicked(this.selectedAgentTab)
        }

        this.searchAgents(this.selectedAgentTab)
      }
    },
    archiveAgent (agent) {
      // TODO: add confirm with input verification before archiving
      if (this.hasUserType('admin')) {
        const code = agent.stUser ? agent.stUser.phone.substring(agent.stUser.phone.length - 4) : 'archive'
        const promptResult = prompt(`Enter "${code}" below to confirm ARCHIVING this agent`)

        if (promptResult) {
          if (promptResult !== code) {
            alert('Please check the code entered and try again')
          } else {
            agent.isArchiveInProgress = true

            archiveAgent(agent.id).then(() => {
              this.searchAgents(this.selectedAgentTab)
              addAlert({
                message: `Successfully archived agent ${agent.stUser?.firstName} ${agent.stUser?.lastName}`,
                type: 'success'
              })
            }).catch((err) => {
              addAlert({ message: err, type: 'error' })
            }).finally(() => {
              agent.isArchiveInProgress = false
            })
          }
        }
      }
    },
    undoArchiveAgent (agent) {
      if (this.hasUserType('admin')) {
        const code = agent.stUser ? agent.stUser.phone.substring(agent.stUser.phone.length - 4) : 'archive'
        const promptResult = prompt(`Enter "${code}" below to confirm Un-archive this agent`)

        if (promptResult) {
          if (promptResult !== code) {
            alert('Please check the code entered and try again')
          } else {
            agent.isArchiveInProgress = true

            unArchiveAgent(agent.id).then(() => {
              this.searchAgents(this.selectedAgentTab)
              addAlert({
                message: `Successfully un-archived agent ${agent.stUser?.firstName} ${agent.stUser?.lastName}`,
                type: 'success'
              })
            }).catch((err) => {
              addAlert({ message: err, type: 'error' })
            }).finally(() => {
              agent.isArchiveInProgress = false
            })
          }
        }
      }
    },
    getTotal (category) {
      if (category === 'invited-agents') {
        return this.totalInvitedAgents
      } else if (category === 'archived-agents') {
        return this.totalArchivedAgents
      } else {
        return this.totalAgents
      }
    },
    isUserEditable (user) {
      let isEditable = this.hasUserType('superadmin')

      if (this.hasUserType('superadmin')) {
        isEditable = !user.roles.find((o) => o.name === 'superadmin')
      } else if (this.hasUserType('admin')) {
        isEditable = !_intersection(['superadmin', 'admin'], user.roles.map(o => o.name)).length
      }

      return isEditable
    },
    /**
     *
     * @param {string} category - can be 'invited' or 'archived'
     * @returns {Promise<void>}
     */
    async searchAgents (category = this.selectedAgentTab) {
      let promises = []
      this.apiInProgress = true
      let retrievedUsers
      if (category === 'invited-agents') {
        if (this.hasUserType('admin')) {
          this.getTotalArchivedAgents().then((total) => {
            this.totalArchivedAgents = total.count
          })
        }

        this.getTotalAgents().then((total) => {
          this.totalAgents = total.count
        })

        promises.push(this.getInvitedAgents())
        promises.push(this.getTotalInvitedAgents())
        const [ users, total ] = await Promise.all(promises)
        this.totalInvitedAgents = total.count
        retrievedUsers = users
      } else if (category === 'archived-agents') {
        this.getTotalInvitedAgents().then((total) => {
          this.totalInvitedAgents = total.count
        })

        this.getTotalAgents().then((total) => {
          this.totalAgents = total.count
        })

        promises.push(this.getArchivedAgents())
        promises.push(this.getTotalArchivedAgents())
        const [ users, total ] = await Promise.all(promises)
        this.totalArchivedAgents = total.count
        retrievedUsers = users
      } else {
        if (this.hasUserType('admin')) {
          this.getTotalArchivedAgents().then((total) => {
            this.totalArchivedAgents = total.count
          })
        }

        if (this.hasUserType('admin')) {
          this.getTotalInvitedAgents().then((total) => {
            this.totalInvitedAgents = total.count
          })
        }

        promises.push(this.getAgents())
        promises.push(this.getTotalAgents())
        const [ users, total ] = await Promise.all(promises)
        this.totalAgents = total.count
        retrievedUsers = users
      }

      this.agents = retrievedUsers.map(o => {
        o.firstName = o.stUser?.firstName || 'Unknown/deleted'
        o.lastName = o.stUser?.lastName || 'Unknown/deleted'
        o.email = o.stUser?.email || ''
        o.phone = o.stUser?.phone || ''
        const currency = this.countryCurrencies && this.countryCurrencies[o.countryId] ? this.countryCurrencies[o.countryId].currencySymbol + ' ' : ''
        o.balanceLabel = currency + formatCurrency(o.balance)
        o.isExpired = o.stUser ? new Date(o.stUser.verificationTokenExpiry).getTime() <= Date.now() : false
        o.verificationTokenExpiryLabel = this.selectedAgentTab === 'invited-agents' && o.stUser ? `${formatTime(o.stUser.verificationTokenExpiry)}, ${formatDate(o.stUser.verificationTokenExpiry)}` : null
        o.dateArchivedLabel = this.selectedAgentTab === 'archived-agents' ? `${formatTime(o.dateArchived)}, ${formatDate(o.dateArchived)}` : null
        o.dateCreatedLabel = `${formatTime(o.dateCreated)}, ${formatDate(o.dateCreated)}`
        o.dateUpdatedLabel = o.dateUpdated ? `${formatTime(o.dateUpdated)}, ${formatDate(o.dateUpdated)}` : ''
        o.userType = o.stUser?.roles && o.stUser.roles.length ? o.stUser.roles[0].name : null
        o.userTypeLabel = o.stUser?.roles ? o.stUser.roles.map(o => _upperFirst(o.name === 'investor' ? 'stakeholder' : o.name)).join(', ') : ''
        o.isEditable = o.stUser ? this.isUserEditable(o.stUser) : true
        return o
      })

      this.apiInProgress = false
    },
    getGenericFilter () {
      const { sortBy, descending, page, rowsPerPage } = this.pagination

      const params = {
        page,
        limit: rowsPerPage
      }

      if (sortBy) {
        params.sort = sortBy
        params.order = descending ? 'DESC' : 'ASC'
      }

      if (this.searchKeywords) {
        params.keywords = this.searchKeywords
      }

      if (this.selectedCountryId) {
        params.countryId = this.selectedCountryId
      }

      return params
    },
    async getAgents () {
      return findAgents(this.getGenericFilter())
    },
    async getTotalAgents () {
      return findTotalAgents(this.getGenericFilter())
    },
    async getInvitedAgents () {
      return findAgents({ ...this.getGenericFilter(), invited: true })
    },
    async getTotalInvitedAgents () {
      return findTotalAgents({ ...this.getGenericFilter(), invited: true })
    },
    async getArchivedAgents () {
      return findAgents({ ...this.getGenericFilter(), archived: true })
    },
    async getTotalArchivedAgents () {
      return findTotalAgents({ ...this.getGenericFilter(), archived: true })
    }
  }
}
