<template>
  <div>
    <v-btn flat icon @click="toggleAlertsVisible()" class="site-alerts-trigger">
      <span v-show="getAlerts.length" class="total">{{getAlerts.length}}</span>
      <v-icon v-html="'fal fa-bell'"></v-icon>
    </v-btn>
    <v-snackbar
        :value="getAlerts.length && isAtLeastOneVisible"
        top right multi-line auto-height
        :vertical="$vuetify.breakpoint.smAndDown"
        :timeout="0"
    >
      <div class="site-alerts-messages">
        <div v-for="alert in getAlerts" :key="`key${alert.id}`" v-show="alert.visible">
          <div><small><v-icon small :class="`site-alerts-messages__${alert.type}`" v-html="getAlertIcon(alert)"></v-icon> &nbsp; {{alert.date}}</small></div>
          <div class="mb-2 mr-3" v-html="alert.message"></div>
        </div>
      </div>
      <v-btn
          class="site-alerts-close"
          color="pink"
          flat
          @click="removeAllAlerts()"
      >
        Close
      </v-btn>
    </v-snackbar>
  </div>
</template>

<style lang="scss" scoped>
  .site-alerts-trigger {
    position: relative;
    span.total {
      position: absolute;
      display: block;
      width: 15px;
      height: 15px;
      top: 0;
      right: 0;
      background-color: red;
      border-radius: 50%;
      color: #fff;
      font-size: 0.8em;
    }
  }
  .site-alerts-messages {
    max-height: 200px;
    overflow-y: auto;
    .site-alerts-messages__success {
      color: #4CAF50
    }
    .site-alerts-messages__info {
      color: #2196F3
    }
    .site-alerts-messages__warning {
      color: #FFC107
    }
    .site-alerts-messages__error {
      color: #f65122
    }
  }
  .site-alerts-close {
    align-self: flex-start
  }
  .v-snack--vertical .v-snack__content .site-snackbar-close.v-btn.v-btn {
    margin-top: 0
  }
</style>

<script>
import src from './src'
export default src
</script>
