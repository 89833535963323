import { apiRequests } from '@/utilities/axios-factory'
import store from '../store'

function getPassengerTransactionLog ({ id, filter }) {
  return apiRequests.get('/v1/passenger-transaction-log/' + id + '/', {
    params: { filter },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function findPassengerTransactionLogs (filter) {
  return apiRequests.get('/v1/passenger-transaction-log/join/', {
    params: { filter },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function findTotalPassengerTransactionLogs ({ where, join }) {
  return apiRequests.get('/v1/passenger-transaction-log/count/join/', {
    params: { where, join },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function findVendorTransactionLogs ({ vendor, filter, searchPhone, stUserKeywords }) {
  return apiRequests.get('/v1/passenger-transaction-log/vendor/' + vendor + '/', {
    params: { filter, searchPhone, stUserKeywords },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function findTotalVendorTransactionLogs ({ vendor, where, searchPhone, stUserKeywords }) {
  return apiRequests.get('/v1/passenger-transaction-log/vendor/' + vendor + '/count/', {
    params: { where, searchPhone, stUserKeywords },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function findGeneralVendorTransactionLogs ({ filter, searchPhone }) {
  return apiRequests.get('/v1/passenger-transaction-log/vendor/', {
    params: { filter, searchPhone },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function findTotalGeneralVendorTransactionLogs ({ where, searchPhone }) {
  return apiRequests.get('/v1/passenger-transaction-log/vendor/count/', {
    params: { where, searchPhone },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

export {
  getPassengerTransactionLog,
  findPassengerTransactionLogs,
  findTotalPassengerTransactionLogs,
  findVendorTransactionLogs,
  findTotalVendorTransactionLogs,
  findGeneralVendorTransactionLogs,
  findTotalGeneralVendorTransactionLogs
}
