import qs from 'querystring'
import { apiRequests } from '../utilities/axios-factory'
import {
  D_GET_USER,
  D_FIND_STAFF,
  D_FIND_STAFF_TOTAL,
  D_INVITE_STAFF,
  D_DELETE_INVITE_STAFF,
  D_RESEND_INVITE_STAFF,
  D_INVITE_TRANSPORT_USER,
  // D_DELETE_INVITE_TRANSPORT_USER,
  D_RESEND_INVITE_TRANSPORT_USER,
  D_UPDATE_STAFF,
  D_DELETE_STAFF,
  D_UNDO_DELETE_STAFF,
  D_FIND_INVITED_STAFF,
  D_FIND_INVITED_STAFF_TOTAL,
  D_VERIFY_STAFF_INVITE
} from '../utilities/action-types'
import { staffUserTypes } from '../utilities/helpers'

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    [D_GET_USER] ({ commit, getters }, { id, filter, isDeleted }) {
      if (isDeleted) {
        return apiRequests.get('/v1/raw-st-user/' + id + '/', {
          params: { filter },
          headers: { 'Authorization': getters.getTokenId }
        }).then((result) => {
          return result.data
        })
      } else {
        return apiRequests.get('/v1/st-user/' + id + '/', {
          params: { filter },
          headers: { 'Authorization': getters.getTokenId }
        }).then((result) => {
          return result.data
        })
      }
    },
    [D_FIND_STAFF] ({ commit, getters }, { userTypes, filter, includeDeleted }) {
      return apiRequests.get('/v1/st-user/by-roles/', {
        params: { roles: userTypes || staffUserTypes, filter, includeDeleted },
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    },
    [D_FIND_STAFF_TOTAL] ({ commit, getters }, { userTypes, where, includeDeleted }) {
      return apiRequests.get('/v1/st-user/count/by-roles/', {
        params: { roles: userTypes || staffUserTypes, where, includeDeleted },
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    },
    [D_FIND_INVITED_STAFF] ({ commit, getters }, { filter, includeDeleted }) {
      return apiRequests.get('/v1/st-user/invitation/', {
        params: { roles: ['staff', 'admin'], filter, includeDeleted },
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    },
    [D_FIND_INVITED_STAFF_TOTAL] ({ commit, getters }, { where, includeDeleted }) {
      return apiRequests.get('/v1/st-user/invitation/count/', {
        params: { roles: ['staff', 'admin'], where, includeDeleted },
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    },
    [D_UPDATE_STAFF] ({ commit, getters }, { id, data }) {
      return apiRequests.patch('/v1/st-user/' + id + '/', data, {
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    },
    [D_DELETE_STAFF] ({ commit, getters }, { id }) {
      return apiRequests.delete('/v1/st-user/' + id + '/', {
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    },
    [D_UNDO_DELETE_STAFF] ({ commit, getters }, { id }) {
      return apiRequests.put('/v1/st-user/' + id + '/deleted/', null, {
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    },
    [D_INVITE_STAFF] ({ commit, getters }, data) {
      return apiRequests.post('/v1/st-user/invitation/', qs.stringify(data), {
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    },
    [D_RESEND_INVITE_STAFF] ({ commit, getters }, { id }) {
      return apiRequests.put('/v1/st-user/' + id + '/invitation/', {}, {
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    },
    [D_DELETE_INVITE_STAFF] ({ commit, getters }, { id }) {
      return apiRequests.delete('/v1/st-user/' + id + '/invitation/', {
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    },
    [D_INVITE_TRANSPORT_USER] ({ commit, getters }, { transportationOwnerId, data }) {
      return apiRequests.post('/v1/transportation-owner/' + transportationOwnerId + '/transportation-owner-user', data, {
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    },
    [D_RESEND_INVITE_TRANSPORT_USER] ({ commit, getters }, { transportationOwnerId, id }) {
      return apiRequests.put('/v1/transportation-owner/' + transportationOwnerId + '/transportation-owner-user/' + id + '/', {}, {
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    },
    /**
     * @param {function} commit
     * @param {function} getters
     * @param {object} data - {email, phone, verificationToken, password, confirmedPassword}
     */
    [D_VERIFY_STAFF_INVITE] ({ commit, getters }, data) {
      return apiRequests.put('/v1/st-user/invitation/verification', data).then((result) => {
        return result.data
      })
    }
  }
}
