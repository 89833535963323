import '@babel/polyfill'
import './plugins/vuetify'
import './plugins/virtual-scroller'
import './plugins/qrcode-reader'
import './plugins/uploader'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import components from './components/'

Vue.config.productionTip = false
for (const key in components) {
  Vue.component(key, components[key])
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
