import { mapGetters } from 'vuex'
import {
  C_CLEAR_SITE_ALERTS,
  C_HIDE_SITE_ALERT,
  C_SHOW_SITE_ALERT
} from '../../utilities/mutation-types'

export default {
  name: 'site-alerts-tray',
  data () {
    return {}
  },
  computed: {
    ...mapGetters({
      getAlerts: 'getSiteAlerts'
    }),
    isAtLeastOneVisible () {
      for (let i = 0; i < this.$store.state.siteAlerts.alerts.length; i++) {
        if (this.$store.state.siteAlerts.alerts[i].visible) {
          return true
        }
      }
      return false
    }
  },
  methods: {
    getAlertIcon (alert) {
      switch (alert.type) {
        case 'success':
          return 'fal fa-check-circle'
        case 'info':
          return 'fal fa-info-circle'
        case 'warning':
          return 'fal fa-exclamation-circle'
        case 'error':
          return 'fal fa-times-circle'
      }
    },
    removeAllAlerts () {
      this.$store.commit(C_CLEAR_SITE_ALERTS)
    },
    toggleAlertsVisible () {
      if (!this.isAtLeastOneVisible) {
        this.$store.state.siteAlerts.alerts.forEach((alert) => {
          this.$store.commit(C_SHOW_SITE_ALERT, alert.id)
        })
      } else {
        this.$store.state.siteAlerts.alerts.forEach((alert) => {
          this.$store.commit(C_HIDE_SITE_ALERT, alert.id)
        })
      }
    }
  }
}
