import { findPassengerIdentifiers } from '../../services/passengers-service'
import { generateKeywordsQueryForOr } from '@smarttransit/common-client'

export default {
  name: 'passenger-selector',
  props: {
    childProps: Object,
    value: Object,
    resultLimit: Number,
    delay: Number,
    initialSearchKeyword: String
  },
  data () {
    return {
      selectedPassenger: null,
      passengers: [],
      apiInProgress: false,
      searchKeywords: ''
    }
  },
  mounted () {
    if (this.$props.value) {
      this.selectedPassenger = this.$props.value
    }

    this.searchPassengers()
  },
  watch: {
    selectedPassenger: function (newVal) {
      this.$emit('input', newVal)
    },
    initialSearchKeyword (val) {
      if (val && !this.searchingInInitialSearchKeyword) {
        this.searchKeywords = val
        this.searchingInInitialSearchKeyword = true

        this.searchPassengers().then(() => {
          if (this.passengers?.length === 1) {
            this.selectedPassenger = { ...this.passengers[0] }
          }
        }).finally(() => {
          this.searchingInInitialSearchKeyword = false
        })
      }
    },
    searchKeywords (val) {
      clearTimeout(this.searchKeywordsHandle)
      if (val) {
        this.searchKeywordsHandle = setTimeout(async () => {
          this.searchPassengers()
        }, this.$props.delay || 800)
      }
    },
    value (val) {
      this.selectedPassenger = val
    }
  },
  methods: {
    async searchPassengers () {
      let filter = {
        where: { and: [{ verified: true }, { phoneNumber: { nilike: 'deleted%' } }] },
        join: { relation: 'passengerProfile', scope: { where: { dateArchived: null }, join: { relation: 'stUser', scope: { where: null } } } },
        include: { passengerProfile: 'stUser' },
        limit: this.$props.resultLimit || 10,
        order: 'dateUpdated DESC'
      }
      if (this.searchKeywords) {
        filter.where.and.push({ or: generateKeywordsQueryForOr(this.searchKeywords, ['phoneNumber']) })
      }
      this.passengers = await findPassengerIdentifiers(filter)
    }
  },
  beforeDestroy () {
    clearTimeout(this.searchKeywordsHandle)
  }
}
