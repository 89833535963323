import { findUsers } from '../../services/users-service'
import { addAlert } from '../../utilities/helpers'
import _upperFirst from 'lodash.upperfirst'

export default {
  name: 'user-selector',
  props: {
    childProps: Object,
    value: Object,
    resultLimit: Number,
    delay: Number
  },
  data () {
    return {
      selectedUser: null,
      users: [],
      apiInProgress: false,
      searchKeywords: ''
    }
  },
  mounted () {
    if (this.$props.value) {
      this.selectedUser = this.$props.value
    }

    this.searchUsers()
  },
  watch: {
    selectedUser: function (newVal) {
      this.$emit('input', newVal)
    },
    searchKeywords (val) {
      clearTimeout(this.searchKeywordsHandle)
      if (val) {
        this.searchKeywordsHandle = setTimeout(async () => {
          this.searchUsers()
        }, this.$props.delay || 800)
      }
    },
    value (val) {
      this.selectedUser = val
    }
  },
  methods: {
    async searchUsers () {
      try {
        this.apiInProgress = true

        this.users = await findUsers({
          keywords: this.searchKeywords,
          includeArchived: true,
          limit: this.$props.resultLimit || 10,
          order: 'dateUpdated DESC'
        })

        this.users.forEach((user) => {
          user.phone = user.phone || ''
          user.truncatedEmail = user.email && user.email.length > 30 ? user.email.substring(0, 30) + '...' : user.email
          user.fullName = (user.firstName ? user.firstName + ' ' : '') + (user.lastName ? user.lastName : '')
          user.userTypeLabel = user.roles ? user.roles.map(o => _upperFirst(o.name === 'investor' ? 'stakeholder' : o.name)).join(', ') : ''
        })
      } catch (error) {
        addAlert({ message: error, type: 'error' })
      } finally {
        this.apiInProgress = false
      }
    }
  },
  beforeDestroy () {
    clearTimeout(this.searchKeywordsHandle)
  }
}
