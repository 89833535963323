import moment from 'moment-timezone'
import _cloneDeep from 'lodash.clonedeep'
import { getCachedHoursOfOperationTemplates } from '../../services/st-settings-service'

const defaultHoursOfOperation = {
  '1': [['05:00', '23:00']],
  '2': [['05:00', '23:00']],
  '3': [['05:00', '23:00']],
  '4': [['05:00', '23:00']],
  '5': [['05:00', '23:00']],
  '6': [['05:00', '22:00']],
  '7': [['08:00', '22:00']]
}

const daysOfTheWeek = {
  '1': 'Monday',
  '2': 'Tuesday',
  '3': 'Wednesday',
  '4': 'Thursday',
  '5': 'Friday',
  '6': 'Saturday',
  '7': 'Sunday'
}

function formatFrom24Hrsto12Hrs (hoursSet) {
  const startTime = moment.utc(hoursSet[0], 'HH:mm')
  const endTime = moment.utc(hoursSet[1], 'HH:mm')
  return [startTime.format('h:mma'), endTime.format('h:mma')]
}

function formatFrom12Hrsto24Hrs (hoursSet) {
  const startTime = moment.utc(hoursSet[0], 'h:mma')
  const endTime = moment.utc(hoursSet[1], 'h:mma')
  return [startTime.format('HH:mm'), endTime.format('HH:mm')]
}

function convertHoursOfOperationToUIVersion (hoursOfOperation) {
  return {
    [daysOfTheWeek['1']]: (hoursOfOperation['1'] || []).map(formatFrom24Hrsto12Hrs),
    [daysOfTheWeek['2']]: (hoursOfOperation['2'] || []).map(formatFrom24Hrsto12Hrs),
    [daysOfTheWeek['3']]: (hoursOfOperation['3'] || []).map(formatFrom24Hrsto12Hrs),
    [daysOfTheWeek['4']]: (hoursOfOperation['4'] || []).map(formatFrom24Hrsto12Hrs),
    [daysOfTheWeek['5']]: (hoursOfOperation['5'] || []).map(formatFrom24Hrsto12Hrs),
    [daysOfTheWeek['6']]: (hoursOfOperation['6'] || []).map(formatFrom24Hrsto12Hrs),
    [daysOfTheWeek['7']]: (hoursOfOperation['7'] || []).map(formatFrom24Hrsto12Hrs)
  }
}

function convertUIVersionToHoursOfOperation (uiVersion) {
  return {
    '1': uiVersion[daysOfTheWeek['1']].map(formatFrom12Hrsto24Hrs),
    '2': uiVersion[daysOfTheWeek['2']].map(formatFrom12Hrsto24Hrs),
    '3': uiVersion[daysOfTheWeek['3']].map(formatFrom12Hrsto24Hrs),
    '4': uiVersion[daysOfTheWeek['4']].map(formatFrom12Hrsto24Hrs),
    '5': uiVersion[daysOfTheWeek['5']].map(formatFrom12Hrsto24Hrs),
    '6': uiVersion[daysOfTheWeek['6']].map(formatFrom12Hrsto24Hrs),
    '7': uiVersion[daysOfTheWeek['7']].map(formatFrom12Hrsto24Hrs)
  }
}

function hoursOfOperationOverlap (ranges, rangeToCompare, indexToSkip) {
  for (let i = 0; i < ranges.length; i++) {
    if ((!indexToSkip && indexToSkip !== 0) || i !== indexToSkip) {
      const startTime = moment.utc(ranges[i][0], 'h:mma').toDate()
      const endTime = moment.utc(ranges[i][1], 'h:mma').toDate()
      if (rangeToCompare[0].getTime() === startTime.getTime() ||
        rangeToCompare[0].getTime() === endTime.getTime() ||
        rangeToCompare[1].getTime() === startTime.getTime() ||
        rangeToCompare[1].getTime() === endTime.getTime() ||
        (rangeToCompare[0].getTime() < startTime.getTime() && rangeToCompare[1].getTime() > endTime.getTime()) ||
        (rangeToCompare[0].getTime() < startTime.getTime() && rangeToCompare[1].getTime() > startTime.getTime() && rangeToCompare[1].getTime() < endTime.getTime()) ||
        (rangeToCompare[0].getTime() > startTime.getTime() && rangeToCompare[0].getTime() < endTime.getTime() && rangeToCompare[1].getTime() > endTime.getTime()) ||
        (rangeToCompare[0].getTime() > startTime.getTime() && rangeToCompare[1].getTime() < endTime.getTime())) {
        return [startTime, endTime]
      }
    }
  }
}

export default {
  name: 'hours-of-operation',
  props: {
    value: Object, // { hours:object, timezone:string, templateId },
    enableTemplates: Boolean,
    enableTimezone: Boolean,
    useDefaultTemplateIfNew: Boolean
  },
  data () {
    return {
      currentHoursOfOperation: this.$props.value && this.$props.value.hours
        ? convertHoursOfOperationToUIVersion(this.$props.value.hours)
        : (this.$props.useDefaultTemplateIfNew ? convertHoursOfOperationToUIVersion(defaultHoursOfOperation) : null),
      currentTimezone: this.$props.value && this.$props.value.timezone ? this.$props.value.timezone : moment.tz.guess(),
      editHourSetModal: false,
      currentHourSet: null,
      templateIndex: this.$props.value && this.$props.value.templateId ? this.$props.value.templateId : null,
      template: null,
      templateOptions: [],
      confirmTemplateDialog: false
    }
  },
  watch: {
    enableTemplates (newVal) {
      if (newVal) {
        this.loadTemplates()
      }
    },
    currentHoursOfOperation (newVal) {
      this.$emit('input', { hours: newVal ? convertUIVersionToHoursOfOperation(newVal) : null, timezone: this.currentTimezone, templateId: this.$props.value.templateId })
    },
    currentTimezone (newVal) {
      this.$emit('input', { hours: this.$props.value.hours, timezone: newVal, templateId: this.$props.value.templateId })
    },
    templateIndex (newVal) {
      if (newVal) {
        const foundHoursOfOperation = this.templateOptions.find((o) => o.value === newVal)
        this.template = convertHoursOfOperationToUIVersion(foundHoursOfOperation.metadata)
        this.confirmTemplateDialog = true
      } else {
        this.template = null
        this.confirmTemplateDialog = false
      }
    }
  },
  mounted () {
    if (this.$props.enableTemplates) {
      this.loadTemplates().then(() => {
        if (this.$props.value && this.$props.value.templateId) {
          const foundHoursOfOperation = this.templateOptions.find((o) => o.value === this.$props.value.templateId)
          this.template = convertHoursOfOperationToUIVersion(foundHoursOfOperation.metadata)
        }
      })
    }

    if (this.currentHoursOfOperation) {
      this.$emit('input', {
        hours: convertUIVersionToHoursOfOperation(this.currentHoursOfOperation),
        timezone: this.currentTimezone,
        templateId: this.$props.value.templateId
      })
    }
  },
  methods: {
    loadTemplates () {
      return getCachedHoursOfOperationTemplates().then((templates) => {
        this.templateOptions = []
        if (templates) {
          this.templateOptions = templates.map((template) => {
            return {
              text: template.templateName,
              value: template.id,
              metadata: template.data
            }
          })
        }
      }).catch((err) => {
        alert(`Error retrieving templates (please reload the page and try again): ${err && err.error ? err.error.message : (err && err.message ? err.message : 'Unknown error')}`)
      })
    },
    confirmTemplate () {
      this.currentHoursOfOperation = null
      this.confirmTemplateDialog = false

      this.$emit('input', {
        hours: null,
        timezone: this.currentTimezone,
        templateId: this.templateIndex
      })
    },
    confirmCustomFromTemplate () {
      this.currentHoursOfOperation = _cloneDeep(this.template)
      this.template = null
      this.templateIndex = null
      this.confirmTemplateDialog = false

      this.$emit('input', {
        hours: this.currentHoursOfOperation,
        timezone: this.currentTimezone,
        templateId: null
      })
    },
    cancelTemplate () {
      this.confirmTemplateDialog = false
      this.templateIndex = null
      this.template = null
    },
    addHourSet (index) {
      this.currentHourSet = { index, hourSetIndex: this.currentHoursOfOperation[index].length, hourSet: [moment('5:00am', 'h:mma').toDate(), moment('11:00pm', 'h:mma').toDate()] }
      this.editHourSetModal = true
    },
    editHourSet (index, hourSetIndex, hourSet) {
      this.currentHourSet = { index, hourSetIndex, hourSet: [moment(hourSet[0], 'h:mma').toDate(), moment(hourSet[1], 'h:mma').toDate()] }
      this.editHourSetModal = true
    },
    deleteHourSet () {
      if (confirm('Confirm removing this time range?')) {
        this.currentHoursOfOperation[this.currentHourSet.index].splice([this.currentHourSet.hourSetIndex], 1)
        this.currentHoursOfOperation = { ...this.currentHoursOfOperation }
        this.editHourSetModal = false
      }
    },
    saveHourSet () {
      if (this.currentHourSet.hourSet[0].getTime() > this.currentHourSet.hourSet[1].getTime()) {
        return alert(`The time range ${moment.utc(this.currentHourSet.hourSet[0]).format('h:mma')} - ${moment.utc(this.currentHourSet.hourSet[1]).format('h:mma')} is invalid`)
      }

      const result = hoursOfOperationOverlap(this.currentHoursOfOperation[this.currentHourSet.index], this.currentHourSet.hourSet, this.currentHourSet.hourSetIndex)

      if (result) {
        return alert(`The time range overlaps with ${moment.utc(result[0]).format('h:mma')} - ${moment.utc(result[1]).format('h:mma')} on ${this.currentHourSet.index}`)
      }

      if (!this.currentHoursOfOperation[this.currentHourSet.index][this.currentHourSet.hourSetIndex] &&
        this.currentHoursOfOperation[this.currentHourSet.index].length < this.currentHourSet.hourSetIndex + 1) {
        this.currentHoursOfOperation[this.currentHourSet.index].push([])
      }

      this.currentHoursOfOperation[this.currentHourSet.index][this.currentHourSet.hourSetIndex] = [moment.utc(this.currentHourSet.hourSet[0]).format('h:mma'), moment.utc(this.currentHourSet.hourSet[1]).format('h:mma')]
      // order the array in case values entered ou of order
      this.currentHoursOfOperation = { ...this.currentHoursOfOperation }
      this.editHourSetModal = false
    }
  }
}
