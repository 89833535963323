import _upperFirst from 'lodash.upperfirst'
import _cloneDeep from 'lodash.clonedeep'
import _isEqual from 'lodash.isequal'
import { formatDate, formatTime } from '@smarttransit/common'
import { generateKeywordsQueryForOr } from '@smarttransit/common-client'

import {
  hasTransportationRole as hasTransportationRoleFunc,
  isAuthorized,
  addAlert
} from '../../utilities/helpers'

import {
  findTotalTransportationOwnerUsers,
  findTransportationOwnerUsers,
  deleteTransportationOwnerUser,
  deleteTransportationOwnerUserInvite,
  resendTransportationOwnerUserInvite,
  createTransportationOwnerUser,
  updateTransportationOwnerUser
} from '../../services/transportation-owner-users-service'

import { updateTransportationUserIdentifier, createTransportationUserIdentifier, deRegisterTransportationUserMomoAccount } from '../../services/transportation-user-identifiers-service'

const ROOT_EMAIL = 'root@smarttransit.io'

export default {
  name: 'transportation-user-management',
  props: {
    signedInUser: Object,
    transportationOwnerId: Number,
    transportationOwner: Object,
    onUserCreated: Function,
    onUserDeleted: Function,
    onUserUpdated: Function,
    onError: Function
  },
  data () {
    return {
      users: [],
      totalUsers: 0,
      totalArchivedUsers: 0,
      totalInvitedUsers: 0,
      userModalApiInProgress: false,
      apiInProgress: false,
      isArchiveInProgress: false,
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 10,
        sortBy: 'dateUpdated'
      },
      searchKeywords: '',
      selectedUserTab: 'users',
      userInviteLoaded: false,
      invitedUser: null,
      selectedUser: null,
      updateUserLoaded: false,
      mobileMoneyModalLoaded: false,
      updatingUser: null,
      mobileMoneyUser: null,
      modalSiteAlertData: null,
      modalSiteAlert: false,
      selectedRole: null,
      phonePrefix: null,
      moneyAccountLogos: {},
      moneyAccountTypes: [],
      transportationRoles: [],
      userTypesByAuthorization: [],
      headers: [],
      invitationHeaders: []
    }
  },
  watch: {
    userInviteLoaded (val) {
      if (val) {
        this.invitedUser = {
          userType: this.userTypesByAuthorization && this.userTypesByAuthorization.length === 1 ? this.userTypesByAuthorization[0].value : 'DRIVER',
          verificationType: 'phone',
          email: '',
          phone: '',
          firstName: '',
          lastName: ''
        }
      } else {
        this.invitedUser = null
      }
    },
    transportationOwner (val) {
      const transportationOwner = val
      if (transportationOwner) {
        this.setPhonePrefixByCountryId(transportationOwner.currentCountryId)
      }
    }
  },
  mounted: function () {
    this.moneyAccountTypes = this.$store.getters.getMoneyAccountTypes
    this.moneyAccountLogos = this.$store.getters.getMoneyAccountLogos
    this.transportationRoles = this.$store.getters.getTransportationRoles
    let headers = [
      {
        text: 'Last Name',
        align: 'left',
        sortable: true,
        value: 'lastName'
      },
      {
        text: 'First Name',
        align: 'left',
        sortable: true,
        value: 'firstName'
      },
      {
        text: 'Phone',
        align: 'left',
        sortable: true,
        value: 'phone'
      },
      {
        text: 'Email',
        align: 'left',
        sortable: true,
        value: 'email'
      },
      {
        text: 'User Type',
        align: 'left',
        sortable: false
      },
      {
        text: 'Rating',
        align: 'left',
        sortable: true,
        value: 'rating'
      },
      {
        text: 'Date Created',
        align: 'left',
        sortable: true,
        value: 'dateCreated'
      },
      {
        text: 'Date Updated',
        align: 'left',
        sortable: true,
        value: 'dateUpdated'
      },
      { text: 'Actions', sortable: false }
    ]
    this.headers = headers
    this.invitationHeaders = [
      {
        text: 'Last Name',
        align: 'left',
        sortable: true,
        value: 'lastName'
      },
      {
        text: 'First Name',
        align: 'left',
        sortable: true,
        value: 'firstName'
      },
      {
        text: 'Phone',
        align: 'left',
        sortable: true,
        value: 'phone'
      },
      {
        text: 'Email',
        align: 'left',
        sortable: true,
        value: 'email'
      },
      {
        text: 'User Type',
        align: 'left',
        sortable: false
      },
      {
        text: 'Date Created',
        align: 'left',
        sortable: true,
        value: 'dateCreated'
      },
      {
        text: 'Invitation Expiry Date',
        align: 'left',
        sortable: true,
        value: 'verificationTokenExpiry'
      },
      { text: 'Actions', sortable: false }
    ]
    if (this.$props.signedInUser) {
      let filteredUserTypes = [{ text: 'Not authorized to set a user type', value: 'none' }]
      if (this.$props.signedInUser) {
        if (this.hasUserType('admin') || this.hasTransportationRole('OWNER')) {
          filteredUserTypes = this.transportationRoles.filter(o => o.value !== 'OWNER')
        } else if (this.hasTransportationRole('ADMIN')) {
          filteredUserTypes = this.transportationRoles.filter(o => o.value === 'DRIVER')
        }
      }
      this.userTypesByAuthorization = filteredUserTypes
    } else {
      this.userTypesByAuthorization = []
    }
    if (this.$props.transportationOwner) {
      this.setPhonePrefixByCountryId(this.$props.transportationOwner.currentCountryId)
    }
    this.searchUsers()
  },
  methods: {
    loadMobileMoneyModal (user) {
      let transportationUserMomoAccount
      const transportationUserIdentifier = user.transportationUserIdentifiers && user.transportationUserIdentifiers.length ? user.transportationUserIdentifiers[0] : null
      if (transportationUserIdentifier && transportationUserIdentifier.transportationUserMomoAccounts && transportationUserIdentifier.transportationUserMomoAccounts.length) {
        transportationUserMomoAccount = transportationUserIdentifier.transportationUserMomoAccounts[0]
      }
      this.mobileMoneyUser = {
        user: user,
        id: transportationUserMomoAccount ? transportationUserMomoAccount.id : null,
        phoneNumber: transportationUserIdentifier ? transportationUserIdentifier.phoneNumber : user.stUser.phone,
        moneyAccountTypeId: transportationUserMomoAccount ? transportationUserMomoAccount.moneyAccountTypeId : ''
      }
      this.clonedMobileMoneyUser = _cloneDeep(this.mobileMoneyUser)
      this.mobileMoneyModalLoaded = true
    },
    closeAndLoadMobileMoneyModal (user) {
      this.updateUserLoaded = false
      this.loadMobileMoneyModal(user)
    },
    loadCreateUserModal () {
      if (!this.$props.transportationOwnerId) {
        alert('Please select an account first')
      } else {
        this.userInviteLoaded = true
      }
    },
    async deRegisterMobileMoney () {
      if (confirm('Confirm removing this mobile money account')) {
        try {
          const result = await deRegisterTransportationUserMomoAccount({ id: this.mobileMoneyUser.id })
          this.mobileMoneyModalLoaded = false
          this.searchUsers(this.selectedUserTab)
          if (result && result.success) {
            this.addAlert('Successfully removed mobile money account', 'success', 'isModal')
          }
        } catch (err) {
          this.addAlert(`Error in removing mobile money account: ${err && err.error && err.error.message ? err.error.message : JSON.stringify(err)}`, 'error', 'isModal')
        }
      }
    },
    hasUserType (role) {
      const { signedInUser } = this.$props
      return (signedInUser && isAuthorized(signedInUser, role))
    },
    setPhonePrefixByCountryId (countryId) {
      switch (countryId) {
        case 'GHA':
          this.phonePrefix = '+233'
          break
      }
    },
    hasTransportationRole (transportationRole) {
      const { signedInUser } = this.$props
      if (signedInUser) {
        this.transportationOwnerUser = this.transportationOwnerUser || signedInUser.transportationOwnerUsers.find((o) => (o.transportationOwnerId === this.$props.transportationOwnerId))
        return hasTransportationRoleFunc(signedInUser, transportationRole, this.$props.transportationOwnerId, this.transportationOwnerUser)
      }
    },
    isUpdatingUserEdited () {
      return !_isEqual(this.updatingUser, this.clonedUpdatingUser)
    },
    isMobileMoneyUserEdited () {
      return !_isEqual(this.mobileMoneyUser, this.clonedMobileMoneyUser)
    },
    loadUpdateUser (user) {
      this.updatingUser = {
        id: user.id,
        firstName: user.stUser.firstName,
        lastName: user.stUser.lastName,
        email: user.stUser.email,
        phone: user.stUser.phone,
        transportationIdentifier: user.transportationIdntifiers && user.transportationIdntifiers.length ? user.transportationIdntifiers[0].phoneNumber : '',
        mobileMoneyAccountType: user.mobileMoneyAccountType,
        user: user,
        isEditable: user.isEditable,
        userType: this.getUserTypeLabel(user.roles, 'returnValue'),
        excludeFeeLabel: !!(user.details && user.details.excludeFeeLabel)
      }
      if (!this.$props.transportationOwner) {
        this.setPhonePrefixByCountryId(user.transportationOwner.currentCountryId)
      }
      this.clonedUpdatingUser = _cloneDeep(this.updatingUser)
      this.updateUserLoaded = true
    },
    cancelEdit () {
      this.updateUserLoaded = false
      this.updatingUser = null
    },
    cancelMobileMoneyUserEdit () {
      this.mobileMoneyModalLoaded = false
      this.mobileMoneyUser = null
    },
    saveMobileMoneyUser () {
      if (this.mobileMoneyUser) {
        if (!this.mobileMoneyUser.phoneNumber || !this.mobileMoneyUser.moneyAccountTypeId) {
          return this.addAlert('Please enter mobile money number and money account type', 'error', 'isModal')
        }
        this.mobileMoneyUser.isLoading = true
        let promise
        if (this.mobileMoneyUser.user.transportationUserIdentifiers && this.mobileMoneyUser.user.transportationUserIdentifiers.length) {
          promise = updateTransportationUserIdentifier({
            id: this.mobileMoneyUser.user.transportationUserIdentifiers[0].id,
            phoneNumber: this.mobileMoneyUser.phoneNumber,
            moneyAccountTypeId: this.mobileMoneyUser.moneyAccountTypeId
          })
        } else {
          promise = createTransportationUserIdentifier({
            phoneNumber: this.mobileMoneyUser.phoneNumber,
            transportationOwnerUserId: this.mobileMoneyUser.user.id,
            moneyAccountTypeId: this.mobileMoneyUser.moneyAccountTypeId
          })
        }
        promise.then(() => {
          this.searchUsers(this.selectedUserTab)
          this.addAlert(`Successfully updated mobile money account ${this.mobileMoneyUser.phoneNumber}`, 'success')
          this.cancelMobileMoneyUserEdit()
        }).catch((err) => {
          if (this.mobileMoneyUser) {
            this.mobileMoneyUser.isLoading = false
          }
          this.addAlert(`Error in updating mobile money: ${err && err.error && err.error.message ? err.error.message : JSON.stringify(err)}`, 'error', 'isModal')
        })
      }
    },
    updateUser () {
      if (this.updatingUser.isEditable) {
        this.updatingUser.isLoading = true
        updateTransportationOwnerUser({
          id: this.updatingUser.id,
          firstName: this.updatingUser.firstName,
          lastName: this.updatingUser.lastName,
          email: this.updatingUser.email,
          phone: !this.updatingUser.phone || this.updatingUser.phone === 'null' || this.updatingUser.phone === this.phonePrefix ? undefined : this.updatingUser.phone,
          role: this.updatingUser.userType,
          excludeFeeLabel: this.updatingUser.excludeFeeLabel
        }).then((result) => {
          this.updateUserLoaded = false
          this.searchUsers(this.selectedUserTab)
          this.addAlert(`Successfully updated user ${this.updatingUser.firstName} ${this.updatingUser.lastName}`, 'success')
          this.updatingUser = null
        }).catch((err) => {
          this.addAlert(`Error in updating user: ${err && err.error.message ? err.error.message : JSON.stringify(err)}`, 'error', 'isModal')
        }).finally(() => {
          if (this.updatingUser) {
            this.updatingUser.isLoading = false
          }
        })
      }
    },
    addAlert (message, type, isModal = undefined) {
      if (this.type === 'error' && typeof this.$props.onError === 'function') {
        this.$props.onError(new Error(message))
      }
      if (isModal) {
        this.modalSiteAlertData = { type: type.toUpperCase(), message }
        this.modalSiteAlert = true
      } else {
        addAlert({
          message,
          type,
          transient: type === 'success'
        })
      }
    },
    currentUserHasRole (role) {
      const user = this.$store?.state?.credentials?.user || []
      return isAuthorized(user, role)
    },
    inviteUser () {
      if (!this.$props.transportationOwnerId) {
        return this.addAlert('Please select an account', 'warning', 'isModal')
      }
      if (!this.invitedUser || (!this.invitedUser.email && !this.invitedUser.phone) || !this.invitedUser.firstName || !this.invitedUser.lastName || !this.invitedUser.userType) {
        return this.addAlert('One of email, phone, first name, last name or user type not provided', 'error', 'isModal')
      }
      if (!this.invitedUser.userType) {
        return this.addAlert('Please select a user type', 'error', 'isModal')
      }
      this.userModalApiInProgress = true
      createTransportationOwnerUser({
        transportationOwnerId: this.$props.transportationOwnerId,
        email: this.invitedUser.verificationType === 'email' ? this.invitedUser.email : '',
        phone: this.invitedUser.verificationType === 'phone' ? this.invitedUser.phone : '',
        firstName: this.invitedUser.firstName,
        lastName: this.invitedUser.lastName,
        roles: [this.invitedUser.userType],
        verificationType: this.invitedUser.verificationType,
        noVerificationNotice: !this.invitedUser.verificationNotice
      }).then((result) => {
        if (this.$props.onUserCreated) {
          this.$props.onUserCreated()
        }
        this.userInviteLoaded = false
        this.selectedUserTab = this.invitedUser.verificationNotice ? 'invited-users' : 'users'
        this.searchUsers(this.selectedUserTab)
        this.addAlert(`Successfully ${this.invitedUser.noVerificationNotice ? 'created user' : 'sent invitation for'} ${this.invitedUser.firstName} ${this.invitedUser.lastName}`, 'success')
      }).catch((err) => {
        this.addAlert(`Error in creating user: ${JSON.stringify(err)}`, 'error', 'isModal')
      }).finally(() => {
        this.userModalApiInProgress = false
      })
    },
    resendUserInvitation (user) {
      if (confirm(`Confirm re-sending invitation for ${user.stUser.firstName || ''} ${user.stUser.lastName}`)) {
        const { transportationOwnerId } = this.$props
        resendTransportationOwnerUserInvite({ transportationOwnerUserId: user.id, transportationOwnerId })
          .then((result) => {
            this.searchUsers('invited-users')
            this.addAlert(`Successfully re-sent invitation for ${user.stUser.firstName || ''} ${user.stUser.lastName}`, 'success')
          }).catch((err) => {
            this.addAlert(`Error in re-sending invite for user: ${JSON.stringify(err)}`, 'error', 'isModal')
          })
      }
    },
    async cancelInvitedUser (user) {
      if (confirm(`Confirm cancelling invitation for ${user.stUser.firstName || ''} ${user.stUser.lastName}`)) {
        await deleteTransportationOwnerUserInvite({ transportationOwnerUserId: user.id })
        this.searchUsers('invited-users')
        this.addAlert(`Successfully deleted invitation for ${user.stUser.firstName || ''} ${user.stUser.lastName}`, 'success')
      }
    },
    onTabChanged (val) {
      console.log('tab changed', val)
      if (this.pagination) {
        this.searchUsers(val)
      }
    },
    onPagination () {
      this.searchUsers(this.selectedUserTab)
    },
    onFilterByRole (val) {
      this.selectedRole = val
      this.searchUsers(this.selectedUserTab)
    },
    deleteUser (user) {
      if (user.isOwner || !user.isEditable) {
        return alert('Account owner cannot be deleted, please archive the account')
      }
      if (this.hasUserType('admin') || this.hasTransportationRole('ADMIN')) {
        if (confirm(`Confirm permanently deleting "${user.stUser.firstName || ''} ${user.stUser.lastName}" from this account?`)) {
          user.isArchiveInProgress = true
          deleteTransportationOwnerUser({
            id: user.id
          }).then(() => {
            this.searchUsers(this.selectedUserTab)
            this.addAlert(`Successfully removed user ${user.stUser.firstName || ''} ${user.stUser.lastName} from account`, 'success')
          }).catch((err) => {
            this.addAlert(`Error in removing user from account: ${err && err.error.message ? err.error.message : JSON.stringify(err)}`, 'error')
          }).finally(() => {
            user.isArchiveInProgress = false
          })
        }
      }
    },
    addArchivedWhere (filter) {
      filter.where.dateArchived = { neq: null }
    },
    getTotal (category) {
      if (category === 'invited-users') {
        return this.totalInvitedUsers
      } else {
        return this.totalUsers
      }
    },
    getUserTypeLabel (roles, returnValue) {
      if (roles.indexOf('OWNER') > -1) {
        return returnValue ? 'OWNER' : 'Owner'
      }
      if (roles.indexOf('ADMIN') > -1) {
        return returnValue ? 'ADMIN' : 'Admin'
      }
      if (roles.indexOf('DRIVER') > -1) {
        return returnValue ? 'DRIVER' : 'Driver / Assistant'
      }
    },
    isUserEditable (user, ignoreIfEmailSame) {
      let isEditable = false
      if ((!ignoreIfEmailSame && this.$props.signedInUser.email === user.email) || this.$props.signedInUser.email === ROOT_EMAIL) {
        isEditable = true
      } else if (user.roles.indexOf('OWNER') > -1 || user.roles.indexOf('ADMIN') > -1) {
        isEditable = this.hasUserType('admin') || this.hasTransportationRole('OWNER')
      } else {
        isEditable = this.hasTransportationRole('ADMIN')
      }
      return isEditable
    },
    /**
     *
     * @param {string} category - can be 'invited' or 'archived'
     * @returns {Promise<void>}
     */
    async searchUsers (category = undefined) {
      let promises = []
      this.apiInProgress = true
      let retrievedUsers
      if (category === 'invited-users') {
        this.getTotalUsers().then((total) => {
          this.totalUsers = total.count
        })
        promises.push(this.getInvitedUsers())
        promises.push(this.getTotalInvitedUsers())
        const [ users, total ] = await Promise.all(promises)
        this.totalInvitedUsers = total.count
        retrievedUsers = users
      } else {
        if (this.hasUserType('admin') || this.hasTransportationRole('ADMIN')) {
          this.getTotalInvitedUsers().then((total) => {
            this.totalInvitedUsers = total.count
          })
        }
        promises.push(this.getUsers())
        promises.push(this.getTotalUsers())
        const [ users, total ] = await Promise.all(promises)
        this.totalUsers = total.count
        retrievedUsers = users
      }
      this.users = retrievedUsers.map(o => {
        o.email = o.stUser.email
        o.isExpired = new Date(o.verificationTokenExpiry).getTime() <= Date.now()
        o.verificationTokenExpiryLabel = this.selectedUserTab === 'invited-users' ? `${formatTime(o.verificationTokenExpiry)}, ${formatDate(o.verificationTokenExpiry)}` : null
        o.dateCreatedLabel = `${formatTime(o.dateCreated)}, ${formatDate(o.dateCreated)}`
        o.dateUpdatedLabel = o.dateUpdated ? `${formatTime(o.dateUpdated)}, ${formatDate(o.dateUpdated)}` : ''
        o.userTypeLabel = this.getUserTypeLabel(o.roles)
        o.invitedUserTypesLabel = o.metadata?.invitedRoles
        o.invitedUserTypesLabel = o.invitedUserTypesLabel && o.invitedUserTypesLabel.length ? o.invitedUserTypesLabel.map(o => _upperFirst(o)).join(', ') : ''
        o.phoneNumber = o.transportationUserIdentifiers && o.transportationUserIdentifiers.length ? o.transportationUserIdentifiers[0].phoneNumber : o.stUser.phone
        o.mobileMoneyAccountType = o.transportationUserIdentifiers && o.transportationUserIdentifiers.length ? o.transportationUserIdentifiers[0].transportationUserMomoAccounts : null
        o.mobileMoneyAccountType = o.mobileMoneyAccountType && o.mobileMoneyAccountType.length ? o.mobileMoneyAccountType[0].moneyAccountTypeId : null
        o.isEditable = this.isUserEditable(o)
        o.isOwner = o.roles.indexOf('OWNER') > -1

        return o
      })
      this.apiInProgress = false
    },
    getGenericFilter ({ verified = true } = {}) {
      const { sortBy, descending, page, rowsPerPage } = this.pagination
      const offset = page === 1 ? 0 : (page * rowsPerPage) - rowsPerPage
      let filter = {
        limit: rowsPerPage,
        offset,
        where: { verified },
        include: ['stUser', { transportationUserIdentifiers: 'transportationUserMomoAccounts' }]
      }
      if (sortBy) {
        filter.order = `${sortBy} ${descending ? 'DESC' : 'ASC'}`
      }
      if (this.$props.transportationOwnerId) {
        filter.where.transportationOwnerId = this.$props.transportationOwnerId
      } else {
        filter.include.push('transportationOwner')
      }

      if (this.selectedRole) {
        if (this.selectedRole === 'DRIVER') {
          filter.where.roles = { ilike: this.selectedRole }
        } else if (this.selectedRole === 'ADMIN') {
          filter.where.roles = { and: [{ ilike: `%${this.selectedRole}%` }, { nilike: `%OWNER%` }] }
        } else if (this.selectedRole === 'OWNER') {
          filter.where.roles = { ilike: `%${this.selectedRole}%` }
        }
      }
      if (this.searchKeywords) {
        filter.join = [{
          relation: 'stUser',
          scope: { where: { or: generateKeywordsQueryForOr(this.searchKeywords, ['firstName', 'lastName', 'email', 'phone']) } }
        },
        {
          relation: 'transportationUserIdentifiers',
          scope: { where: { or: generateKeywordsQueryForOr(this.searchKeywords, ['phoneNumber']), verified } }
        }]
      }
      return filter
    },
    async getUsers () {
      let filter = this.getGenericFilter()
      return findTransportationOwnerUsers(filter)
    },
    async getTotalUsers () {
      let filter = this.getGenericFilter()
      return findTotalTransportationOwnerUsers(filter)
    },
    async getInvitedUsers () {
      let filter = this.getGenericFilter({ verified: false })
      return findTransportationOwnerUsers(filter)
    },
    async getTotalInvitedUsers () {
      let filter = this.getGenericFilter({ verified: false })
      return findTotalTransportationOwnerUsers(filter)
    }
  },
  beforeDestroy () {
    if (this.selectedSignedInUserWatchHandle) {
      this.selectedSignedInUserWatchHandle()
    }
  }
}
