import moment from 'moment-timezone'

export default {
  name: 'time-field',
  props: {
    otherProps: Object,
    value: Date
  },
  data () {
    return {
      timeValue: null,
      timeLabelRules: [(v) => {
        if (v && v.trim()) {
          if (!/^[0-9]{1,2}:[0-9]{2}[aApP]{1}[mM]{1}$/.test(v)) {
            return 'Format (eg.) 12:00am expected'
          }
          const segments = v.split(':')
          if (segments.length > 1 && (parseInt(segments[1], 10) < 0 || parseInt(segments[1], 10) > 59)) {
            return 'Minutes only between 0 and 59'
          }
          if (Number(segments[0]) < 1 || Number(segments[0]) > 12) {
            return '12hr clock expected (eg.) 12:00am instead of 00:00'
          }
        }
        return true
      }]
    }
  },
  mounted () {
    if (this.$props.value) {
      this.processValue(this.$props.value)
    }
  },
  watch: {
    timeValue: function (newVal) {
      newVal = (newVal || '').trim()
      let inputResult
      if (newVal && this.timeLabelRules[0](newVal) === true) {
        const timeLabel = newVal.split(':')
        if (timeLabel[0].length === 1) {
          inputResult = moment.utc(newVal.toLowerCase(), 'h:mma').toDate()
        } else {
          inputResult = moment.utc(newVal.toLowerCase(), 'hh:mma').toDate()
        }
      }
      this.$emit('input', inputResult)
    },
    value (val) {
      this.processValue(val)
    }
  },
  methods: {
    processValue (val) {
      if (val) {
        if (this.timeValue) {
          clearTimeout(this.timeoutHandle)
          this.timeoutHandle = setTimeout(() => {
            this.timeValue = moment.utc(val).format('hh:mma')
          }, 1000)
        } else {
          this.timeValue = moment.utc(val).format('hh:mma')
        }
      }
    }
  }
}
