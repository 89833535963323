<template>
  <div class="staff-management">
    <v-layout row wrap>
      <v-flex md8 sm6 pa-2>
        <form @submit.prevent="searchAgents">
          <v-text-field
              solo
              v-model="searchKeywords"
              append-icon="fal fa-search"
              :rules="[v => !v || v.length >= 2 || 'Min 2 characters']"
              :disabled="apiInProgress"
              type="search"
              placeholder="Search by name, email address, phone number"
              @click:append="searchAgentsClicked"
          />
        </form>
      </v-flex>
      <v-flex md2 sm3 pa-2>
        <v-select
            clearable
            :items="countries"
            label="Country"
            v-model="selectedCountryId"
        />
      </v-flex>
      <v-flex md2 sm3 pa-2>
        <v-btn block color="primary" v-if="hasUserType('admin')" :disabled="apiInProgress" @click="createAgent()"><v-icon>fal fa-plus</v-icon> &nbsp; Create Agent</v-btn>
      </v-flex>
    </v-layout>
    <v-tabs icons-and-text @change="onTabChanged">
      <v-tabs-slider color="primary" />
      <v-tab ripple href="#agents">
        Agents {{selectedAgentTab !== 'agents' ? `(${totalAgents})` : ''}}
        <v-icon>fal fa-user</v-icon>
      </v-tab>
      <v-tab ripple href="#invited-agents" v-if="hasUserType('admin')">
        Invited Agents {{selectedAgentTab !== 'invited-agents' ? `(${totalInvitedAgents})` : ''}}
        <v-icon>fal fa-user-plus</v-icon>
      </v-tab>
      <v-tab ripple href="#archived-agents" v-if="hasUserType('admin')">
        Archived Agents {{selectedAgentTab !== 'archived-agents' ? `(${totalArchivedAgents})` : ''}}
        <v-icon>fal fa-user-times</v-icon>
      </v-tab>
      <v-tabs-items v-model="selectedAgentTab">
        <v-tab-item value="agents">
          <v-data-table
              v-if="selectedAgentTab === 'agents'"
              :headers="headers"
              :items="agents"
              :pagination.sync="pagination"
              :total-items="getTotal(selectedAgentTab)"
              :loading="apiInProgress"
              :rows-per-page-items="[10, 25, 50]"
              @update:pagination="onPagination"
              class="elevation-1"
              must-sort
          >
            <template v-slot:items="props">
              <tr :class="props.item.isEditable ? 'tr--pointer' : undefined" @click="loadAgent(props.item)">
                <td class="layout justify-start">
                  <v-tooltip v-if="signedInUser && props.item.email === signedInUser.email" top>
                    <template v-slot:activator="{ on }">
                      <v-btn style="margin: 0" flat small icon><v-icon small class="info--text" v-on="on">far fa-hand-point-right</v-icon></v-btn>
                    </template>
                    <span>Current user signed in</span>
                  </v-tooltip>
                  <v-text-field
                    class="text-field--flat"
                    v-model="props.item.lastName"
                    hide-details
                  />
                </td>
                <td>
                  <v-text-field
                    class="text-field--flat"
                    v-model="props.item.firstName"
                    hide-details
                  />
                </td>
                <td>{{ props.item.phone }}</td>
                <td>{{ props.item.email }}</td>
                <td>{{ props.item.balanceLabel }}</td>
                <td>{{ props.item.dateCreatedLabel }}</td>
                <td>{{ props.item.dateUpdatedLabel }}</td>
                <td class="justify-center layout px-0">
                  <v-btn flat icon :disabled="!props.item.isEditable || signedInUser.email === props.item.email" :loading="props.item.isArchiveInProgress"  @click.stop="archiveAgent(props.item)">
                  <v-icon small>
                    fas fa-archive
                  </v-icon>
                </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item value="invited-agents" v-if="hasUserType('admin')">
          <v-data-table
              v-if="selectedAgentTab === 'invited-agents'"
              :headers="invitationHeaders"
              :items="agents"
              :pagination.sync="pagination"
              :total-items="getTotal(selectedAgentTab)"
              :loading="apiInProgress"
              :rows-per-page-items="[10, 25, 50]"
              @update:pagination="onPagination"
              class="elevation-1"
              must-sort
          >
            <template v-slot:items="props">
              <tr :class="(props.item.isExpired ? 'invited-user--expired' : '') + (props.item.isEditable ? ' tr--pointer' : '')" @click="loadInvitedAgent(props.item)">
                <td :title="props.item.lastName">
                  <v-text-field
                    class="text-field--flat"
                    v-model="props.item.lastName"
                    hide-details
                  />
                </td>
                <td :title="props.item.firstName">
                  <v-text-field
                    class="text-field--flat"
                    v-model="props.item.firstName"
                    hide-details
                  />
                </td>
                <td>{{ props.item.phone }}</td>
                <td :title="props.item.email">
                  <v-text-field
                    class="text-field--flat"
                    v-model="props.item.email"
                    hide-details
                  />
                </td>
                <td>{{ props.item.dateCreatedLabel }}</td>
                <td>
                  <div>{{ props.item.verificationTokenExpiryLabel }}</div>
                  <div v-if="props.item.isExpired"><small class="error--text">(Expired)</small></div>
                </td>
                <td class="justify-center layout px-0">
                  <v-btn flat icon @click.stop="resendAgentInvitation(props.item)" :disabled="!props.item.isEditable" title="Re-send invitation">
                    <v-icon small>
                      fas fa-envelope
                    </v-icon>
                  </v-btn> &nbsp; <v-btn flat icon @click.stop="cancelInvitedAgent(props.item)" :disabled="!props.item.isEditable" title="Cancel invitation">
                  <v-icon small>
                    fas fa-trash-alt
                  </v-icon>
                </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item value="archived-agents" v-if="hasUserType('admin')">
          <v-data-table
              v-if="selectedAgentTab === 'archived-agents'"
              :headers="archivedHeaders"
              :items="agents"
              :pagination.sync="pagination"
              :total-items="getTotal(selectedAgentTab)"
              :loading="apiInProgress"
              :rows-per-page-items="[10, 25, 50]"
              @update:pagination="onPagination"
              class="elevation-1"
              must-sort
          >
            <template v-slot:items="props">
              <tr :class="props.item.isEditable ? 'tr--pointer' : undefined" @click="loadArchivedAgent(props.item)">
                <td :title="props.item.lastName">
                  <v-text-field
                    class="text-field--flat"
                    v-model="props.item.lastName"
                    hide-details
                  />
                </td>
                <td :title="props.item.firstName">
                  <v-text-field
                    class="text-field--flat"
                    v-model="props.item.firstName"
                    hide-details
                  />
                </td>
                <td>{{ props.item.phone }}</td>
                <td :title="props.item.email">
                  <v-text-field
                    class="text-field--flat"
                    v-model="props.item.email"
                    hide-details
                  />
                </td>
                <td>{{ props.item.balanceLabel }}</td>
                <td>{{ props.item.dateCreatedLabel }}</td>
                <td>{{ props.item.dateArchivedLabel }}</td>
                <td class="justify-center layout px-0">
                  <v-btn flat icon :loading="props.item.isArchiveInProgress" :disabled="!props.item.isEditable" @click.stop="undoArchiveAgent(props.item)">
                    <v-icon small>
                      fas fa-undo
                    </v-icon>
                  </v-btn>
<!--                   <v-btn flat icon :disabled="!props.item.isEditable" @click.stop="deleteUser(props.item)">-->
<!--                  <v-icon small>-->
<!--                    fas fa-trash-alt-->
<!--                  </v-icon>-->
<!--                </v-btn>-->
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
import src from './src'
export default src
</script>
