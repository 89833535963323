import { formatCurrency } from '@smarttransit/common'

function _formatCurrency (val) {
  if (val && (val + '').indexOf('.') === -1) {
    return formatCurrency(parseInt(val, 10) * 100)
  } else {
    return formatCurrency(val)
  }
}

export default {
  name: 'currency-field',
  props: {
    childProps: Object,
    value: [String, Number],
    delay: Boolean
  },
  data () {
    return {
      processedValue: null
    }
  },
  mounted () {
    if (this.$props.value) {
      this.processedValue = _formatCurrency(this.$props.value)
    }
  },
  watch: {
    processedValue: function (newVal) {
      newVal = (newVal ? newVal + '' : '').trim()
      this.$emit('input', newVal)
    },
    value (val) {
      this.processedValue = val
    }
  },
  methods: {
    emitEvent (event) {
      this.$emit(event, this.processedValue)
    },
    onCurrencyChange (newVal) {
      newVal = (newVal ? newVal + '' : '').trim()

      if (newVal) {
        newVal = _formatCurrency(newVal)
        this.processedValue = newVal
      }
    }
  }
}
