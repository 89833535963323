
export default {
  name: 'home',
  props: {},
  data () {
    return { signedInUser: undefined }
  },
  computed: {
    getSignedInUser () {
      return this.$store.state.credentials.user
    }
  },
  methods: {
    afterLoginForward (signInResult) {
      if (this.$route.query.forward) {
        this.$router.push({ path: this.$route.query.forward })
      }
    }
  }
}
