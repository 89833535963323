import { render, staticRenderFns } from "./template.vue?vue&type=template&id=5ec54806&scoped=true&"
import script from "./template.vue?vue&type=script&lang=js&"
export * from "./template.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ec54806",
  null
  
)

export default component.exports