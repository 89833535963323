const _ = require('lodash')
const moment = require('moment')
const numeral = require('numeral')

const DATE_FORMAT = 'MMM D Y'
const TIME_FORMAT = 'hh:mma'

function formatCurrency (amount, currencyPrefix = '') {
  const parsedAmount = ((amount + '').indexOf('.') === -1) ? _.round((numeral(amount).value() / 100), 2) : numeral(amount).value()
  return (currencyPrefix ? currencyPrefix + ' ' : '') + numeral(parsedAmount).format('0,0.00')
}

function formatDate (date, timezone) {
  const momentDate = moment.isMoment(date) ? date : (timezone ? moment(date).tz(timezone) : moment(date))
  return momentDate.format(DATE_FORMAT)
}

function formatTime (time, timezone) {
  const momentDate = moment.isMoment(time) ? time : (timezone ? moment(time).tz(timezone) : moment(time))
  return momentDate.format(TIME_FORMAT)
}

function sleep (msecs) {
  return new Promise((resolve) => setTimeout(() => resolve(), msecs))
}

function getShortTransactionLogId (transactionLogId) {
  return transactionLogId.substring(transactionLogId.length - 7).toUpperCase()
}

function getPhonePrefixByCountryId (countryId) {
  switch (countryId) {
    case 'GHA':
      return '+233'
  }
}

/**
 * Convenience function to return the valid support contacts based on phone country code
 * @param {string} phoneCountryCode - e.g. '+233'
 * @returns {{type:('email'|'whatsapp'), value: string}[]}
 */
function getSupportContacts (phoneCountryCode) {
  switch (phoneCountryCode) {
    case '+233':
      return [
        { type: 'email', value: 'support@smarttransyt.com' },
        { type: 'whatsapp', value: '053 102 2770 / +233 53 102 2770' },
        { type: 'phone', value: '053 102 2770 / +233 53 102 2770' }
      ]
    case '+1':
    case '+44':
    default:
      return [
        { type: 'email', value: 'support@smarttransyt.com' },
        { type: 'whatsapp', value: '053 102 2770 / +233 53 102 2770' }
      ]
  }

}

module.exports = {
  formatCurrency,
  formatDate,
  formatTime,
  getPhonePrefixByCountryId,
  sleep,
  getShortTransactionLogId,
  getSupportContacts
}
