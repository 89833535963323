export const C_RENEW_TOKEN = 'renewToken'
export const C_SET_TOKEN = 'setToken'
export const C_INVALIDATE_TOKEN = 'invalidateToken'

export const C_SET_SIGNED_IN_USER = 'setSignedInUser'
export const C_SET_SIGNED_USER_PROMISE = 'setSignedInUserPromise'

export const C_ADD_SITE_ALERT = 'addSiteAlert'
export const C_REMOVE_SITE_ALERT = 'removeSiteAlert'
export const C_HIDE_SITE_ALERT = 'hideSiteAlert'
export const C_SHOW_SITE_ALERT = 'showSiteAlert'
export const C_CLEAR_SITE_ALERTS = 'clearSiteAlerts'

export const C_SET_CACHE = 'setBrowserCache'
export const C_SET_USER_CACHE = 'setUserCache'
export const C_SET_BROWSER_SETTING = 'setBrowserSettings'

export const C_SET_BUS_STOP_DATA = 'setBusStopData'

export const C_SET_SELECTED_TRANSPORTATION_OWNER = 'setSelectedTransportationOwner'

export const C_SET_TOOLBAR_HEIGHT = 'setToolbarHeight'

export const C_SET_FOOTER_HEIGHT = 'setFooterHeight'

export default {
  C_RENEW_TOKEN,
  C_SET_TOKEN,
  C_INVALIDATE_TOKEN,
  C_SET_SIGNED_IN_USER,
  C_ADD_SITE_ALERT,
  C_REMOVE_SITE_ALERT,
  C_HIDE_SITE_ALERT,
  C_SHOW_SITE_ALERT,
  C_CLEAR_SITE_ALERTS,
  C_SET_CACHE,
  C_SET_USER_CACHE,
  C_SET_BROWSER_SETTING,
  C_SET_BUS_STOP_DATA,
  C_SET_SELECTED_TRANSPORTATION_OWNER,
  C_SET_TOOLBAR_HEIGHT,
  C_SET_FOOTER_HEIGHT
}
