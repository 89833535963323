/**
 * @param {number} amountAsCents
 * @param {number} conversionRatePercent
 * @returns {number}
 */
function convertToPassengerCredits ({ amountAsCents, conversionRatePercent }) {
  if (!amountAsCents && amountAsCents !== 0) {
    throw new Error('"amount" required in converting to passenger credits')
  }

  if (!conversionRatePercent) {
    throw new Error('"conversionRatePercent" required in converting to passenger credits')
  }

  return Math.round(amountAsCents * (conversionRatePercent / 100))
}

/**
 * @param {number} amountAsCents
 * @param {number} conversionRatePercent
 * @returns {number}
 */
function convertToAgentCredits ({ amountAsCents, conversionRatePercent }) {
  if (!amountAsCents && amountAsCents !== 0) {
    throw new Error('"amount" required in converting to passenger credits')
  }

  if (!conversionRatePercent) {
    throw new Error('"conversionRatePercent" required in converting to passenger credits')
  }

  const conversionRateAsDivisor = parseFloat((conversionRatePercent / 100).toFixed(2))
  return Math.floor(amountAsCents / conversionRateAsDivisor)
}

module.exports = {
  convertToPassengerCredits,
  convertToAgentCredits
}
