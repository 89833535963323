<template>
  <div>
    <v-card class="pa-2">
      <div style="flex: 1" v-if="enableTimezone">
        <timezone-selector :child-props="{ outline: true, label: 'Timezone' }" v-model="currentTimezone"/>
      </div>
      <div v-if="enableTemplates">
        <v-select
            class="mb-4"
            outline
            hide-details
            clearable
            v-model="templateIndex"
            :items="templateOptions"
            label="Use template"
        />
      </div>
      <v-alert v-if="confirmTemplateDialog" value="true" type="info">
        Confirm using this template
        <div>
          <v-btn small color="warning" @click="cancelTemplate()">
            Cancel
          </v-btn>
          <v-btn small @click="confirmTemplate()">
            Use template
          </v-btn>
          <v-btn small @click="confirmCustomFromTemplate()">
            Customize
          </v-btn>
        </div>
      </v-alert>
      <div v-if="!template">
        <div class="layout justify-start mb-1" v-for="(hourOfOperation, index) in currentHoursOfOperation" :key="index">
        <div style="width: 100px;" class="layout align-center d-flex"><strong>{{ index }}</strong></div>
        <v-chip
          class="ml-1 pointer"
          v-for="(hourSet, hourSetIndex) in hourOfOperation"
          :key="hourSetIndex"
          @click="editHourSet(index, hourSetIndex, hourSet)"
        >
          <v-icon small>fas fa-pencil</v-icon> &nbsp;
          {{ hourSet.join(' - ') }}
        </v-chip>
        <v-chip v-if="!hourOfOperation.length" color="warning">Out of operation</v-chip>
        <v-btn class="ml-1" fab small @click="addHourSet(index)">
          <v-icon>add</v-icon>
        </v-btn>
      </div>
      </div>
      <h3 class="text-xs-center" v-if="template">Template</h3>
      <div v-if="template">
        <div class="layout justify-start" v-for="(hourOfOperation, index) in template" :key="index">
        <div style="width: 100px;" class="layout align-center d-flex"><strong>{{ index }}</strong></div>
        <v-chip
            class="ml-1"
            v-for="(hourSet, hourSetIndex) in hourOfOperation"
            :key="hourSetIndex"
        >
          {{ hourSet.join(' - ') }}
        </v-chip>
        <v-chip v-if="!hourOfOperation.length" class="ml-1" color="warning">Out of operation</v-chip>
        <v-btn class="ml-1" disabled fab small>
          <v-icon>add</v-icon>
        </v-btn>
      </div>
      </div>
    </v-card>
    <v-dialog v-model="editHourSetModal" width="500px">
      <v-card>
        <v-card-title class="justify-center dialog-close-container">
          <h2>Manage Time Window</h2>
          <v-btn class="dialog-close" color="primary" flat icon @click="editHourSetModal = false">
            <v-icon>far fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text v-if="currentHourSet" class="px-2 pb-2 pt-0">
          <div class="layout justify-space-around mb-2">
            <div><time-field v-model="currentHourSet.hourSet[0]" :other-props="{ label: 'From', placeholder: 'eg. 5:00am' }" /></div>
            <div><time-field v-model="currentHourSet.hourSet[1]" :other-props="{ label: 'To', placeholder: 'eg. 11:00pm' }" /></div>
          </div>
          <div class="layout row justify-center">
            <v-btn color="error" flat @click="deleteHourSet()">
              <v-icon small>fas fa-trash</v-icon> &nbsp;
              Remove
            </v-btn>
          </div>
        </v-card-text>
        <v-card-actions class="layout justify-space-between">
          <v-btn color="warning" @click="editHourSetModal = false">
            Cancel
          </v-btn>
          <v-btn @click="saveHourSet()">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

<script>
import src from './src'
export default src
</script>
