import { apiRequests, mapApiRequests } from '../utilities/axios-factory'
import {
  D_FIND_ROUTE_FARES,
  D_GET_ROUTE_FARE,
  D_GET_ROUTE_FARE_TOTAL,
  D_CREATE_ROUTE_FARE,
  D_UPDATE_ROUTE_FARE,
  D_DELETE_ROUTE_FARE,
  D_START_AUDIT_ROUTE_FARE_JOB,
  D_STOP_AUDIT_ROUTE_FARE_JOB,
  D_CLEAR_AUDIT_ROUTE_FARE_JOB_ERROR,
  D_GET_AUDIT_ROUTE_FARE_JOB_STATUS,
  D_UPDATE_ROUTE_FARE_PERCENT
} from '../utilities/action-types'

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    [D_FIND_ROUTE_FARES] ({ commit, getters }, filter) {
      return apiRequests.get('/v1/route-fare/', {
        params: { filter },
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    },
    [D_GET_ROUTE_FARE] ({ commit, getters }, { id, filter }) {
      return apiRequests.get('/v1/route-fare/' + id + '/', {
        params: { filter },
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    },
    [D_GET_ROUTE_FARE_TOTAL] ({ commit, getters }, filter) {
      return apiRequests.get('/v1/route-fare/count/', {
        params: { filter },
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    },
    [D_CREATE_ROUTE_FARE] ({ commit, getters }, data) {
      return apiRequests.post('/v1/route-fare/', data, {
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    },
    [D_UPDATE_ROUTE_FARE] ({ commit, getters }, data) {
      return apiRequests.patch('/v1/route-fare/' + data.id + '/', data, {
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    },
    [D_UPDATE_ROUTE_FARE_PERCENT] ({ commit, getters }, { percent, agencyId }) {
      return apiRequests.patch('/v1/route-fare/by-percent/', { percent, agencyId }, {
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    },
    [D_DELETE_ROUTE_FARE] ({ commit, getters }, { id }) {
      return apiRequests.delete('/v1/route-fare/' + id + '/', {
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    },
    [D_GET_AUDIT_ROUTE_FARE_JOB_STATUS] ({ commit, getters }, routerId) {
      return mapApiRequests.get('/v1/geo-location/admin/audit-route-fares-request/', {
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    },
    [D_START_AUDIT_ROUTE_FARE_JOB] ({ commit, getters }, routerId) {
      return mapApiRequests.post('/v1/geo-location/admin/audit-route-fares-request/', { routerId }, {
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    },
    [D_STOP_AUDIT_ROUTE_FARE_JOB] ({ commit, getters }, routerId) {
      return mapApiRequests.delete('/v1/geo-location/admin/audit-route-fares-request/', {
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    },
    [D_CLEAR_AUDIT_ROUTE_FARE_JOB_ERROR] ({ commit, getters }, routerId) {
      return mapApiRequests.delete('/v1/geo-location/admin/audit-route-fares-request/error/', {
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    }
  }
}
