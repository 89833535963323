<template>
  <div>
    <v-autocomplete
      v-model="selectedPassenger"
      :items="passengers"
      :loading="apiInProgress"
      :search-input.sync="searchKeywords"
      item-text="phoneNumber"
      item-value="id"
      return-object
      clearable
      hide-no-data
      hide-selected
      v-bind="childProps"
    >
      <template slot="item" slot-scope="{ item }">
        <div style="width: 100%">
          <div>{{item.phoneNumber}}</div>
          <div v-if="item.passengerProfile.stUser.firstName || item.passengerProfile.stUser.lastName"><em><small>{{item.passengerProfile.stUser.firstName + ' ' + item.passengerProfile.stUser.lastName}}</small></em></div>
          <hr style="width: 100%; border: 0; border-bottom: 1px solid #ccc" />
        </div>
      </template>
      <template slot="selection" slot-scope="{ item }">
        <div>
          <div>{{item.phoneNumber}}</div>
          <div v-if="item.passengerProfile.stUser.firstName || item.passengerProfile.stUser.lastName"><em><small>{{item.passengerProfile.stUser.firstName + ' ' + item.passengerProfile.stUser.lastName}}</small></em></div>
        </div>
      </template>
    </v-autocomplete>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

<script>
import src from './src'
export default src
</script>
