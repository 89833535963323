<template>
  <div class="staff-management">
    <v-layout row wrap>
      <v-flex md8 sm6 pa-2>
        <form @submit.prevent="searchUsers">
          <v-text-field
              solo
              v-model="searchKeywords"
              append-icon="fal fa-search"
              :rules="[v => !v || v.length >= 2 || 'Min 2 characters']"
              :disabled="apiInProgress"
              type="search"
              placeholder="Search by name, email address, phone number"
              @click:append="searchUsers"
          />
        </form>
      </v-flex>
      <v-flex md2 sm3 pa-2>
        <v-select
            clearable
            :items="userTypes"
            label="User Type"
            :value="selectedUserType"
            @change="onFilterByUserType"
        />
      </v-flex>
      <v-flex md2 sm3 pa-2>
        <v-btn block color="primary" v-if="hasUserType('admin')" :disabled="apiInProgress" @click="userInviteLoaded = true"><v-icon>fal fa-plus</v-icon> &nbsp; Create Staff</v-btn>
      </v-flex>
    </v-layout>
    <v-tabs icons-and-text @change="onTabChanged">
      <v-tabs-slider color="primary" />
      <v-tab ripple href="#users">
        Users {{selectedUserTab !== 'users' ? `(${totalUsers})` : ''}}
        <v-icon>fal fa-user</v-icon>
      </v-tab>
      <v-tab ripple href="#invited-users" v-if="hasUserType('admin')">
        Invited Users {{selectedUserTab !== 'invited-users' ? `(${totalInvitedUsers})` : ''}}
        <v-icon>fal fa-user-plus</v-icon>
      </v-tab>
      <v-tab ripple href="#archived-users" v-if="hasUserType('admin')">
        Archived Users {{selectedUserTab !== 'archived-users' ? `(${totalArchivedUsers})` : ''}}
        <v-icon>fal fa-user-times</v-icon>
      </v-tab>
      <v-tabs-items v-model="selectedUserTab">
        <v-tab-item value="users">
          <v-data-table
              v-if="selectedUserTab === 'users'"
              :headers="headers"
              :items="users"
              :pagination.sync="pagination"
              :total-items="getTotal(selectedUserTab)"
              :loading="apiInProgress"
              :rows-per-page-items="[10, 25, 50]"
              @update:pagination="onPagination"
              class="elevation-1"
              must-sort
          >
            <template v-slot:items="props">
              <tr :class="props.item.isEditable ? 'tr--pointer' : undefined" @click="loadUpdateUser(props.item)">
                <td class="layout justify-start">
                  <v-tooltip v-if="signedInUser && props.item.email === signedInUser.email" top>
                    <template v-slot:activator="{ on }">
                      <v-btn style="margin: 0" flat small icon><v-icon small class="info--text" v-on="on">far fa-hand-point-right</v-icon></v-btn>
                    </template>
                    <span>Current user signed in</span>
                  </v-tooltip>
                  <v-text-field
                    class="text-field--flat"
                    v-model="props.item.lastName"
                    hide-details
                  />
                </td>
                <td>
                  <v-text-field
                    class="text-field--flat"
                    v-model="props.item.firstName"
                    hide-details
                  />
                </td>
                <td class="text-xs-right">{{ props.item.phone }}</td>
                <td class="text-xs-right">{{ props.item.email }}</td>
                <td class="text-xs-right">{{ props.item.userTypeLabel }}</td>
                <td class="text-xs-right">{{ props.item.dateCreatedLabel }}</td>
                <td class="text-xs-right">{{ props.item.dateUpdatedLabel }}</td>
                <td class="justify-center layout px-0" :title="props.item.isRoot ? 'Root user cannot be updated or deleted' : ''">
                  <v-btn flat icon :disabled="props.item.isRoot || !props.item.isEditable || signedInUser.email === props.item.email" :loading="props.item.isArchiveInProgress"  @click.stop="archiveUser(props.item)">
                  <v-icon small>
                    fas fa-archive
                  </v-icon>
                </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item value="invited-users" v-if="hasUserType('admin')">
          <v-data-table
              v-if="selectedUserTab === 'invited-users'"
              :headers="invitationHeaders"
              :items="users"
              :pagination.sync="pagination"
              :total-items="getTotal(selectedUserTab)"
              :loading="apiInProgress"
              :rows-per-page-items="[10, 25, 50]"
              @update:pagination="onPagination"
              class="elevation-1"
              must-sort
          >
            <template v-slot:items="props">
              <tr :class="props.item.isExpired ? 'invited-user--expired' : ''">
                <td :title="props.item.lastName">
                  <v-text-field
                    class="text-field--flat"
                    v-model="props.item.lastName"
                    hide-details
                  />
                </td>
                <td :title="props.item.firstName">
                  <v-text-field
                    class="text-field--flat"
                    v-model="props.item.firstName"
                    hide-details
                  />
                </td>
                <td class="text-xs-right">{{ props.item.phone }}</td>
                <td :title="props.item.email">
                  <v-text-field
                    class="text-field--flat"
                    v-model="props.item.email"
                    hide-details
                  />
                </td>
                <td class="text-xs-right">
                  <div v-if="props.item.invitedUserTypesLabel">{{ props.item.invitedUserTypesLabel }}</div>
                  <div><small>(Pending)</small></div>
                </td>
                <td class="text-xs-right">{{ props.item.dateCreatedLabel }}</td>
                <td class="text-xs-right">
                  <div>{{ props.item.verificationTokenExpiryLabel }}</div>
                  <div v-if="props.item.isExpired"><small class="error--text">(Expired)</small></div>
                </td>
                <td class="justify-center layout px-0">
                  <v-btn flat icon @click.stop="resendUserInvitation(props.item)" :disabled="!props.item.isEditable" title="Re-send invitation">
                    <v-icon small>
                      fas fa-envelope
                    </v-icon>
                  </v-btn> &nbsp; <v-btn flat icon @click.stop="cancelInvitedUser(props.item)" :disabled="!props.item.isEditable" title="Cancel invitation">
                  <v-icon small>
                    fas fa-trash-alt
                  </v-icon>
                </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item value="archived-users" v-if="hasUserType('admin')">
          <v-data-table
              v-if="selectedUserTab === 'archived-users'"
              :headers="archivedHeaders"
              :items="users"
              :pagination.sync="pagination"
              :total-items="getTotal(selectedUserTab)"
              :loading="apiInProgress"
              :rows-per-page-items="[10, 25, 50]"
              @update:pagination="onPagination"
              class="elevation-1"
              must-sort
          >
            <template v-slot:items="props">
              <tr>
                <td :title="props.item.lastName">
                  <v-text-field
                    class="text-field--flat"
                    v-model="props.item.lastName"
                    hide-details
                  />
                </td>
                <td :title="props.item.firstName">
                  <v-text-field
                    class="text-field--flat"
                    v-model="props.item.firstName"
                    hide-details
                  />
                </td>
                <td class="text-xs-right">{{ props.item.phone }}</td>
                <td :title="props.item.email">
                  <v-text-field
                    class="text-field--flat"
                    v-model="props.item.email"
                    hide-details
                  />
                </td>
                <td class="text-xs-right">{{ props.item.userTypeLabel }}</td>
                <td class="text-xs-right">{{ props.item.dateCreatedLabel }}</td>
                <td class="text-xs-right">{{ props.item.dateArchivedLabel }}</td>
                <td class="justify-center layout px-0">
                  <v-btn flat icon :loading="props.item.isArchiveInProgress" :disabled="!props.item.isEditable" @click.stop="undoArchiveUser(props.item)">
                    <v-icon small>
                      fas fa-undo
                    </v-icon>
                  </v-btn>
<!--                   <v-btn flat icon :disabled="!props.item.isEditable" @click.stop="deleteUser(props.item)">-->
<!--                  <v-icon small>-->
<!--                    fas fa-trash-alt-->
<!--                  </v-icon>-->
<!--                </v-btn>-->
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
    <v-dialog
        transition="dialog-bottom-transition"
        scrollable
        persistent
        max-width="500"
        v-model="userInviteLoaded">
      <v-card>
        <v-card-title class="headline grey lighten-2 text-xs-center" primary-title>
          Create a user by invitation
        </v-card-title>
        <v-form>
          <v-card-text v-if="invitedUser">
            <v-layout row wrap>
              <v-flex :sm6="currentUserHasRole('admin')" :sm12="!currentUserHasRole('admin')" pr-1>
                <v-select
                    pb-2
                    :items="[{text: 'Email', value: 'email'},{text: 'Phone', value: 'phone'}]"
                    label="Invite By"
                    v-model="invitedUser.verificationType"
                />
              </v-flex>
              <v-flex sm6 v-if="currentUserHasRole('admin')" pl-1>
                <v-select
                    pb-2
                    v-if="currentUserHasRole('admin')"
                    :items="userTypesByAuthorization"
                    label="User Type"
                    v-model="invitedUser.userType"
                />
              </v-flex>
            </v-layout>
            <v-text-field
                pb-2
                v-if="invitedUser.verificationType === 'email'"
                type="email" v-model="invitedUser.email"
                :rules="[(v) => (!v || /^[^@]+@[^@]+\.[0-9a-zA-Z]{2,}$/.test(v) || 'Valid email format required')]"
                label="Email address to invite">
            </v-text-field>
            <phone-number-field
              pb-2
              v-if="invitedUser.verificationType === 'phone'"
              v-model="invitedUser.phone"
              :child-props="{ label: 'Mobile number to invite' }" />
            <v-layout row wrap>
              <v-flex sm6 pr-1>
                <v-text-field v-model="invitedUser.firstName" pb-2 label="First name" />
              </v-flex>
              <v-flex sm6 pl-1>
                <v-text-field v-model="invitedUser.lastName" pb-2 label="Last name" />
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-form>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="warn" flat :disabled="userModalApiInProgress" @click="userInviteLoaded = false">
            Cancel
          </v-btn> &nbsp;
          <v-btn color="primary" :disabled="userModalApiInProgress" :loading="userModalApiInProgress" @click="inviteUser()">
            Send Invite
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        transition="dialog-bottom-transition"
        scrollable
        persistent
        max-width="500"
        v-model="updateUserLoaded">
      <v-card>
        <v-card-title class="headline grey lighten-2 text-xs-center" primary-title>
          Update a user
        </v-card-title>
        <v-form>
          <v-card-text v-if="updatingUser">
            <v-select
              pb-2
              :disabled="!isUserEditable(updatingUser, 'ignoreIfEmailSame')"
              :items="isUserEditable(updatingUser, 'ignoreIfEmailSame') ? userTypesByAuthorization : userTypes"
              v-model="updatingUser.userType"
              label="User Type"
            />
            <v-text-field
                pb-2
                :readonly="!isUserEditable(updatingUser)"
                type="email" v-model="updatingUser.email"
                :rules="[(v) => (!v || /^[^@]+@[^@]+\.[0-9a-zA-Z]{2,}$/.test(v) || 'Valid email format required')]"
                label="Email address">
            </v-text-field>
            <v-text-field
              pb-2
              readonly
              v-if="!isUserEditable(updatingUser)"
              type="text" v-model="updatingUser.phone"
              label="Mobile number">
            </v-text-field>
            <phone-number-field
                pb-2
                v-if="isUserEditable(updatingUser)"
                v-model="updatingUser.phone"
                :child-props="{ label: 'Mobile number' }"
            />
            <v-layout row wrap>
              <v-flex sm6 pr-1>
                <v-text-field v-model="updatingUser.firstName" pb-2 label="First name" />
              </v-flex>
              <v-flex sm6 pl-1>
                <v-text-field v-model="updatingUser.lastName" pb-2 label="Last name" />
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-form>
        <v-divider />
        <v-card-actions v-if="updatingUser">
          <v-spacer />
          <v-btn color="warn" flat :disabled="updatingUser.isLoading" @click="cancelEdit()">
            Cancel
          </v-btn> &nbsp;
          <v-btn color="primary" :disabled="updatingUser.isLoading || !isUpdatingUserEdited()" :loading="updatingUser.isLoading" @click="updateUser()">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        transition="dialog-bottom-transition"
        scrollable
        v-model="userEditorLoaded">
      <v-card>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modalSiteAlert" max-width="500px">
      <v-card>
        <v-card-title>
          {{modalSiteAlertData ? modalSiteAlertData.type : ''}}
        </v-card-title>
        <v-card-text style="overflow-y: scroll; max-height: 300px;" class="px-2 pb-2">
          {{modalSiteAlertData ? modalSiteAlertData.message : ''}}
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" flat @click="modalSiteAlert = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import src from './src'
export default src
</script>
