import { apiRequests } from '../utilities/axios-factory'
import store from '../store'
import { generateKeywordsQueryForOr } from '@smarttransit/common-client'

function getArchivedOrUnarchivedPassenger ({ id, filter }) {
  filter = {
    include: [
      {
        relation: 'passengerIdentifiers',
        scope: {
          limit: 1,
          order: ['dateUpdated DESC NULLS LAST', 'dateCreated DESC']
        }
      },
      {
        relation: 'passengerTrips',
        scope: {
          limit: 1,
          order: 'dateUpdated DESC'
        }
      },
      {
        relation: 'passengerAlternateIdentifiers',
        scope: {
          order: 'dateUpdated DESC'
        }
      },
      'stUser'
    ],
    ...filter
  }

  return apiRequests.get('/v1/raw-passenger-profile/' + id + '/', {
    params: { filter },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function getPassengerIdentifier ({ id, filter }) {
  return apiRequests.get('/v1/passenger-identifier/' + id + '/', {
    params: { filter },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function findPassengerIdentifiers (filter) {
  return apiRequests.get('/v1/passenger-identifier/join/', {
    params: { filter },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function findTotalPassengers ({ where, join }) {
  return apiRequests.get('/v1/passenger-identifier/count/join/', {
    params: { where, join },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function findPassengerIdentifiersByPassengerProfileId (passengerProfileId) {
  return apiRequests.get('/v1/passenger-identifier/join/', {
    params: { filter: { where: { passengerProfileId } } },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

/**
 *
 * @param {string} [keywords]
 * @param {string} [identifierType]
 * @param {string} [countryId]
 * @param {number} [limit]
 * @param {number} [offset]
 * @param {string|string[]} [order=['dateCreated DESC', 'dateUpdated DESC']]
 * @returns {Promise<*>}
 */
function findVerifiedPassengers ({
  keywords,
  identifierType,
  countryId,
  limit,
  offset,
  order = ['dateUpdated DESC NULLS LAST', 'dateCreated DESC']
}) {
  const filter = {
    join: {
      relation: 'passengerIdentifiers',
      scope: { where: { and: [{ verified: true }, { phoneNumber: { nilike: '%deleted%' } }] } }
    },
    include: [
      {
        relation: 'passengerIdentifiers',
        scope: {
          limit: 1,
          order: ['dateUpdated DESC NULLS LAST', 'dateCreated DESC']
        }
      },
      {
        relation: 'passengerTrips',
        scope: {
          limit: 1,
          order: 'dateUpdated DESC'
        }
      },
      {
        relation: 'passengerAlternateIdentifiers',
        scope: { order: ['dateUpdated DESC'] }
      }
    ],
    limit,
    offset,
    order
  }

  if (countryId) {
    filter.where = { and: [{ or: [{ currentCountryIdDetected: countryId }, { currentCountryIdSet: countryId }] }] }
  }

  if (identifierType) {
    filter.join.scope.where.and.push({ identifierType })
    filter.include[0].scope.where = { ...filter.join.scope.where }
  }

  if (keywords) {
    if (/^\+{0,1}[0-9\s]+$/u.test(keywords)) {
      filter.join.scope.where.and.push({ or: generateKeywordsQueryForOr(keywords, ['phoneNumber']) })
      filter.include[0].scope.where = { ...filter.join.scope.where }
    } else {
      filter.include[0].scope.where = { ...filter.join.scope.where }

      filter.join = [
        { ...filter.join },
        {
          relation: 'passengerAlternateIdentifiers',
          scope: {
            where: {
              and: [
                { dateArchived: null },
                { or: generateKeywordsQueryForOr(keywords, ['identifierKey']) }
              ]
            }
          }
        }
      ]
    }
  }

  return apiRequests.get('/v1/passenger-profile/join/', {
    params: { filter },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

/**
 *
 * @param {string} [keywords]
 * @param {string} [identifierType]
 * @param {string} [countryId]
 * @returns {Promise<*>}
 */
function findTotalVerifiedPassengers ({ keywords, identifierType, countryId }) {
  let where

  let join = {
    relation: 'passengerIdentifiers',
    scope: { where: { and: [{ verified: true }, { phoneNumber: { nilike: '%deleted%' } }] } }
  }

  if (countryId) {
    where = { and: [{ or: [{ currentCountryIdDetected: countryId }, { currentCountryIdSet: countryId }] }] }
  }

  if (identifierType) {
    join.scope.where.and.push({ identifierType })
  }

  if (keywords) {
    if (/^\+{0,1}[0-9\s]+$/u.test(keywords)) {
      join.scope.where.and.push({ or: generateKeywordsQueryForOr(keywords, ['phoneNumber']) })
    } else {
      join = [
        { ...join },
        {
          relation: 'passengerAlternateIdentifiers',
          scope: {
            where: {
              and: [
                { dateArchived: null },
                { or: generateKeywordsQueryForOr(keywords, ['identifierKey']) }
              ]
            }
          }
        }
      ]
    }
  }

  return apiRequests.get('/v1/passenger-profile/count/join/', {
    params: { where, join },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

/**
 *
 * @param {string} [keywords]
 * @param {string} [identifierType]
 * @param {string} [countryId]
 * @param {number} [limit]
 * @param {number} [offset]
 * @param {string|string[]} [order=['dateCreated DESC', 'dateUpdated DESC']]
 * @returns {Promise<*>}
 */
function findPendingPassengers ({
  keywords,
  identifierType,
  countryId,
  limit,
  offset,
  order = ['dateUpdated DESC NULLS LAST', 'dateCreated DESC']
}) {
  const filter = {
    join: {
      relation: 'passengerIdentifiers',
      scope: {
        where: {
          and: [
            { phoneNumber: { nilike: '%deleted%' } },
            { activationCodeExpiry: { neq: null } },
            {
              or: [
                { activationCode: { neq: null } },
                { activationCode: { neq: '' } }
              ]
            }
          ]
        }
      }
    },
    include: [{
      relation: 'passengerIdentifiers',
      scope: { limit: 1, order: ['dateUpdated DESC', 'dateCreated DESC'] }
    }, {
      relation: 'passengerAlternateIdentifiers',
      scope: { order: ['dateUpdated DESC'] }
    }],
    limit,
    offset,
    order
  }

  if (countryId) {
    filter.where = { and: [{ or: [{ currentCountryIdDetected: countryId }, { currentCountryIdSet: countryId }] }] }
  }

  if (identifierType) {
    filter.join.scope.where.and.push({ identifierType })
    filter.include[0].scope.where = { ...filter.join.scope.where }
  }

  if (keywords) {
    if (/^\+{0,1}[0-9\s]+$/u.test(keywords)) {
      filter.join.scope.where.and.push({ or: generateKeywordsQueryForOr(keywords, ['phoneNumber']) })
      filter.include[0].scope.where = { ...filter.join.scope.where }
    } else {
      filter.include[0].scope.where = { ...filter.join.scope.where }

      filter.join = [
        { ...filter.join },
        {
          relation: 'passengerAlternateIdentifiers',
          scope: {
            where: {
              and: [
                { dateArchived: null },
                { or: generateKeywordsQueryForOr(keywords, ['identifierKey']) }
              ]
            }
          }
        }
      ]
    }
  }

  return apiRequests.get('/v1/passenger-profile/join/', {
    params: { filter },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

/**
 *
 * @param {string} [keywords]
 * @param {string} [identifierType]
 * @param {string} [countryId]
 * @returns {Promise<*>}
 */
function findTotalPendingPassengers ({ keywords, identifierType, countryId }) {
  let where

  let join = {
    relation: 'passengerIdentifiers',
    scope: {
      where: {
        and: [
          { phoneNumber: { nilike: '%deleted%' } },
          { activationCodeExpiry: { neq: null } },
          {
            or: [
              { activationCode: { neq: null } },
              { activationCode: { neq: '' } }
            ]
          }
        ]
      }
    }
  }

  if (countryId) {
    where = { and: [{ or: [{ currentCountryIdDetected: countryId }, { currentCountryIdSet: countryId }] }] }
  }

  if (identifierType) {
    join.scope.where.and.push({ identifierType })
  }

  if (keywords) {
    if (/^\+{0,1}[0-9\s]+$/u.test(keywords)) {
      join.scope.where.and.push({ or: generateKeywordsQueryForOr(keywords, ['phoneNumber']) })
    } else {
      join = [
        { ...join },
        {
          relation: 'passengerAlternateIdentifiers',
          scope: {
            where: {
              and: [
                { dateArchived: null },
                { or: generateKeywordsQueryForOr(keywords, ['identifierKey']) }
              ]
            }
          }
        }
      ]
    }
  }

  return apiRequests.get('/v1/passenger-profile/count/join/', {
    params: { where, join },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

/**
 *
 * @param {string} [keywords]
 * @param {string} [identifierType]
 * @param {string} [countryId]
 * @param {number} [limit]
 * @param {number} [offset]
 * @param {string|string[]} [order=['dateCreated DESC', 'dateUpdated DESC']]
 * @returns {Promise<*>}
 */
function findPendingTransferPassengers ({
  keywords,
  identifierType,
  countryId,
  limit,
  offset,
  order = ['dateUpdated DESC NULLS LAST', 'dateCreated DESC']
}) {
  const filter = {
    join: {
      relation: 'passengerIdentifiers',
      scope: {
        where: {
          and: [
            { phoneNumber: { nilike: '%deleted%' } },
            { transferCodeExpiry: { neq: null } },
            {
              or: [
                { transferCode: { neq: null } },
                { transferCode: { neq: '' } }
              ]
            }
          ]
        }
      }
    },
    include: [
      { relation: 'passengerIdentifiers', scope: { limit: 1, order: ['dateUpdated DESC', 'dateCreated DESC'] } },
      { relation: 'passengerAlternateIdentifiers', scope: { order: ['dateUpdated DESC'] } }
    ],
    limit,
    offset,
    order
  }

  if (countryId) {
    filter.where = { and: [{ or: [{ currentCountryIdDetected: countryId }, { currentCountryIdSet: countryId }] }] }
  }

  if (identifierType) {
    filter.join.scope.where.and.push({ identifierType })
    filter.include[0].scope.where = { ...filter.join.scope.where }
  }

  if (keywords) {
    if (/^\+{0,1}[0-9\s]+$/u.test(keywords)) {
      filter.join.scope.where.and.push({ or: generateKeywordsQueryForOr(keywords, ['phoneNumber']) })
      filter.include[0].scope.where = { ...filter.join.scope.where }
    } else {
      filter.include[0].scope.where = { ...filter.join.scope.where }

      filter.join = [
        { ...filter.join },
        {
          relation: 'passengerAlternateIdentifiers',
          scope: {
            where: {
              and: [
                { dateArchived: null },
                { or: generateKeywordsQueryForOr(keywords, ['identifierKey']) }
              ]
            }
          }
        }
      ]
    }
  }

  return apiRequests.get('/v1/passenger-profile/join/', {
    params: { filter },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

/**
 *
 * @param {string} [keywords]
 * @param {string} [identifierType]
 * @param {string} [countryId]
 * @returns {Promise<*>}
 */
function findTotalPendingTransferPassengers ({ keywords, identifierType, countryId }) {
  let where

  let join = {
    relation: 'passengerIdentifiers',
    scope: {
      where: {
        and: [
          { phoneNumber: { nilike: '%deleted%' } },
          { transferCodeExpiry: { neq: null } },
          {
            or: [
              { transferCode: { neq: null } },
              { transferCode: { neq: '' } }
            ]
          }
        ]
      }
    }
  }

  if (countryId) {
    where = { and: [{ or: [{ currentCountryIdDetected: countryId }, { currentCountryIdSet: countryId }] }] }
  }

  if (identifierType) {
    join.scope.where.and.push({ identifierType })
  }

  if (keywords) {
    if (/^\+{0,1}[0-9\s]+$/u.test(keywords)) {
      join.scope.where.and.push({ or: generateKeywordsQueryForOr(keywords, ['phoneNumber']) })
    } else {
      join = [
        { ...join },
        {
          relation: 'passengerAlternateIdentifiers',
          scope: {
            where: {
              and: [
                { dateArchived: null },
                { or: generateKeywordsQueryForOr(keywords, ['identifierKey']) }
              ]
            }
          }
        }
      ]
    }
  }

  return apiRequests.get('/v1/passenger-profile/count/join/', {
    params: { where, join },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

/**
 *
 * @param {string} [keywords]
 * @param {string} [identifierType]
 * @param {string} [countryId]
 * @param {number} [limit]
 * @param {number} [offset]
 * @param {string|string[]} [order=['dateCreated DESC', 'dateUpdated DESC']]
 * @returns {Promise<*>}
 */
function findArchivedPassengers ({
  keywords,
  identifierType,
  countryId,
  limit,
  offset,
  order = ['dateUpdated DESC NULLS LAST', 'dateCreated DESC']
}) {
  const filter = {
    where: { and: [{ dateArchived: { neq: null } }] },
    include: [
      {
        relation: 'passengerIdentifiers',
        scope: { limit: 1, order: 'dateUpdated DESC NULLS LAST' }
      }, {
        relation: 'passengerTrips',
        scope: { limit: 1, order: 'dateUpdated DESC' }
      },
      {
        relation: 'passengerAlternateIdentifiers',
        scope: { order: 'dateUpdated DESC' }
      }],
    limit,
    offset,
    order
  }

  if (countryId) {
    filter.where.and.push({ or: [{ currentCountryIdDetected: countryId }, { currentCountryIdSet: countryId }] })
  }

  if (identifierType) {
    if (!filter.join) {
      filter.join = {
        relation: 'passengerIdentifiers',
        scope: {
          where: { and: [] }
        }
      }
    }

    filter.join.scope.where.and.push({ identifierType })
    filter.include[0].scope.where = { ...filter.join.scope.where }
  }

  if (keywords) {
    if (/^\+{0,1}[0-9\s]+$/u.test(keywords)) {
      if (!filter.join) {
        filter.join = {
          relation: 'passengerIdentifiers',
          scope: {
            where: { and: [] }
          }
        }
      }

      filter.join.scope.where.and.push({ or: generateKeywordsQueryForOr(keywords, ['phoneNumber']) })
      filter.include[0].scope.where = { ...filter.join.scope.where }
    } else {
      filter.join = [
        (filter.join ? { ...filter.join } : null),
        {
          relation: 'passengerAlternateIdentifiers',
          scope: {
            where: { or: generateKeywordsQueryForOr(keywords, ['identifierKey']) }
          }
        }
      ].filter(Boolean)
    }
  }

  return apiRequests.get('/v1/raw-passenger-profile/join/', {
    params: { filter },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

/**
 *
 * @param {string} [keywords]
 * @param {string} [identifierType]
 * @param {string} [countryId]
 * @returns {Promise<*>}
 */
function findTotalArchivedPassengers ({ keywords, identifierType, countryId }) {
  const where = { and: [{ dateArchived: { neq: null } }] }

  let join

  if (countryId) {
    where.and.push({ or: [{ currentCountryIdDetected: countryId }, { currentCountryIdSet: countryId }] })
  }

  if (identifierType) {
    if (!join) {
      join = {
        relation: 'passengerIdentifiers',
        scope: {
          where: { and: [] }
        }
      }
    }

    join.scope.where.and.push({ identifierType })
  }

  if (keywords) {
    if (/^\+{0,1}[0-9\s]+$/u.test(keywords)) {
      if (!join) {
        join = {
          relation: 'passengerIdentifiers',
          scope: {
            where: { and: [] }
          }
        }
      }

      join.scope.where.and.push({ or: generateKeywordsQueryForOr(keywords, ['phoneNumber']) })
    } else {
      join = [
        (join ? { ...join } : null),
        {
          relation: 'passengerAlternateIdentifiers',
          scope: {
            where: { or: generateKeywordsQueryForOr(keywords, ['identifierKey']) }
          }
        }
      ].filter(Boolean)
    }
  }

  return apiRequests.get('/v1/raw-passenger-profile/count/join/', {
    params: { where, join },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

async function archivePassenger (passengerProfileId) {
  return apiRequests.delete('/v1/passenger-profile/' + passengerProfileId + '/', {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

async function sendNewPassengerPassword (passengerProfileId) {
  return apiRequests.post('/v1/passenger-profile/' + passengerProfileId + '/temporary-password/', {}, {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

async function clearPassengerReRegistrationGuard (passengerIdentifierId) {
  return apiRequests.delete('/v1/passenger-identifier/' + passengerIdentifierId + '/re-registration-guard/', {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

async function unarchivePassenger ({ passengerProfileId, passengerIdentifierId }) {
  return apiRequests.patch('/v1/passenger-profile/' + passengerProfileId + '/unarchive/', { passengerIdentifierId }, {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

async function refreshMobileMoneyProfile (passengerProfileId) {
  return apiRequests.patch('/v1/passenger-profile/' + passengerProfileId + '/mobile-money-profile/', {}, {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

async function findBulkPassengers ({
  keywords,
  dateCategory,
  dateFrom,
  dateTo,
  countryId,
  walletBalance,
  ticketsPurchased,
  daysSinceLastTrip,
  limit,
  offset,
  order
}) {
  return apiRequests.get('/v1/passenger-profile/search/', {
    params: {
      keywords,
      dateCategory,
      dateFrom,
      dateTo,
      countryId,
      walletBalance,
      ticketsPurchased,
      daysSinceLastTrip,
      limit,
      offset,
      order
    },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

async function findTotalBulkPassengers ({
  keywords,
  dateCategory,
  dateFrom,
  dateTo,
  countryId,
  walletBalance,
  ticketsPurchased,
  daysSinceLastTrip
}) {
  return apiRequests.get('/v1/passenger-profile/search/count/', {
    params: {
      keywords,
      dateCategory,
      dateFrom,
      dateTo,
      countryId,
      walletBalance,
      ticketsPurchased,
      daysSinceLastTrip
    },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

async function sendPassengersBulkNotification ({
  passengerProfileIds,
  smsContent,
  keywords,
  dateCategory,
  dateFrom,
  dateTo,
  countryId,
  walletBalance,
  ticketsPurchased,
  daysSinceLastTrip
}) {
  return apiRequests.post('/v1/passenger-profile/bulk-notification/', {
    passengerProfileIds,
    smsContent,
    keywords,
    dateCategory,
    dateFrom,
    dateTo,
    countryId,
    walletBalance,
    ticketsPurchased,
    daysSinceLastTrip
  }, {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

export {
  getArchivedOrUnarchivedPassenger,
  getPassengerIdentifier,
  findPassengerIdentifiers,
  findTotalPassengers,
  findPassengerIdentifiersByPassengerProfileId,
  findVerifiedPassengers,
  findTotalVerifiedPassengers,
  findPendingPassengers,
  findTotalPendingPassengers,
  findPendingTransferPassengers,
  findTotalPendingTransferPassengers,
  findArchivedPassengers,
  findTotalArchivedPassengers,
  archivePassenger,
  unarchivePassenger,
  findBulkPassengers,
  findTotalBulkPassengers,
  sendPassengersBulkNotification,
  sendNewPassengerPassword,
  clearPassengerReRegistrationGuard,
  refreshMobileMoneyProfile
}
