<template>
  <div>
    <vue-file-upload
      v-if="isEditable"
      class="upload-button"
      :name="name"
      :ref="refName"
      :timeout="parsedTimeout"
      :accept="acceptedMimeTypesString"
      :multiple="isMultiple"
      v-model="idDocumentsToUpload"
    >
      <v-btn block>{{uploadLabel || 'Select file'}}</v-btn>
    </vue-file-upload>
    <div v-if="documentsData.documentsToUpload && documentsData.documentsToUpload.length">
      <v-btn small block class="my-2" color="warning" @click="clearUpload()">Undo</v-btn>
      <div :key="`document${index}ToUpload`" v-for="(documentToUpload, index) in documentsData.documentsToUpload">
        <div class="text-xs-center mb-2">
          <span><code>{{documentToUpload.name}}</code></span> &nbsp;
          <span><code>{{documentToUpload.size}} bytes</code></span>
        </div>
        <v-alert v-if="documentToUpload.error" class="mb-2" type="error">{{documentToUpload.error}}</v-alert>
        <div class="layout row justify-center mb-2">
          <img v-if="documentToUpload.isImage" :src="documentToUpload.blob" :alt="`${documentToUpload.name} preview`" :width="imageWidth || 'auto'" :height="imageHeight || 100" />
          <div v-if="!documentToUpload.isImage">
            <v-icon large v-text="getDocumentIcon(documentToUpload.type)" />
          </div>
        </div>
      </div>
    </div>
    <div v-show="!documentsData.documentsToUpload || !documentsData.documentsToUpload.length">
      <div class="layout column align-center justify-center mb-2" :key="`uploadedDocument${index}`" v-for="(uploadedDocument, index) in documentsData.uploadedDocuments">
        <div class="text-xs-center mb-2">
          <span><code>{{uploadedDocument.filename}}</code></span> &nbsp;
          <span><code>{{uploadedDocument.size}} bytes</code></span>
        </div>
        <div class="mb-2">
          <v-alert v-if="!uploadedDocument.url" type="error">No secure URL found</v-alert>
          <a v-if="uploadedDocument.url" :href="uploadedDocument.url" target="_blank">
            <img v-if="isImage(uploadedDocument.type)" :src="uploadedDocument.url" :alt="`${uploadedDocument.filename} preview`" :width="imageWidth || 'auto'" :height="imageHeight || 100" />
            <v-icon v-if="!isImage(uploadedDocument.type)" v-text="getDocumentIcon(uploadedDocument.type)" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.upload-button {
  width: 100%;
  :hover {
    cursor: pointer;
  }
}
</style>

<script>
import src from './src'
export default src
</script>
