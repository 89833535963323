import { apiRequests } from '../utilities/axios-factory'
import store from '../store'

function getTimezones () {
  return apiRequests.get('/v1/country-currency/timezones/', {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function findCountryCurrencies (filter) {
  return apiRequests.get('/v1/country-currency/join/', {
    params: { filter },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

export {
  getTimezones,
  findCountryCurrencies
}
