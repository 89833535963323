function decodeMinewQrCodeString (qrCodeString) {
  if (qrCodeString.replace(/:/ug, '').length === 12 &&
    /^[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{8}/.test(qrCodeString.replace(/:/ug, ''))) {
    return qrCodeString.replace(/:/ug, '')
  }

  return ''
}

function decodeKkmQrCodeString (qrCodeString) {
  if (qrCodeString.length >= 12 && qrCodeString.length < 32 &&
    /^MAC:[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{8},SERIAL.+/.test(qrCodeString)) {
    return qrCodeString.split(':')[1].split(',')[0]
  }

  return ''
}

function formatAlternateIdKey (address) {
  return address.replace(/:/ug, '').toUpperCase()
}

module.exports = {
  decodeMinewQrCodeString,
  decodeKkmQrCodeString,
  formatAlternateIdKey
}
