
const defaultButtonAttributes = {
  color: 'default',
  title: 'Click to download file'
}

export default {
  name: 'download-button',
  props: {
    label: String,
    labelIcon: String,
    onClick: Function, // expects promise returned
    onDownload: {
      type: Function,
      required: true
    }, // expects promise returned
    downloadType: {
      type: String,
      required: true
    },
    fileName: {
      type: String,
      required: true
    },
    disabled: Boolean,
    buttonAttributes: Object,
    dataIsAlreadyBlob: Boolean
  },
  data () {
    return {
      currentLabel: this.$props.label,
      currentLabelIcon: this.$props.labelIcon,
      currentOnClick: this.$props.onClick, // expects promise returned
      currentOnDownload: this.$props.onDownload, // expects promise returned
      currentDownloadType: this.$props.downloadType,
      currentFileName: this.$props.fileName,
      currentDisabled: this.$props.disabled,
      currentDataIsAlreadyBlob: this.$props.dataIsAlreadyBlob,
      currentButtonAttributes: Object.assign(defaultButtonAttributes, this.$props.buttonAttributes || {}),
      isDownloading: false,
      downloadBlob: null,
      downloadError: '',
      modalDownloadError: false,
      enableDownloadStub: false,
      objectURLCreated: false
    }
  },
  watch: {
    label: {
      // the callback will be called immediately after the start of the observation
      // immediate: true,
      handler (val, oldVal) {
        this.$data.currentLabel = val
      }
    },
    labelIcon (val) {
      this.$data.currentLabelIcon = val
    },
    onClick (val) {
      this.$data.currentLabelIcon = val
    }, // expects promise returned
    onDownload (val) {
      this.$data.currentOnDownload = val
    }, // expects promise returned
    downloadType (val) {
      this.$data.currentDownloadType = val
    },
    fileName (val) {
      this.$data.currentFileName = val
    },
    buttonAttributes (val) {
      this.$data.currentButtonAttributes = Object.assign(defaultButtonAttributes, val || {})
    },
    disabled (val) {
      this.$data.currentDisabled = val
    },
    dataIsAlreadyBlob (val) {
      this.$data.currentDataIsAlreadyBlob = val
    }
  },
  mounted: function () {
  },
  methods: {
    async getDownloadData () {
      try {
        let continueDownload = true
        if (this.$data.currentOnClick) {
          continueDownload = await this.$data.currentOnClick()
        }
        if (continueDownload) {
          this.$data.isDownloading = true
          const data = await this.$data.currentOnDownload()
          this.$data.isDownloading = false
          await this.initDownload(data)
        }
      } catch (err) {
        console.log('download button error', err)
        this.$data.isDownloading = false
        this.$data.downloadError = (err && (err.error ? err.error.message : err.message ? err.message : JSON.stringify(err))) || 'Unknown error'
        this.$data.modalDownloadError = true
      }
    },
    async initDownload (data) {
      const blob = this.$data.currentDataIsAlreadyBlob ? data : new Blob([data], { type: this.$data.currentDownloadType })
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, this.$data.currentFileName)
      } else {
        this.cleanUpObjectURL()
        this.$data.enableDownloadStub = true
        this.$data.objectURLCreated = !this.$data.currentDataIsAlreadyBlob
        this.$data.downloadBlob = this.$data.currentDataIsAlreadyBlob ? blob : window.URL.createObjectURL(blob)
        this.$nextTick(() => {
          // this.$refs['downloadStub'].href = this.$data.downloadBlob
          this.$refs['downloadStub'].click()
          this.$data.enableDownloadStub = false
        })
      }
    },
    cleanUpObjectURL () {
      if (this.$data.objectURLCreated) {
        window.URL.revokeObjectURL(this.$data.downloadBlob)
        this.$data.objectURLCreated = false
      }
    }
  },
  beforeDestroy () {
    this.cleanUpObjectURL()
  }
}
