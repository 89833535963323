import _isEqual from 'lodash.isequal'
import _upperFirst from 'lodash.upperfirst'
import _intersection from 'lodash.intersection'

import {
  D_FIND_STAFF,
  D_FIND_STAFF_TOTAL,
  D_FIND_INVITED_STAFF,
  D_FIND_INVITED_STAFF_TOTAL,
  D_UPDATE_STAFF,
  D_DELETE_STAFF,
  D_INVITE_STAFF,
  D_RESEND_INVITE_STAFF,
  D_DELETE_INVITE_STAFF,
  D_UNDO_DELETE_STAFF
} from '../../utilities/action-types'
import store from '../../store'
import { formatDate, formatTime } from '@smarttransit/common'
import { generateKeywordsQueryForOr } from '@smarttransit/common-client'
import { isAuthorized, addAlert } from '../../utilities/helpers'
const ROOT_EMAIL = 'root@smarttransit.io'

export default {
  name: 'staff-management',
  props: {
    signedInUser: Object,
    onUserCreated: Function,
    onUserDeleted: Function,
    onUserUpdated: Function,
    onError: Function
  },
  data () {
    return {
      users: [],
      totalUsers: 0,
      totalArchivedUsers: 0,
      totalInvitedUsers: 0,
      userModalApiInProgress: false,
      apiInProgress: false,
      isArchiveInProgress: false,
      pagination: null,
      selectedUserType: 'none',
      userTypes: [{ text: 'Super Admin', value: 'superadmin' }, { text: 'Stakeholder', value: 'investor' }, { text: 'Admin', value: 'admin' }, { text: 'Staff', value: 'staff' }, { text: 'Vendor', value: 'vendor' }],
      searchKeywords: '',
      userEditorLoaded: false,
      selectedUserTab: 'users',
      userInviteLoaded: false,
      invitedUser: null,
      selectedUser: null,
      updateUserLoaded: false,
      updatingUser: null,
      modalSiteAlertData: null,
      modalSiteAlert: false,
      selectedAccountPermissions: [],
      transportationRoles: [],
      headers: [],
      invitationHeaders: [],
      archivedHeaders: []
    }
  },
  computed: {
    userTypesByAuthorization () {
      let filteredUserTypes = [{ text: 'Not authorized to set a user type', value: 'none' }]
      if (this.$props.signedInUser) {
        if (this.$props.signedInUser.email === ROOT_EMAIL) {
          filteredUserTypes = this.userTypes
        } else if (this.$props.signedInUser.roles.some((o) => o.name === 'superadmin')) {
          filteredUserTypes = this.userTypes.filter(o => (o.value !== 'superadmin'))
        } else if (this.$props.signedInUser.roles.some((o) => o.name === 'admin')) {
          filteredUserTypes = this.userTypes.filter(o => (o.value === 'staff'))
        }
      }
      return filteredUserTypes
    }
  },
  watch: {
    userInviteLoaded (val) {
      if (val) {
        this.invitedUser = {
          userType: this.currentUserHasRole('admin') ? 'none' : 'staff',
          verificationType: 'email',
          email: '',
          phone: '',
          firstName: '',
          lastName: ''
        }
      } else {
        this.invitedUser = null
      }
    }
  },
  mounted: function () {
    let headers = [
      {
        text: 'Last Name',
        align: 'left',
        sortable: true,
        value: 'lastName'
      },
      {
        text: 'First Name',
        align: 'left',
        sortable: true,
        value: 'firstName'
      },
      {
        text: 'Phone',
        align: 'left',
        sortable: true,
        value: 'phone'
      },
      {
        text: 'Email',
        align: 'left',
        sortable: true,
        value: 'email'
      },
      {
        text: 'User Type',
        align: 'left',
        sortable: false
      }
    ]
    headers.push({
      text: 'Date Created',
      align: 'left',
      sortable: true,
      value: 'dateCreated'
    })
    headers.push({
      text: 'Date Updated',
      align: 'left',
      sortable: true,
      value: 'dateUpdated'
    })
    headers.push({ text: 'Actions', sortable: false })
    this.headers = headers
    this.invitationHeaders = [
      {
        text: 'Last Name',
        align: 'left',
        sortable: true,
        value: 'lastName'
      },
      {
        text: 'First Name',
        align: 'left',
        sortable: true,
        value: 'firstName'
      },
      {
        text: 'Phone',
        align: 'left',
        sortable: true,
        value: 'phone'
      },
      {
        text: 'Email',
        align: 'left',
        sortable: true,
        value: 'email'
      },
      {
        text: 'User Type',
        align: 'left',
        sortable: false
      },
      {
        text: 'Date Created',
        align: 'left',
        sortable: true,
        value: 'dateCreated'
      },
      {
        text: 'Invitation Expiry Date',
        align: 'left',
        sortable: true,
        value: 'verificationTokenExpiry'
      },
      { text: 'Actions', sortable: false }
    ]
    this.archivedHeaders = [
      {
        text: 'Last Name',
        align: 'left',
        sortable: true,
        value: 'lastName'
      },
      {
        text: 'First Name',
        align: 'left',
        sortable: true,
        value: 'firstName'
      },
      {
        text: 'Phone',
        align: 'left',
        sortable: true,
        value: 'phone'
      },
      {
        text: 'Email',
        align: 'left',
        sortable: true,
        value: 'email'
      },
      {
        text: 'User Type',
        align: 'left',
        sortable: false
      },
      {
        text: 'Date Created',
        align: 'left',
        sortable: true,
        value: 'dateCreated'
      },
      {
        text: 'Date Archived',
        align: 'left',
        sortable: true,
        value: 'dateArchived'
      },
      { text: 'Actions', sortable: false }
    ]
    this.searchUsers()
  },
  methods: {
    hasUserType (role) {
      const { signedInUser } = this.$props
      return (signedInUser && isAuthorized(signedInUser, role))
    },
    isUpdatingUserEdited () {
      return !_isEqual(this.updatingUser, this.clonedUpdatingUser)
    },
    loadUpdateUser (user) {
      if (user.isEditable) {
        user.phone = user.phone || ''
        this.updatingUser = user
        this.clonedUpdatingUser = { ...user }
        this.updateUserLoaded = true
      }
    },
    cancelEdit () {
      this.updateUserLoaded = false
      this.updatingUser = null
    },
    updateUser () {
      if (this.updatingUser.isEditable) {
        this.updatingUser.isLoading = true
        if (this.isUserEditable(this.updatingUser)) {
          this.updatingUser.roles = [this.updatingUser.userType]
        }
        if (!this.updatingUser.phone || this.updatingUser.phone === 'null') {
          this.updatingUser.phone = undefined
        }
        store.dispatch(D_UPDATE_STAFF, {
          id: this.updatingUser.id,
          data: this.updatingUser
        }).then((result) => {
          this.updateUserLoaded = false
          this.searchUsers(this.selectedUserTab)
          this.addAlert(`Successfully updated user ${this.updatingUser.firstName} ${this.updatingUser.lastName}`, 'success')
          this.updatingUser = null
        }).catch((err) => {
          this.addAlert(`Error in updating user: ${err && err.error.message ? err.error.message : JSON.stringify(err)}`, 'error', 'isModal')
        }).finally(() => {
          if (this.updatingUser) {
            this.updatingUser.isLoading = false
          }
        })
      }
    },
    addAlert (message, type, isModal = undefined) {
      if (isModal) {
        this.modalSiteAlertData = { type: type.toUpperCase(), message }
        this.modalSiteAlert = true
      } else {
        addAlert({
          message,
          type,
          transient: type === 'success'
        })
      }
    },
    currentUserHasRole (role) {
      const user = (this.$store?.state?.credentials?.user || [])
      return isAuthorized(user, role)
    },
    inviteUser () {
      if (!this.invitedUser || (!this.invitedUser.email && !this.invitedUser.phone) || !this.invitedUser.firstName || !this.invitedUser.lastName || !this.invitedUser.userType) {
        return this.addAlert('One of email, phone, first name, last name or user type not provided', 'error', 'isModal')
      }
      this.userModalApiInProgress = true
      store.dispatch(D_INVITE_STAFF, {
        email: this.invitedUser.verificationType === 'email' ? this.invitedUser.email : null,
        phone: this.invitedUser.verificationType === 'phone' ? this.invitedUser.phone.replace(/ /ug, '') : null,
        firstName: this.invitedUser.firstName,
        lastName: this.invitedUser.lastName,
        roles: [this.invitedUser.userType],
        verificationType: this.invitedUser.verificationType
      }).then((result) => {
        this.userInviteLoaded = false
        this.selectedUserTab = 'invited-users'
        this.searchUsers(this.selectedUserTab)
        this.addAlert(`Successfully sent invitation for ${this.invitedUser.firstName} ${this.invitedUser.lastName}`, 'success')
      }).catch((err) => {
        this.addAlert(`Error in inviting user: ${JSON.stringify(err)}`, 'error', 'isModal')
      }).finally(() => {
        this.userModalApiInProgress = false
      })
    },
    resendUserInvitation (user) {
      if (confirm(`Confirm re-sending invitation for ${user.firstName} ${user.lastName}`)) {
        store.dispatch(D_RESEND_INVITE_STAFF, {
          id: user.id
        }).then((result) => {
          this.searchUsers('invited-users')
          this.addAlert(`Successfully re-sent invitation for ${user.firstName} ${user.lastName}`, 'success')
        }).catch((err) => {
          this.addAlert(`Error in re-sending invite for user: ${JSON.stringify(err)}`, 'error', 'isModal')
        })
      }
    },
    async cancelInvitedUser (user) {
      if (confirm(`Confirm cancelling invitation for ${user.firstName} ${user.lastName}`)) {
        await store.dispatch(D_DELETE_INVITE_STAFF, {
          id: user.id
        })
        this.searchUsers('invited-users')
        this.addAlert(`Successfully deleted invitation for ${user.firstName} ${user.lastName}`, 'success')
      }
    },
    onTabChanged (val) {
      console.log('tab changed', val)
      if (this.pagination) {
        this.searchUsers(val)
      }
    },
    onPagination () {
      this.searchUsers(this.selectedUserTab)
    },
    onFilterByRole (val) {
      this.selectedAccountPermissions = val
      this.searchUsers(this.selectedUserTab)
    },
    onFilterByUserType (val) {
      this.selectedUserType = val
      this.searchUsers(this.selectedUserTab)
    },
    archiveUser (user) {
      if (this.hasUserType('admin')) {
        user.isArchiveInProgress = true
        store.dispatch(D_DELETE_STAFF, {
          id: user.id
        }).then(() => {
          this.searchUsers(this.selectedUserTab)
          this.addAlert(`Successfully archived user ${user.firstName} ${user.lastName}`, 'success')
        }).catch((err) => {
          this.addAlert(`Error in archiving user: ${err && err.error.message ? err.error.message : JSON.stringify(err)}`, 'error')
        }).finally(() => {
          user.isArchiveInProgress = false
        })
      }
    },
    undoArchiveUser (user) {
      if (this.hasUserType('admin')) {
        user.isArchiveInProgress = true
        store.dispatch(D_UNDO_DELETE_STAFF, {
          id: user.id
        }).then(() => {
          this.searchUsers(this.selectedUserTab)
          this.addAlert(`Successfully un-archived user ${user.firstName} ${user.lastName}`, 'success')
        }).catch((err) => {
          this.addAlert(`Error in un-archiving user: ${err && err.error.message ? err.error.message : JSON.stringify(err)}`, 'error')
        }).finally(() => {
          user.isArchiveInProgress = false
        })
      }
    },
    deleteUser (user) {
      if (user.isEditable) {
        // perform delete
      }
    },
    addArchivedWhere (filter) {
      filter.where.dateArchived = { neq: null }
    },
    getTotal (category) {
      if (category === 'invited-users') {
        return this.totalInvitedUsers
      } else if (category === 'archived-users') {
        return this.totalArchivedUsers
      } else {
        return this.totalUsers
      }
    },
    isUserEditable (user, ignoreIfEmailSame) {
      let isEditable = false
      if (user.isRoot) {
        isEditable = this.$props.signedInUser.email === user.email
      } else {
        if ((!ignoreIfEmailSame && this.$props.signedInUser.email === user.email) || this.$props.signedInUser.email === ROOT_EMAIL) {
          isEditable = true
        } else if (this.hasUserType('superadmin')) {
          isEditable = !user.roles.find((o) => o.name === 'superadmin')
          console.log('isEditable', isEditable)
        } else if (this.hasUserType('admin')) {
          isEditable = !_intersection(['superadmin', 'admin', 'investor', 'vendor'], user.roles.map(o => o.name)).length
          console.log('admin isEditable', isEditable)
        }
      }
      return isEditable
    },
    /**
     *
     * @param {string} category - can be 'invited' or 'archived'
     * @returns {Promise<void>}
     */
    async searchUsers (category = undefined) {
      let promises = []
      this.apiInProgress = true
      let retrievedUsers
      if (category === 'invited-users') {
        if (this.hasUserType('admin')) {
          this.getTotalArchivedUsers().then((total) => {
            this.totalArchivedUsers = total.count
          })
        }
        this.getTotalUsers().then((total) => {
          this.totalUsers = total.count
        })
        promises.push(this.getInvitedUsers())
        promises.push(this.getTotalInvitedUsers())
        const [ users, total ] = await Promise.all(promises)
        this.totalInvitedUsers = total.count
        retrievedUsers = users
      } else if (category === 'archived-users') {
        this.getTotalInvitedUsers().then((total) => {
          this.totalInvitedUsers = total.count
        })
        this.getTotalUsers().then((total) => {
          this.totalUsers = total.count
        })
        promises.push(this.getArchivedUsers())
        promises.push(this.getTotalArchivedUsers())
        const [ users, total ] = await Promise.all(promises)
        this.totalArchivedUsers = total.count
        retrievedUsers = users
      } else {
        if (this.hasUserType('admin')) {
          this.getTotalArchivedUsers().then((total) => {
            this.totalArchivedUsers = total.count
          })
        }
        if (this.hasUserType('admin')) {
          this.getTotalInvitedUsers().then((total) => {
            this.totalInvitedUsers = total.count
          })
        }
        promises.push(this.getUsers())
        promises.push(this.getTotalUsers())
        const [ users, total ] = await Promise.all(promises)
        this.totalUsers = total.count
        retrievedUsers = users
      }
      this.users = retrievedUsers.map(o => {
        o.isExpired = new Date(o.verificationTokenExpiry).getTime() <= Date.now()
        o.verificationTokenExpiryLabel = this.selectedUserTab === 'invited-users' ? `${formatTime(o.verificationTokenExpiry)}, ${formatDate(o.verificationTokenExpiry)}` : null
        o.dateArchivedLabel = this.selectedUserTab === 'archived-users' ? `${formatTime(o.dateArchived)}, ${formatDate(o.dateArchived)}` : null
        o.dateCreatedLabel = `${formatTime(o.dateCreated)}, ${formatDate(o.dateCreated)}`
        o.dateUpdatedLabel = o.dateUpdated ? `${formatTime(o.dateUpdated)}, ${formatDate(o.dateUpdated)}` : ''
        o.userType = o.roles && o.roles.length ? o.roles[0].name : null
        o.userTypeLabel = o.roles ? o.roles.map(o => _upperFirst(o.name === 'investor' ? 'stakeholder' : o.name)).join(', ') : ''
        o.invitedUserTypesLabel = o.metadata?.invitedRoles
        o.invitedUserTypesLabel = o.invitedUserTypesLabel && o.invitedUserTypesLabel.length ? o.invitedUserTypesLabel.map(o => _upperFirst(o === 'investor' ? 'stakeholder' : o)).join(', ') : ''
        o.isRoot = o.email === ROOT_EMAIL
        o.isEditable = this.isUserEditable(o)
        return o
      })
      this.apiInProgress = false
    },
    getGenericFilter () {
      const { sortBy, descending, page, rowsPerPage } = this.pagination
      const offset = page === 1 ? 0 : (page * rowsPerPage) - rowsPerPage
      let filter = {
        limit: rowsPerPage,
        offset,
        where: {}
      }
      if (sortBy) {
        filter.order = `${sortBy} ${descending ? 'DESC' : 'ASC'}`
      }
      if (this.searchKeywords) {
        filter.where.or = generateKeywordsQueryForOr(this.searchKeywords, ['firstName', 'lastName', 'email', 'phone'])
      }
      filter.include = 'roles'
      if (this.selectedAccountPermissions.length) {
        let permissionsWhere = { where: { roles: { or: [] } } }
        this.selectedAccountPermissions.forEach((permission) => {
          permissionsWhere.where.roles.or.push({ ilike: `%${permission}%` })
        })
        filter.join = { relation: 'transportationOwnerUser', scope: permissionsWhere }
      }
      return filter
    },
    async getUsers () {
      let filter = this.getGenericFilter()
      let userTypes
      if (this.selectedUserType && this.selectedUserType !== 'none') {
        userTypes = [this.selectedUserType]
      }
      return store.dispatch(D_FIND_STAFF, { userTypes, filter })
    },
    async getTotalUsers () {
      let filter = this.getGenericFilter()
      let userTypes
      if (this.selectedUserType && this.selectedUserType !== 'none') {
        userTypes = [this.selectedUserType]
      }
      return store.dispatch(D_FIND_STAFF_TOTAL, { userTypes, where: filter.where })
    },
    async getInvitedUsers () {
      let filter = this.getGenericFilter()
      return store.dispatch(D_FIND_INVITED_STAFF, { filter })
    },
    async getTotalInvitedUsers () {
      let filter = this.getGenericFilter()
      return store.dispatch(D_FIND_INVITED_STAFF_TOTAL, { where: filter.where })
    },
    async getArchivedUsers () {
      let filter = this.getGenericFilter()
      this.addArchivedWhere(filter)
      return store.dispatch(D_FIND_STAFF, { filter, includeDeleted: true })
    },
    async getTotalArchivedUsers () {
      let filter = this.getGenericFilter()
      this.addArchivedWhere(filter)
      return store.dispatch(D_FIND_STAFF_TOTAL, { where: filter.where, includeDeleted: true })
    }
  }
}
