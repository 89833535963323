import { apiRequests } from '../utilities/axios-factory'
import {
  D_GET_TRANSPORTATION_OWNER,
  D_FIND_TRANSPORTATION_OWNERS,
  D_FIND_TRANSPORTATION_OWNERS_TOTAL,
  D_VERIFY_TRANSPORTATION_OWNER_REGISTRATION,
  D_FIND_ARCHIVED_TRANSPORTATION_OWNERS,
  D_FIND_ARCHIVED_TRANSPORTATION_OWNERS_TOTAL,
  D_ARCHIVE_TRANSPORTATION_OWNER,
  D_UNARCHIVE_TRANSPORTATION_OWNER,
  D_DELETE_TRANSPORTATION_OWNER,
  D_REGISTER_TRANSPORTATION_OWNER,
  D_UPDATE_TRANSPORTATION_OWNER,
  D_FIND_TRANSPORTATION_PROFILES,
  D_GET_TRANSPORTATION_PROFILE,
  D_UPDATE_TRANSPORTATION_PROFILE,
  D_CREATE_TRANSPORTATION_PROFILE,
  D_DELETE_TRANSPORTATION_PROFILE,
  D_FIND_TRANSPORTATION_PROFILES_TOTAL,
  D_FIND_TRANSPORTATION_OWNER_USER,
  D_CREATE_PROFILE_ROUTE,
  D_UPDATE_PROFILE_ROUTE,
  D_DELETE_PROFILE_ROUTE,
  D_FIND_PROFILE_ROUTES,
  D_FIND_PROFILE_ROUTES_TOTAL,
  D_GET_PROFILE_ROUTE
} from '../utilities/action-types'
import { C_SET_SELECTED_TRANSPORTATION_OWNER } from '../utilities/mutation-types'
import _isNil from 'lodash.isnil'

export default {
  state: {
    selectedTransportationOwner: null
  },
  getters: {
    getSelectedTransportationOwner (state) {
      return state.selectedTransportationOwner
    }
  },
  mutations: {
    [C_SET_SELECTED_TRANSPORTATION_OWNER] (state, value) {
      if (_isNil(value)) {
        state.selectedTransportationOwner = null
      } else {
        state.selectedTransportationOwner = value
      }
    }
  },
  actions: {
    [D_GET_TRANSPORTATION_OWNER] ({ commit, getters }, { id, filter }) {
      return apiRequests.get('/v1/transportation-owner/' + id + '/', {
        params: { filter },
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    },
    [D_FIND_TRANSPORTATION_OWNERS] ({ commit, getters }, filter) {
      return apiRequests.get('/v1/transportation-owner/', {
        params: { filter },
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    },
    [D_FIND_TRANSPORTATION_OWNERS_TOTAL] ({ commit, getters }, where) {
      return apiRequests.get('/v1/transportation-owner/count/', {
        params: { where },
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    },
    [D_FIND_ARCHIVED_TRANSPORTATION_OWNERS] ({ commit, getters }, filter) {
      return apiRequests.get('/v1/raw-transportation-owner/', {
        params: { filter },
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    },
    [D_FIND_ARCHIVED_TRANSPORTATION_OWNERS_TOTAL] ({ commit, getters }, where) {
      return apiRequests.get('/v1/raw-transportation-owner/count/', {
        params: { where },
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    },
    [D_ARCHIVE_TRANSPORTATION_OWNER] ({ commit, getters }, { id }) {
      return apiRequests.delete('/v1/transportation-owner/' + id + '/', {
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    },
    [D_UNARCHIVE_TRANSPORTATION_OWNER] ({ commit, getters }, { id }) {
      return apiRequests.put('/v1/transportation-owner/' + id + '/deleted/', null, {
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    },
    [D_DELETE_TRANSPORTATION_OWNER] ({ commit, getters }, { id }) {
      return apiRequests.delete('/v1/transportation-owner/' + id + '/permanent/', {
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    },
    [D_REGISTER_TRANSPORTATION_OWNER] ({ commit, getters }, data) {
      return apiRequests.post('/v1/transportation-owner/registration/', data, {
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    },
    [D_VERIFY_TRANSPORTATION_OWNER_REGISTRATION] ({ commit, getters }, data) {
      return apiRequests.put('/v1/transportation-owner/registration/verification', data, {
        headers: getters.getTokenId ? { 'Authorization': getters.getTokenId } : undefined
      }).then((result) => {
        return result.data
      })
    },
    [D_UPDATE_TRANSPORTATION_OWNER] ({ commit, getters }, { id, companyName, contactName, contactEmail, contactPhone, currentCountryId, equipmentTokens }) {
      return apiRequests.patch('/v1/transportation-owner/' + id + '/', { companyName, contactName, contactEmail, contactPhone, currentCountryId, equipmentTokens }, {
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    },

    [D_FIND_TRANSPORTATION_PROFILES] ({ commit, getters }, filter) {
      if (filter.where && !Object.keys(filter.where).length) {
        filter.where = undefined
      }
      return apiRequests.get('/v1/transportation-profile/join/', {
        params: { filter },
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    },
    [D_FIND_TRANSPORTATION_PROFILES_TOTAL] ({ commit, getters }, { where, join }) {
      if (where && !Object.keys(where).length) {
        where = undefined
      }
      return apiRequests.get('/v1/transportation-profile/count/join/', {
        params: { where, join },
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    },
    [D_GET_TRANSPORTATION_PROFILE] ({ commit, getters }, { id, filter }) {
      return apiRequests.get('/v1/transportation-profile/' + id + '/', {
        params: { filter },
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    },
    [D_DELETE_TRANSPORTATION_PROFILE] ({ commit, getters }, { id }) {
      return apiRequests.delete('/v1/transportation-profile/' + id + '/', {
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    },
    [D_UPDATE_TRANSPORTATION_PROFILE] ({ commit, getters }, data) {
      return apiRequests.patch('/v1/transportation-profile/' + data.id + '/', data, {
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    },
    [D_CREATE_TRANSPORTATION_PROFILE] ({ commit, getters }, { transportationOwnerId, data }) {
      return apiRequests.post('/v1/transportation-owner/' + transportationOwnerId + '/transportation-profile/', data, {
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    },

    [D_FIND_TRANSPORTATION_OWNER_USER] ({ commit, getters }, filter) {
      if (filter.where && !Object.keys(filter.where).length) {
        filter.where = undefined
      }
      return apiRequests.get('/v1/transportation-owner-user/', {
        params: { filter },
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    },

    [D_FIND_PROFILE_ROUTES] ({ commit, getters }, filter) {
      if (filter.where && !Object.keys(filter.where).length) {
        filter.where = undefined
      }
      return apiRequests.get('/v1/transportation-profile-route/join/', {
        params: { filter },
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    },
    [D_FIND_PROFILE_ROUTES_TOTAL] ({ commit, getters }, { where, join }) {
      if (where && !Object.keys(where).length) {
        where = undefined
      }
      return apiRequests.get('/v1/transportation-profile-route/count/join/', {
        params: { where, join },
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    },
    [D_GET_PROFILE_ROUTE] ({ commit, getters }, { id, filter }) {
      return apiRequests.get('/v1/transportation-profile-route/' + id + '/join/', {
        params: { filter },
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    },
    [D_DELETE_PROFILE_ROUTE] ({ commit, getters }, { id }) {
      return apiRequests.delete('/v1/transportation-profile-route/' + id + '/', {
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    },
    [D_UPDATE_PROFILE_ROUTE] ({ commit, getters }, data) {
      return apiRequests.patch('/v1/transportation-profile-route/' + data.id + '/', data, {
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    },
    [D_CREATE_PROFILE_ROUTE] ({ commit, getters }, { transportationProfileId, data }) {
      return apiRequests.post('/v1/transportation-profile/' + transportationProfileId + '/transportation-profile-route/', data, {
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    }
  }
}
