import { D_SIGN_OUT } from '../../utilities/action-types'
import { C_ADD_SITE_ALERT, C_INVALIDATE_TOKEN, C_SET_SIGNED_IN_USER } from '../../utilities/mutation-types'
import store from '../../store'

export default {
  name: 'sign-out',
  created: function () {
    if (this.$store.getters.getToken) {
      this.$store.dispatch(D_SIGN_OUT).then(() => {
        this.$router.replace({ name: 'home' })
      }).catch((err) => {
        if (err.error.statusCode === 401) {
          store.commit(C_INVALIDATE_TOKEN)
          store.commit(C_SET_SIGNED_IN_USER, { newUser: null })
          this.$router.replace({ name: 'home' })
        } else {
          store.commit(C_ADD_SITE_ALERT, {
            message: `Could not complete sign out: ${JSON.stringify(err)}`,
            type: 'error'
          })
        }
      })
    } else {
      this.$router.replace({ name: 'home' })
    }
  },
  props: {}
}
