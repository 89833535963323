import Vue from 'vue'
import Vuex from 'vuex'
import credentials from './store-modules/credentials'
import siteAlerts from './store-modules/site-alerts'
import cacheSettings from './store-modules/cache-settings'
import countryAccountTypes from './store-modules/country-account-types'
import routeFare from './store-modules/route-fare'
import transportationOwnersAndProfiles from './store-modules/transportation-owners-and-profiles'
import transportationEquipment from './store-modules/transportation-equipment'
import usersAndProfiles from './store-modules/users-and-profiles'
import busStops from './store-modules/bus-stops'
import transactions from './store-modules/transactions'
import siteProperties from './store-modules/site-properties'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    credentials,
    siteAlerts,
    cacheSettings,
    countryAccountTypes,
    routeFare,
    transportationOwnersAndProfiles,
    transportationEquipment,
    usersAndProfiles,
    busStops,
    transactions,
    siteProperties
  }
})
