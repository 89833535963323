<template>
  <div>
    <v-text-field
      v-model="timeValue"
      v-bind="otherProps"
      :rules="timeLabelRules" />
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

<script>
import src from './src'
export default src
</script>
