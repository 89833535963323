<template>
    <v-container fluid>
        <v-slide-y-transition mode="out-in">
            <v-layout column align-center>
                <h1>Signing out...</h1>
            </v-layout>
        </v-slide-y-transition>
    </v-container>
</template>

<script>
import src from './src'
export default src
</script>
