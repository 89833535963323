<template>
  <div class="st-map" style="height: 100%">
    <div v-if="!fullContainer" :id="`topbar${containerId}`" :ref="`topbar${containerId}`">
      <v-layout column v-if="topbar">
        <v-layout v-if="!disableSearch" row wrap>
          <v-flex md6 sm12 pa-2>
            <v-autocomplete
              @change="fromFieldSelectChangeHandle"
              :loading="isFromFieldLoading"
              :items="fromFieldItems"
              :search-input.sync="fromFieldSearch"
              hide-no-data
              hide-details
              no-filter
              allow-overflow label="From"
              :placeholder="fromFieldSearchPlaceholder"
            />
          </v-flex>
          <v-flex md6 sm12 pa-2>
            <v-autocomplete
              @change="toFieldSelectChangeHandle"
              :loading="isToFieldLoading"
              :items="toFieldItems"
              :search-input.sync="toFieldSearch"
              hide-no-data
              hide-details
              no-filter
              allow-overflow label="To"
              :placeholder="toFieldSearchPlaceholder"
            />
          </v-flex>
        </v-layout >
        <v-layout row wrap px-2>
          <v-flex offset-md6 offset-sm5 md2 sm2 pt-2>
            <v-btn v-if="!disableSearch" block :color="isModal ? '' : 'primary'" :disabled="!fromLatLng || !fromLatLng || routeLatLngInvalid" @click="routeQuery(fromLatLng, toLatLng)">Get Route(s)
            </v-btn>
          </v-flex>
          <v-flex md4 sm5 xs12 pl-2>
            <v-select
              dense
              outline
              hide-details
              v-model="selectedRegion"
              :items="regions"
              label="Region"
              item-text="text"
              :loading="regionsLoading"
              no-data-text="No regions found"
            />
          </v-flex>
        </v-layout>
        <div class="py-2 px-2 text-xs-right"><span v-if="selectedRegion && displayRegionTime" v-html="`${selectedRegion ? selectedRegion.routerId : ''} time: ${regionTimeLabel}${regionTimeProhibitedText}`" /></div>
      </v-layout>
      <v-layout row v-if="sidebar || realtimeMapByAccount" px-2>
        <v-flex row wrap xs4 sm10>
          <div v-if="realtimeMapByAccount" class="layout row align-center justify-start">
            <div>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <div class="pulse-generator-content">
                    <div style="width: 24px; height: 24px;" :class="realtimeMapError || !realtimeMapLastUpdatedLabel ? 'pulse-generator pulse-generator--off' : 'pulse-generator'" />
                    <v-icon class="pulse-generator-child" style="width: 24px; height: 24px;" v-show="!realtimeMapError" v-on="on">fal fa-location-circle</v-icon>
                    <v-icon small class="pulse-generator-child" style="width: 24px; height: 24px;" v-show="realtimeMapError" color="error" v-on="on">fas fa-exclamation-triangle</v-icon>
                  </div>
                </template>
                <span v-show="!realtimeMapError">Last time updates were received or the last time a transport reported its location</span>
                <span v-show="realtimeMapError">Updates could not be retrieved due to error: {{realtimeMapError}}</span>
              </v-tooltip>
            </div>
            <div v-show="realtimeMapLastUpdatedLabel"><small>Last updated: {{realtimeMapLastUpdatedLabel}}</small></div>
            <v-chip class="ml-2" small v-show="totalLive || totalLive === 0"><small>Total live: {{totalLive}}</small></v-chip>
            <div v-show="realtimeMapError || !initMonitorBusesHandle">
              <v-btn small @click="restartMonitorBuses()">Restart realtime monitor</v-btn>
            </div>
            <div class="ml-2" v-show="currentTrackedBus">
              <v-btn small @click="stopBusTrack()">Stop tracking bus</v-btn>
            </div>
          </div>
        </v-flex>
        <v-flex row wrap xs8 sm2>
          <v-switch v-if="sidebar" @change="sidebarDisplayedHandle" :value="sidebarDisplayed" label="Display sidebar" />
        </v-flex>
      </v-layout>
    </div>
    <v-layout column>
      <v-layout row style="margin: initial">
        <v-flex :md10="sidebarDisplayed" :xs0="sidebarDisplayed" :xs12="!sidebarDisplayed" v-resize="onResize">
          <div :id="containerId" :style="{width: mapDimensions.width, height: mapDimensions.height}"></div>
        </v-flex>
        <v-flex v-if="!fullContainer" :md2="sidebarDisplayed" :xs12="sidebarDisplayed" :xs0="!sidebarDisplayed" :style="{overflowY: 'scroll', height: mapDimensions.height}" v-show="sidebarDisplayed" pa-2>
          <div class="pb-2">
            <v-text-field color="#999" label="From (lat,long)" placeholder="Unknown" :rules="latLngRules" :value="fromLatLng" @change="fromLatLngChangeHandle"></v-text-field>
            <v-text-field color="#999" label="To (lat,long)" placeholder="Unknown" :rules="latLngRules" :value="toLatLng" @change="toLatLngChangeHandle"></v-text-field>
          </div>
          <v-divider />
          <div v-show="routes.length">
            <p>{{routes.length}} Route(s) found</p>
            <v-radio-group v-model="selectedRoute" column>
              <v-radio label="None selected" color="primary" v-if="routes.length > 1" value="none" />
              <v-radio
                v-for="(route, index) in routes"
                :key="index"
                :label="`(${index + 1}) ${route.distanceKm}km - ${generateRouteHint(route)}`"
                color="primary"
                :value="index"
              />
            </v-radio-group>
          </div>
        </v-flex>
      </v-layout>
    </v-layout>
    <v-dialog v-model="modalSiteAlert" max-width="500px">
      <v-card>
        <v-card-title>
          {{modalSiteAlertData ? modalSiteAlertData.type : ''}}
        </v-card-title>
        <v-card-text style="overflow-y: scroll; max-height: 300px;" class="px-2 pb-2">
          {{modalSiteAlertData ? modalSiteAlertData.message : ''}}
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" flat @click="modalSiteAlert = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog hide-overlay v-model="modalBusStopDetails" max-width="500px" v-if="currentMapFeature">
      <v-card>
        <v-card-title class="text-xs-center">
          <h3>Bus Stop: {{currentMapFeature.properties.name}}</h3>
        </v-card-title>
        <v-card-text style="overflow-y: scroll; max-height: 300px;" class="px-2 pb-2">
          <div class="px-2">
            <p>ID: {{currentMapFeature.properties.id}}</p>
            <p>Longitude: {{currentMapFeature.geometry.coordinates[0]}}</p>
            <p>Latitude: {{currentMapFeature.geometry.coordinates[1]}}</p>
          </div>
          <v-layout row wrap>
            <v-flex sm6 py-2 pr-2>
              <v-btn block @click="setMapFeatureAsFrom()">Set as "From"
              </v-btn>
            </v-flex>
            <v-flex sm6 py-2 pl-2>
              <v-btn block class="primary" @click="setMapFeatureAsTo()">Set as "To"
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions class="text-xs-right">
          <v-btn color="primary" flat @click="modalBusStopDetails = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  /*.bus-marker {*/
  /*  background-image: url('/bus-marker.png');*/
  /*  background-size: cover;*/
  /*  width: 20px;*/
  /*  height: 20px;*/
  /*  border-radius: 5px;*/
  /*  !*background-color: #b1b1b1;*!*/
  /*  background-color: #204da2;*/
  /*  cursor: pointer;*/
  /*}*/
  /*.bus-marker-primary {*/
  /*  background-color: #204da2;*/
  /*}*/
  /*.bus-marker-boarded {*/
  /*  background-color: #21c064;*/
  /*}*/
</style>

<script>
import src from './src'
export default src
</script>
