<template>
  <v-autocomplete
    v-bind="childProps"
    v-model="selectedTimezone"
    :items="timezones"
    :loading="childProps.loading || apiInProgress"
    :dense="childProps.dense || true"
    :label="childProps.label || 'Select/search for a timezone'"
    :prepend-inner-icon="childProps.prependInnerIcon || 'fal fa-globe'"
  />
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

<script>
import src from './src'
export default src
</script>
