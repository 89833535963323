import store from '../../store'
import { getPhoneNumberMask, maskPhoneNumber } from '@smarttransit/common-client'

export default {
  name: 'phone-number-field',
  props: {
    value: String,
    defaultPrefix: String,
    errors: Array,
    countryId: String,
    childProps: Object
  },
  data () {
    return {
      currentMask: '###-###-####',
      countryCode: null,
      prefix: null,
      numberValue: '',
      numberValueKey: Date.now(),
      items: store.getters.phoneNumberCountryCodes,
      rules: [
        (data) => {
          const _data = data ? data.trim() : ''
          if (_data.indexOf('+') > -1) {
            return 'Select a mobile number prefix from the select list, no need to include it manually'
          }
          return true
        },
        (data) => {
          const _data = data ? data.trim() : ''
          if ((this.$props.childProps && this.$props.childProps.required ? true : data) && (!/[\d-()\s]+/.test(_data) || /[a-zA-Z]+/.test(_data))) {
            return 'Characters permitted include "-", "(", ")", numbers or spaces'
          }
          return true
        },
        (data) => {
          const _data = data ? data.trim() : ''
          if (_data && !this.$data.prefix) {
            return 'Select country code from dropdown'
          }
          return true
        }
      ]
    }
  },
  mounted () {
    this.$data.prefix = (this.$props.defaultPrefix || '')

    if (this.$props.value || this.$props.defaultPrefix) {
      this.processValue()
    }
    // this.$nextTick(() => {
    //   this.$data.numberValueKey = Date.now()
    // })
  },
  watch: {
    countryId (newVal) {
      if (newVal && !this.$data.prefix && !this.$props.defaultPrefix) {
        const prefixItem = this.$data.items.find(o => o.countryId.toLowerCase() === newVal.toLowerCase())
        if (prefixItem) {
          this.$data.prefix = prefixItem.code
        }
      }
    },
    numberValue: function (newVal) {
      const _prefix = this.$data.prefix || ''

      if (_prefix) {
        if (newVal && newVal.indexOf('+') > -1) {
          this.$data.items.forEach((item) => {
            if (newVal.indexOf(item.code) === 0) {
              newVal = newVal.replace(item.code, '')
            }
          })
        }
      } else {
        if (newVal && newVal.indexOf('+') > -1) {
          this.$data.items.forEach((item) => {
            if (newVal.indexOf(item.code) === 0 && newVal.length > item.code.length) {
              newVal = newVal.replace(item.code, '')
            }
          })
        }
      }

      newVal = this.parseNumberValueToCleanNumber(newVal)

      this.$emit('input', newVal ? (_prefix + newVal).trim() : '')
    },
    prefix: function (newVal) {
      const _prefix = newVal || ''
      // console.log('select val', _prefix + this.$data.numberValue)
      const numberValue = (this.$data.numberValue || '').trim()
      this.$emit('input', numberValue ? (_prefix + this.parseNumberValueToCleanNumber(numberValue)).trim() : '')
    },
    defaultPrefix: function (val) {
      if (val) {
        this.$data.prefix = val
      }
    },
    value: function () {
      this.processValue()
    }
  },
  methods: {
    setPrefix (item) {
      this.$data.prefix = item.code
      this.$data.currentMask = getPhoneNumberMask(this.$data.prefix + (this.$data.numberValue || ''), this.$data.items)

      if (this.$data.numberValue) {
        this.$refs.numberInput.validate()
      } else {
        this.$refs.numberInput.focus()
      }
    },
    processValue () {
      let value = this.$props.value
      let prefix = this.$data.prefix

      if (value && value.indexOf('+') > -1) {
        this.$data.items.forEach((item) => {
          if (value && value.indexOf(item.code) === 0) {
            value = value.replace(item.code, '')
            prefix = item.code
            this.$data.prefix = prefix
          }
        })
      }

      this.$data.currentMask = getPhoneNumberMask(prefix + (value || ''), this.$data.items)
      this.$data.numberValue = maskPhoneNumber(prefix + (value || ''), this.$data.items, true)
    },
    parseNumberValueToCleanNumber (numberValue) {
      numberValue = (numberValue || '').replace(/[^\d+]+/ug, '').trim()

      if (this.$data.prefix) {
        switch (this.$data.prefix) {
          case '+233':
            if (numberValue && numberValue.split('')[0] === '0') {
              numberValue = numberValue.substring(1)
            }
        }
      }

      return numberValue
    }
  }
}
