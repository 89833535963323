import _intersectionBy from 'lodash.intersectionby'
import store from '../store'
import { C_ADD_SITE_ALERT } from './mutation-types'
import { getMapRegions } from '@/services/maps-service'

let regions = []
let regionsCacheTime = 0
let regionTtlHours = 1
const STAFF_USER_TYPES = ['superadmin', 'staff', 'admin', 'investor', 'vendor']

const _getCachedRegions = async function () {
  if (!regions.length || Date.now() - regionsCacheTime > (60000 * 60 * regionTtlHours)) {
    regionsCacheTime = Date.now()
    regions = await getMapRegions()
  }
  return regions
}

const _hasTransportationRole = function (user, transportationRole, transportationOwnerId, transportationOwnerUser = undefined) {
  if (user) {
    const _transportationOwnerUser = transportationOwnerUser || user.transportationOwnerUsers.find((o) => (o.transportationOwnerId + '' === transportationOwnerId + ''))

    if (_transportationOwnerUser) {
      _transportationOwnerUser.roles = Array.isArray(_transportationOwnerUser.roles) ? _transportationOwnerUser.roles : _transportationOwnerUser.roles.split(',')
      return _transportationOwnerUser.roles.find((o) => (o === transportationRole.toUpperCase()))
    }
  }
}

const _isAuthorized = function (user, role) {
  let isAuthorized
  if (user) {
    switch (role) {
      case 'superadmin':
        isAuthorized = _intersectionBy(user.roles, [{ name: 'superadmin' }], 'name').length > 0
        break
      case 'admin':
        isAuthorized = _intersectionBy(user.roles, [{ name: 'superadmin' }, { name: 'admin' }], 'name').length > 0
        break
      case 'staff':
        isAuthorized = _intersectionBy(user.roles, [{ name: 'superadmin' }, { name: 'admin' }, { name: 'staff' }, { name: 'investor' }], 'name').length > 0
        break
      case 'investor':
        isAuthorized = _intersectionBy(user.roles, [{ name: 'superadmin' }, { name: 'investor' }], 'name').length > 0
        break
      case 'transporter':
        isAuthorized = _intersectionBy(user.roles, [{ name: 'superadmin' }, { name: 'investor' }, { name: 'admin' }, { name: 'staff' }, { name: 'transporter' }], 'name').length > 0
        break
      case 'vendor':
        isAuthorized = _intersectionBy(user.roles, [{ name: 'superadmin' }, { name: 'investor' }, { name: 'vendor' }, { name: 'admin' }], 'name').length > 0
        break
      default:
        isAuthorized = user.roles.some((o) => (o.role))
    }
  }
  return isAuthorized
}

function _initBreadcrumbs () {
  const breadcrumbs = []
  if ((this.$router?.currentRoute?.matched || []).length) {
    let foundCurrentRoute
    this.$router.currentRoute.matched.forEach((route, index) => {
      if (!foundCurrentRoute) {
        const routeObj = this.$router.resolve({ name: route.name, params: this.$router.currentRoute.params })
        foundCurrentRoute = (routeObj.route.name === this.$router.currentRoute.name)
        if (routeObj) {
          let accountName = index === 1 && this.$props.selectedAccount ? this.$props.selectedAccount.companyName : (routeObj.route.meta.title || routeObj.route.name)
          accountName = accountName.length > 30 ? accountName.substring(0, 30) + '...' : accountName
          breadcrumbs.push({
            text: accountName,
            disabled: foundCurrentRoute,
            to: { name: routeObj.route.name, params: this.$router.currentRoute.params, query: this.$router.currentRoute.query },
            'active-class': foundCurrentRoute ? 'v-breadcrumbs__item--disabled' : ''
          })
        }
      }
    })
  }
  return breadcrumbs
}

function _addAlert ({ message, type, isModal }) {
  if (type === 'error') {
    console.error('addAlert message', message)
  }

  const parsedMessage = (message && typeof message === 'object') ? (message.error && message.error.message ? message.error.message : (message.message ? message.message : JSON.stringify(message))) : message

  if (isModal) {
    alert(parsedMessage)
  } else {
    store.commit(C_ADD_SITE_ALERT, {
      message: parsedMessage,
      type,
      transient: type === 'success'
    })
  }
}

export const staffUserTypes = STAFF_USER_TYPES
export const hasTransportationRole = _hasTransportationRole
export const getCachedRegions = _getCachedRegions
export const isAuthorized = _isAuthorized
export const initBreadcrumbs = _initBreadcrumbs
export const addAlert = _addAlert
