import { apiRequests } from '../utilities/axios-factory'
import {
  D_GET_COUNTRY_CURRENCY, D_FIND_COUNTRY_CURRENCIES
} from '../utilities/action-types'

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    [D_GET_COUNTRY_CURRENCY] ({ commit, getters }, { id, filter }) {
      return apiRequests.get('/v1/country-currency/' + id + '/', {
        params: { filter },
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    },
    [D_FIND_COUNTRY_CURRENCIES] ({ commit, getters }, filter) {
      return apiRequests.get('/v1/country-currency/', {
        params: { filter },
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    }
  }
}
