import { mapApiRequests } from '../utilities/axios-factory'
import {
  D_FIND_BUS_STOPS,
  D_FIND_BUS_STOPS_TOTAL,
  D_UPDATE_BUS_STOP,
  D_START_BUS_STOP_JOB,
  D_STOP_BUS_STOP_JOB,
  D_GET_BUS_STOP_JOB_STATUS, D_CLEAR_BUS_STOP_JOB_ERROR
} from '../utilities/action-types'

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    [D_FIND_BUS_STOPS] ({ commit, getters }, filter) {
      return mapApiRequests.get('/v1/bus-stop/', {
        params: { filter },
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    },
    [D_FIND_BUS_STOPS_TOTAL] ({ commit, getters }, where) {
      return mapApiRequests.get('/v1/bus-stop/count/', {
        params: { where },
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    },
    [D_GET_BUS_STOP_JOB_STATUS] ({ commit, getters }, routerId) {
      return mapApiRequests.get('/v1/bus-stop/sync-request-status/', {
        params: { routerId },
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    },
    [D_UPDATE_BUS_STOP] ({ commit, getters }, data) {
      return mapApiRequests.patch('/v1/bus-stop/' + data.id + '/', { name: data.name }, {
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    },
    [D_START_BUS_STOP_JOB] ({ commit, getters }, routerId) {
      return mapApiRequests.post('/v1/bus-stop/sync-request/', { routerId }, {
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    },
    [D_STOP_BUS_STOP_JOB] ({ commit, getters }, routerId) {
      return mapApiRequests.delete('/v1/bus-stop/sync-request/', {
        params: { routerId },
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    },
    [D_CLEAR_BUS_STOP_JOB_ERROR] ({ commit, getters }, routerId) {
      return mapApiRequests.delete('/v1/bus-stop/sync-request/error/', {
        params: { routerId },
        headers: { 'Authorization': getters.getTokenId }
      }).then((result) => {
        return result.data
      })
    }
  }
}
