import _upperFirst from 'lodash.upperfirst'
import _orderBy from 'lodash.orderby'
import _debounce from 'lodash.debounce'
import _uniqBy from 'lodash.uniqby'
import _round from 'lodash.round'
import _cloneDeep from 'lodash.clonedeep'
import moment from 'moment-timezone'
import MapboxClient from 'mapbox'
import 'mapbox-gl/dist/mapbox-gl.css'

import maputilities from '@smarttransit/map-utilities'
import { MapboxInstance } from '@smarttransit/common-map'
import { sleep } from '@smarttransit/common'

const BUS_STOP_ZOOM_LEVEL = 13
const CENTER_AT_LONG_LAT_ZOOM = 15

export default {
  name: 'st-map',
  props: {
    widthOffset: Number,
    heightOffset: Number,
    fullContainer: Boolean,
    forceRefreshBusStops: String,
    centerAtLongLat: { type: Array, required: false },
    defaultCenter: Array,
    defaultZoom: undefined,
    trackBusId: String,
    onBusClusterClickCallback: Function,
    onBusMarkerClickCallback: Function,
    onRouteRetrieved: Function,
    onRouteSelected: Function, // function provided will call with param null or object: { routeIdList:string, from:string, to:string, geometry:string[] }
    onSearchResults: Function,
    onRegionChange: Function,
    onBusStopClicked: Function,
    onBoundsChanged: Function,
    onMapLoaded: Function,
    onBusesUpdated: Function,
    onBusesInit: Function,
    containerId: { type: String, required: true, validator: function (value) { return !/[\s]+/.test(value) } },
    routeIdList: String,
    routeIdFrom: String,
    routeIdTo: String,
    routeGeometry: Array,
    routeCoordinates: Array,
    realtimeMapByAccount: [Boolean, Number],
    markerOptions: { type: Object, validator: function (value) { return !value || Object.keys(value).some((o) => ['genericBusImageUrl', 'primaryBusImageUrl', 'successBusImageUrl', 'transparentBusImageUrl', 'startMarkerClass', 'endMarkerClass'].includes(o)) } },
    sidebar: Boolean,
    topbar: Boolean,
    disableSearch: Boolean,
    isModal: Boolean,
    token: Object,
    mapboxAccessToken: { type: String, required: true },
    maptilerKey: { type: String, required: true },
    onSocketInstance: Function,
    api: {
      type: Object,
      required: true,
      validator: function (obj) {
        const objKeys = Object.keys(obj)

        return obj &&
          objKeys.includes('genericApiRequests') &&
          objKeys.includes('getBusStopsForProfileRoute') &&
          objKeys.includes('getTransportationProfileRoute') &&
          objKeys.includes('addAlert')
          // optional api functions
          // getGeoLocationTransportationProfilesByIds
          // getGeoLocationTransportationProfilesWithinRadius
          // getCachedRegions
          // getBusStops
          // getRouteByRouteIdList
          // getRoute
      }
    }
  },
  data () {
    return {
      currentMap: null,
      initMonitorBusesHandle: null,
      realtimeMapError: null,
      realtimeMapLastUpdated: null,
      totalLive: null,
      realtimeMapLastUpdatedLabel: '',
      mapDimensions: { width: '100%', height: '200px' },
      timeLabelRules: [(v) => {
        if (!/^[0-9]{1,2}:[0-9]{2}[aApP]{1}[mM]{1}$/.test(v)) {
          return 'Format (eg.) 12:00am expected'
        }

        const segments = v.split(':')

        if (Number(segments[0]) < 1 || Number(segments[0]) > 12) {
          return '12hr clock expected (eg.) 12:00am instead of 00:00'
        }
        return true
      }],
      activeTextField: null,
      isFromFieldLoading: false,
      isToFieldLoading: false,
      fromFieldSearch: null,
      toFieldSearch: null,
      fromFieldSearchPlaceholder: null,
      toFieldSearchPlaceholder: null,
      fromFieldSelect: null,
      toFieldSelect: null,
      fromLatLng: '',
      toLatLng: '',
      sidebarDisplayed: false,
      selectedRoute: null,
      selectedRegion: null,
      regionsLoading: false,
      displayRegionTime: false,
      routeLatLngInvalid: false,
      modalSiteAlert: false,
      modalSiteAlertData: null,
      modalBusStopDetails: null,
      currentMapFeature: null,
      currentTrackedBus: null,
      regionTimeLabel: '',
      regionTimeProhibitedText: '',
      time: '',
      timeLabel: '',
      busesFromRealtime: [],
      busProfiles: [],
      routes: [], // contains objects of { distanceKm, layerIds, coordinates, routeIdList, from, to }
      fromFieldItems: [],
      toFieldItems: [],
      regions: [],
      latLngRules: [latLngRules.bind(this)],
      // @type {{startMarker: { longitude: number, latitude: number }, endMarker: { longitude: number, latitude: number }, route: Array}[]}
      mapRouteLines: []
    }
  },
  watch: {
    trackBusId (newVal, oldVal) {
      if (newVal !== oldVal) {
        if (newVal) {
          if (!this.trackBusInProgress) {
            this.trackBus(newVal)
          }
        } else {
          this.stopBusTrack()
        }
      }
    },
    forceRefreshBusStops (val) {
      if (val) {
        this.refreshBusStops()
      }
    },
    heightOffset (val) {
      this.onResize({ heightOffset: val })
    },
    routeGeometry (val) {
      processRouteGeometry.call(this, val)
    },
    routeCoordinates (val) {
      processRouteCoordinates.call(this, val)
    },
    mapRouteLines (routeLines) {
      clearTimeout(this.mapRouteLinesDebounceHandle)

      // we want to throttle the mapRouteLines update to prevent too many map renders
      this.mapRouteLinesDebounceHandle = setTimeout(() => {
        this.mapboxInstance.clearAllRouteLayers()
        this.routes = []

        if (routeLines.length) {
          const allMapboxRouteLegs = routeLines.filter((o) => o.category === 'mapboxRouteLegs')

          if (allMapboxRouteLegs.length) {
            this.mapboxInstance.generateRouteLinesFromMapbox(allMapboxRouteLegs.map((o) => o.route))
            const layers = this.mapboxInstance.getRouteLayersBySource(MapboxInstance.LAYER_SOURCES.MAPBOX_LEGS)
            this.addRoutes(allMapboxRouteLegs.map((o) => (o.route)), layers.map((o) => o.id))
          }

          const allRouteCoordinates = routeLines.filter((o) => o.category === 'coordinates')

          if (allRouteCoordinates.length) {
            this.mapboxInstance.generateRouteLines((allRouteCoordinates).map((o) => o.route))
            const layers = this.mapboxInstance.getRouteLayersBySource(MapboxInstance.LAYER_SOURCES.COORDINATES)
            this.currentTrackedBusRouteLayerId = layers.length ? layers[0].id : null
            this.addRoutes(allRouteCoordinates.map((o) => (o.route)), layers.map((o) => o.id))
          }

          const allRouteLegs = routeLines.filter((o) => o.category === 'routeLegs')

          if (allRouteLegs.length) {
            this.mapboxInstance.generateRouteLines((allRouteLegs).map((o) => o.route))
            const layers = this.mapboxInstance.getRouteLayersBySource(MapboxInstance.LAYER_SOURCES.LEGS)
            this.addRoutes(allRouteLegs.map((o) => (o.route)), layers.map((o) => o.id))
          }

          const routeBoundCoords = []

          for (const routeLine of routeLines) {
            if (routeLine.startMarker) {
              this.mapboxInstance.setStartMarker({
                markerClassName: this.$props.markerOptions?.startMarkerClass,
                lng: routeLine.startMarker.longitude,
                lat: routeLine.startMarker.latitude
                // markerOptions: { color: '#ccc' }
              })

              routeBoundCoords.push([routeLine.startMarker.longitude, routeLine.startMarker.latitude])
            }

            if (routeLine.endMarker) {
              this.mapboxInstance.setEndMarker({
                markerClassName: this.$props.markerOptions?.endMarkerClass,
                lng: routeLine.endMarker.longitude,
                lat: routeLine.endMarker.latitude
              })

              routeBoundCoords.push([routeLine.endMarker.longitude, routeLine.endMarker.latitude])
            }
          }

          if (routeBoundCoords.length) {
            setMapBounds.call(this, routeBoundCoords)
          }

          if (routeLines.length === 1) {
            this.selectedRoute = 0
          } else {
            this.selectedRoute = 'none'
          }

          if (typeof this.$props.onRouteRetrieved === 'function') {
            this.$props.onRouteRetrieved(routeLines.map((o) => (o.route)))
          }

          processSelectedRoute.call(this, this.selectedRoute)
        }
      }, 500)
    },
    fromFieldSearch (val) {
      this.fromFieldSearchQuery(val)
    },
    toFieldSearch (val) {
      this.toFieldSearchQuery(val)
    },
    fromFieldItems (val) {
      if (typeof this.$props.onSearchResults === 'function') {
        if (this.activeSearchField === 'fromField') {
          this.$props.onSearchResults(null, (val || []).map((o) => ({ description: o.text, lng: o.value.lng, lat: o.value.lat })))
        }
      }
    },
    toFieldItems (val) {
      if (typeof this.$props.onSearchResults === 'function') {
        if (this.activeSearchField === 'toField') {
          this.$props.onSearchResults(null, (val || []).map((o) => ({ description: o.text, lng: o.value.lng, lat: o.value.lat })))
        }
      }
    },
    selectedRoute (val) {
      processSelectedRoute.call(this, val)
    },
    selectedRegion (val) {
      if (this.socketInstance) {
        this.socketInstance.disconnect()
      }

      this.displayRegionTime = false
      this.regionTime = ''

      if (typeof this.$props.onRegionChange === 'function') {
        this.$props.onRegionChange(val)
      }

      if (val) {
        if (!this.$props.disableSearch) {
          this.syncTimeWithRegionTime(this.time)
          // initSocketInstance.call(this)
        }

        if (this.mapboxInstance.currentMap.getZoom() >= BUS_STOP_ZOOM_LEVEL) {
          this.refreshBusStops()
        }
      }
    },
    timeLabel (val) {
      if (val && this.timeLabelRules[0](val) === true) {
        const timeLabel = val.split(':')
        if (timeLabel[0].length === 1) {
          this.time = moment(val.toLowerCase(), 'h:mma').format('HH:mm')
        } else {
          this.time = moment(val.toLowerCase(), 'hh:mma').format('HH:mm')
        }
        this.syncTimeWithRegionTime(this.time)
      }
    },
    defaultCenter (val) {
      if (val) {
        if (this.mapboxInstance && this.mapboxInstance.currentMap) {
          const zoom = this.mapboxInstance.currentMap.getZoom() >= CENTER_AT_LONG_LAT_ZOOM ? this.mapboxInstance.currentMap.getZoom() : CENTER_AT_LONG_LAT_ZOOM
          this.mapboxInstance.currentMap.setZoom(zoom)
          this.mapboxInstance.currentMap.panTo(val) // flyTo({ center: val, zoom, curve: 1, maxDuration: 0 }) // .panTo(val)
          // this.mapboxInstance.currentMap.setZoom(zoom)
        }
      }
    },
    centerAtLongLat (val) {
      function preparePopup (features) {
        const popupOptions = { closeButton: true, closeOnClick: false }
        const event = { lngLat: { lng: val[0], lat: val[1] } }

        if (this.centerAtLongLatPopup) {
          this.centerAtLongLatPopup.remove()
        }

        if (features?.length) {
          this.centerAtLongLatPopup = setPopup.call(this, features[0], popupOptions, event)
        } else {
          // TODO: perform reverse geocode lookup to determine name for this location
          this.centerAtLongLatPopup = setPopup.call(this, { geometry: { coordinates: val }, properties: { name: `(${val.join(', ')})` } }, popupOptions, event)
        }

        this.centerAtLongLatPopup.on('close', () => {
          this.centerAtLongLatPopup = null
        })

        this.centerAtLongLatPopup.addTo(this.mapboxInstance.currentMap)
      }
      let onMoveEnd = function () {
        this.mapboxInstance.currentMap.off('moveend', onMoveEnd)
        let features = this.mapboxInstance.getBusStopsByCoordinates(val)

        if (features?.length) {
          preparePopup.call(this, features)
        } else {
          window.addEventListener('xBusStopsRefreshed', () => {
            features = this.mapboxInstance.getBusStopsByCoordinates(val)
            preparePopup.call(this, features)
          }, { once: true })
        }
      }

      onMoveEnd = onMoveEnd.bind(this)

      if (val) {
        if (this.mapboxInstance?.currentMap) {
          const zoom = this.mapboxInstance.currentMap.getZoom() >= CENTER_AT_LONG_LAT_ZOOM ? this.mapboxInstance.currentMap.getZoom() : CENTER_AT_LONG_LAT_ZOOM
          this.mapboxInstance.currentMap.on('moveend', onMoveEnd)
          // flyTo({ center: val, zoom, curve: 1 })
          this.mapboxInstance.currentMap.setZoom(zoom)
          this.mapboxInstance.currentMap.panTo(val) // flyTo({ center: val, zoom, curve: 1, maxDuration: 0 })
        }
      } else if (this.centerAtLongLatPopup) {
        this.centerAtLongLatPopup.remove()
      }
    },
    realtimeMapByAccount (newVal, oldVal) {
      if (newVal !== oldVal) {
        if (newVal && this.mapboxInstance?.currentMap) {
          this.initMonitorBusesHandle = true
          this.initMonitorBuses(newVal)
        } else {
          this.cancelMonitorBuses()
        }
      }
    },
    mapboxAccessToken (val) {
      if (val) {
        this.mapboxClient = new MapboxClient(val)
      }
    },
    maptilerKey (val) {
      if (val) {
        initMapAfterMapTiler.call(this)
      }
    }
  },
  computed: {
    currentAccessToken () {
      return this.$props.token
    },
    currentRealtimeMapByAccount () {
      return this.$props.realtimeMapByAccount
    }
  },
  mounted () {
    this.busStops = []
    this.busStopsCacheTimestamp = null
    this.socketInstance = null
    this.retryWithNoWalkLimit = true

    if (this.$props.mapboxAccessToken) {
      this.mapboxClient = new MapboxClient(this.$props.mapboxAccessToken)
    }

    if (this.$props.sidebar) {
      this.sidebarDisplayedHandle(true)
    }

    this.regionsLoading = true
    this.regionTime = ''
    this.setToCurrentTime()
    // this.$nextTick(this.onResize)

    if (this.$props.trackBusId) {
      this.trackBus(this.$props.trackBusId)
    } else {
      this.stopBusTrack()
    }

    if (this.$props.maptilerKey) {
      initMapAfterMapTiler.call(this)
    }
  },
  methods: {
    generateRouteHint (route) {
      if (route.coordinates) {
        return `Manual coordinates`
      } else if (route.legs) {
        return `OTP route`
      } else if (route.routeIdList?.indexOf('mapbox') > -1) {
        return `Mapbox route`
      }

      return ''
    },
    addAlert (message, type) {
      if (this.$props.isModal) {
        this.modalSiteAlertData = { type: type.toUpperCase(), message }
        this.modalSiteAlert = true
      } else {
        this.$props.api.addAlert({ message, type })
      }
    },
    async initMonitorCurrentTrackedBusRoute () {
      if (this.unwatchCurrentTrackedBus) {
        this.unwatchCurrentTrackedBus()
      }

      this.unwatchCurrentTrackedBus = this.$watch(() => (this.currentTrackedBus?.transportationProfileRouteId), async (transportationProfileRouteId, prevProfileRoute) => {
        if (transportationProfileRouteId) {
          this.destroyRouteForBus()

          if (prevProfileRoute) {
            // destroy or try repainting map
            this.restartMapInstance(() => {
              this.restartMonitorBuses()
            })

            this.displayRouteForBus(transportationProfileRouteId)
          } else {
            this.displayRouteForBus(transportationProfileRouteId)
            await this.updateBusMarkers([])
            await this.updateBusMarkers([this.currentTrackedBus])
          }
        } else {
          this.destroyRouteForBus()

          this.restartMapInstance(() => {
            this.restartMonitorBuses()
          })
        }
      })
    },
    restartMonitorBuses () {
      // this.cancelMonitorBuses()
      this.initMonitorBusesHandle = true
      this.initMonitorBuses(this.currentRealtimeMapByAccount)
    },
    async trackBus (profileId) {
      if (this.trackBusInProgress) {
        return
      }

      this.trackBusInProgress = true

      try {
        let bus

        if (this.busesFromRealtime && this.busesFromRealtime.length) {
          bus = this.busesFromRealtime.find(o => o.profileid === profileId)
        }

        if (bus) {
          this.currentTrackedBus = bus
        } else {
          // search for realtime bus by id
          try {
            const buses = await this.$props.api.getGeoLocationTransportationProfilesByIds({ ids: [profileId] })

            if (buses?.length) {
              this.currentTrackedBus = buses[0]
            } else {
              this.currentTrackedBus = null
              this.addAlert('No active vehicle found to track')
            }
          } catch (err) {
            console.log('track bus err', err)
            this.addAlert(err, 'error')
          }
        }

        if (this.currentTrackedBus && this.mapboxInstance) {
          const zoom = this.mapboxInstance.currentMap.getZoom()

          if (zoom < 11) {
            this.mapboxInstance.currentMap.jumpTo({ zoom })
          }
        }
      } finally {
        this.trackBusInProgress = false
      }
    },
    stopBusTrack () {
      if (this.currentTrackedBus) {
        this.currentTrackedBus = null
      }
    },
    async restartMapInstance (customOnLoadFunc) {
      this.mapboxInstance.destroyListeners()
      this.mapboxInstance.currentMap.remove()

      provisionMapInstance.call(this, customOnLoadFunc)
    },
    async displayRouteForBus (transportationProfileRouteId) {
      const results = await Promise.all([
        this.$props.api.getTransportationProfileRoute({ id: transportationProfileRouteId }),
        this.$props.api.getBusStopsForProfileRoute({ id: transportationProfileRouteId })
      ])

      const currentTrackedBusRoute = results[0]

      let coordinatesFromPolyline = currentTrackedBusRoute.profileRouteMetadata.routeGeometry.reduce((coordinates, polyline) => {
        coordinates = coordinates.concat(maputilities.convertPolylineToCoordinates(polyline))
        return coordinates
      }, [])

      this.mapRouteLines = this.mapRouteLines.filter((o) => o.category !== 'coordinates')

      this.mapRouteLines.push({
        startMarker: {
          longitude: coordinatesFromPolyline[0][0],
          latitude: coordinatesFromPolyline[0][1]
        },
        endMarker: {
          longitude: coordinatesFromPolyline[coordinatesFromPolyline.length - 1][0],
          latitude: coordinatesFromPolyline[coordinatesFromPolyline.length - 1][1]
        },
        route: coordinatesFromPolyline,
        category: 'coordinates'
      })

      this.currentTrackedBusStops = results[1]
    },
    destroyRouteForBus () {
      this.currentTrackedBusStops = []

      if (this.currentTrackedBusRouteLayerId) {
        this.mapboxInstance.removeLayer(this.currentTrackedBusRouteLayerId)
        this.mapboxInstance.startEndMarkers?.start?.remove && this.mapboxInstance.startEndMarkers.start.remove()
        this.mapboxInstance.startEndMarkers?.end?.remove && this.mapboxInstance.startEndMarkers.end.remove()
      }

      this.currentTrackedBusRouteLayerId = null
    },
    async initMonitorBuses (accountId, recursive) {
      if (this.initMonitorInProgress || !this.initMonitorBusesHandle) {
        return
      }

      try {
        this.initMonitorInProgress = true
        this.realtimeMapError = null
        let total, buses

        if (this.$props.api?.getGeoLocationTransportationProfilesWithinRadius) {
          const results = await this.$props.api.getGeoLocationTransportationProfilesWithinRadius({
            long: this.mapboxInstance.currentMap.getCenter().lng,
            lat: this.mapboxInstance.currentMap.getCenter().lat,
            searchradius: this.mapboxInstance.getMapRadius(),
            transportationOwnerId: accountId !== true ? accountId : undefined,
            includeTotal: true
          })

          total = results.total
          buses = results.data
        }

        this.busesFromRealtime = buses

        if (buses?.length) {
          buses = _orderBy(buses, ['timestamp'], ['desc'])
        }

        this.realtimeMapLastUpdated = buses?.length ? buses[0].timestamp : Date.now()
        this.totalLive = total

        // check if tracking a bus and adjust the map
        if (this.currentTrackedBus) {
          const currentTrackedBus = buses.find(o => o.profileid === this.currentTrackedBus.profileid)

          if (currentTrackedBus) {
            currentTrackedBus.tracked = true
            this.currentTrackedBus = currentTrackedBus
          }

          const currentTrackedBusCoords = [this.currentTrackedBus.long, this.currentTrackedBus.lat]
          const radiusFactorInKm = maputilities.approximateMetersFromPixels(30, this.currentTrackedBus.lat, this.mapboxInstance.currentMap.getZoom()) / 1000

          if (!this.mapboxInstance.isWithinMapBounds(currentTrackedBusCoords, radiusFactorInKm)) {
            this.mapboxInstance.currentMap.panTo(currentTrackedBusCoords)
          }
          // if bus stops available, call function to provide: "next stop/stop 'X'"
          this.announceBusStopOnRouteIfAvailable()
        }

        if (typeof this.$props.onBusesUpdated === 'function') {
          this.$props.onBusesUpdated(buses)
        }

        if (this.mapboxInstance.onMapLoadCompleted) {
          this.updateBusMarkers(buses)
        }

        if (!recursive && typeof this.$props.onBusesInit === 'function') {
          this.$props.onBusesInit(buses)
        }

        this.initMonitorInProgress = false

        if (this.initMonitorBusesHandle) {
          await sleep(1000)
          await this.initMonitorBuses(accountId, true)
        }
      } catch (err) {
        console.log('realtime map err', err)
        this.initMonitorInProgress = false
        this.realtimeMapError = `${err?.error ? err.error.message : (err?.message ? err.message : 'Unknown error')}`
        this.cancelMonitorBuses()
        this.addAlert(err, 'error')
      }
    },
    async announceBusStopOnRouteIfAvailable () {
      if (this.currentTrackedBus) {
        const currentBusStopIdUpdated = this.currentBusStopId !== this.currentTrackedBus.currentBusStopId ? this.currentTrackedBus.currentBusStopId : null
        const nextBusStopIdUpdated = this.nextBusStopId !== this.currentTrackedBus.nextBusStopId ? this.currentTrackedBus.nextBusStopId : null
        const distanceToNextStop = this.currentTrackedBus.distanceToNextStop
        this.currentBusStopId = this.currentTrackedBus.currentBusStopId
        this.nextBusStopId = this.currentTrackedBus.nextBusStopId

        if (currentBusStopIdUpdated || nextBusStopIdUpdated) {
          const currentBusStop = currentBusStopIdUpdated ? this.currentTrackedBusStops.find((o) => o.id === currentBusStopIdUpdated) : null
          const nextBusStop = nextBusStopIdUpdated ? this.currentTrackedBusStops.find((o) => o.id === nextBusStopIdUpdated) : null
          this.displayBusStopPopup(currentBusStop, nextBusStop, distanceToNextStop)
        }
      }
    },
    displayBusStopPopup (currentBusStop, nextBusStop, distanceToNextStop) {
      if (this.busStopPopupInstance) {
        clearTimeout(this.busStopPopupInstanceHandle)
        this.busStopPopupInstance.remove()
        this.busStopPopupInstance = null
      }

      const feature = { geometry: { coordinates: [this.currentTrackedBus.long, this.currentTrackedBus.lat] }, properties: { name: '' } }

      this.busStopPopupInstance = setPopup.call(this, feature, {
        offset: 12,
        html: `
<h4>${this.currentTrackedBus.licenseplate}</h4>
${currentBusStop ? `<div><strong>Current stop:</strong> ${currentBusStop.name}</div>` : ''}
${nextBusStop ? `<div><strong>Next stop:</strong> ${nextBusStop.name}</div>` : ''}
${nextBusStop ? `<div><strong><em>in ${distanceToNextStop}km</em></strong></div>` : ''}
<div><small>(${feature.geometry.coordinates.join(', ')})</small></div>
`
      })

      this.busStopPopupInstance.addTo(this.mapboxInstance.currentMap)

      this.busStopPopupInstanceHandle = setTimeout(() => {
        if (this.busStopPopupInstance) {
          this.busStopPopupInstance.remove()
          this.busStopPopupInstance = null
        }
      }, 5000)
    },
    async cancelMonitorBuses () {
      this.initMonitorBusesHandle = false
    },
    initRealtimeMapLastUpdated () {
      clearInterval(this.realtimeMapLastUpdatedHandle)

      this.realtimeMapLastUpdatedHandle = setInterval(() => {
        if (!this.realtimeMapError && this.realtimeMapLastUpdated) {
          this.realtimeMapLastUpdatedLabel = moment(this.realtimeMapLastUpdated).fromNow()
        } else {
          this.realtimeMapLastUpdatedLabel = ''
        }
      }, 2000)
    },
    async updateBusMarkers (buses) {
      const layerId = 'busLayer'
      const imageId = 'generic-bus'

      if (!this.mapboxInstance.currentMap.hasImage(imageId) && this.$props.markerOptions?.genericBusImageUrl) {
        await this.mapboxInstance.addLayerImage(this.$props.markerOptions.genericBusImageUrl, imageId)
      }

      if (buses?.length) {
        const sourceId = 'busSource'
        // for (let i = 0; i < 20; i++) {
        //   const cloned = _.cloneDeep(buses[0])
        //   cloned.profileid += i
        //   buses.push(cloned)
        // }
        buses = buses.map(o => {
          o.lng = o.long
          o.total = buses.length
          return o
        })

        this.mapboxInstance.updateImageLayer({
          pointsData: buses,
          layerId,
          sourceId,
          imageId,
          imageSize: 0.04,
          clusterMaxZoom: 10,
          onMouseEnterCallback: (e, features) => {
            if (this.busPopupInstance) {
              this.busPopupInstance.remove()
              this.busPopupInstance = null
            }

            let currentBusStop, nextBusStop

            if (this.currentTrackedBusStops) {
              const currentBusStopIdUpdated = this.currentBusStopId !== this.currentTrackedBus.currentBusStopId ? this.currentTrackedBus.currentBusStopId : null
              currentBusStop = currentBusStopIdUpdated ? this.currentTrackedBusStops.find((o) => o.id === this.currentTrackedBus.currentBusStopId) : null
              nextBusStop = this.nextBusStopId ? this.currentTrackedBusStops.find((o) => o.id === this.nextBusStopId) : null
            }

            const feature = features.length && features[0]

            if (feature) {
              this.busPopupInstance = setPopup.call(this, feature, {
                offset: 12,
                html: `
<h4>${feature.properties.licenseplate}</h4>
<div>Last checked in: ${moment(feature.properties.timestamp).fromNow()}</div>
${currentBusStop ? `<div><strong>Current stop:</strong> ${currentBusStop.name}</div>` : ''}
${nextBusStop ? `<div><strong>Next stop:</strong> ${nextBusStop.name}</div>` : ''}
${nextBusStop ? `<div><strong><em>in ${this.currentTrackedBus.distanceToNextStop}km</em></strong></div>` : ''}
<div><small>(${feature.geometry.coordinates.join(', ')})</small></div>
`
              }, e)

              this.busPopupInstance.addTo(this.mapboxInstance.currentMap)
            }
          },
          onMouseLeaveCallback: () => {
            if (this.busPopupInstance) {
              this.busPopupInstance.remove()
              this.busPopupInstance = null
            }
          },
          onClickCallback: (e, features) => {
            if (typeof this.$props.onBusMarkerClickCallback === 'function') {
              this.$props.onBusMarkerClickCallback(e, features[0])
            }
          },
          onClusterMouseEnterCallback: (e, features) => {
            if (this.busPopupInstance) {
              this.busPopupInstance.remove()
              this.busPopupInstance = null
            }
            const feature = features.length && features[0]

            if (feature) {
              this.busPopupInstance = setPopup.call(this, features[0], {
                offset: 12,
                html: `
<h4>Group of ${feature.properties.point_count} bus(es)</h4>
<p><small>(${feature.geometry.coordinates.join(', ')})</small></p>
`
              }, e)

              this.busPopupInstance.addTo(this.mapboxInstance.currentMap)
            }
          },
          onClusterMouseLeaveCallback: () => {
            if (this.busPopupInstance) {
              this.busPopupInstance.remove()
              this.busPopupInstance = null
            }
          },
          onClusterClickCallback: (e, features) => {
            if (typeof this.$props.onBusClusterClickCallback === 'function') {
              this.$props.onBusClusterClickCallback(e, features[0])
            }
          }
        })
      } else if (this.mapboxInstance.currentMap.getLayer(layerId)) {
        this.mapboxInstance.removeImageLayer(layerId)
      }
    },
    fromFieldSearchQuery: _debounce(function (val) {
      val && val !== (this.fromFieldSelect?.description || '') && this.geocodeQuery(val, 'fromField')
    }, 800),
    toFieldSearchQuery: _debounce(function (val) {
      val && val !== (this.toFieldSelect?.description || '') && this.geocodeQuery(val, 'toField')
    }, 800),
    fromLatLngChangeHandle (val) {
      this.fromLatLng = val

      if (!this.mapboxInstance.startEndMarkers.start || formatLngLatForView({ lng: this.mapboxInstance.startEndMarkers.start.getLngLat().lng, lat: this.mapboxInstance.startEndMarkers.start.getLngLat().lat }) !== val) {
        this.fromFieldSearchPlaceholder = val
      }

      this.mapboxInstance.setStartMarker({
        markerClassName: this.$props.markerOptions?.startMarkerClass,
        lng: val.split(',')[1].replace(/[()]+/, ''),
        lat: val.split(',')[0].replace(/[()]+/, '')
      }, () => this.routeQuery(this.fromLatLng, this.toLatLng))
    },
    toLatLngChangeHandle (val) {
      this.toLatLng = val

      if (!this.mapboxInstance.startEndMarkers.end || formatLngLatForView({ lng: this.mapboxInstance.startEndMarkers.end.getLngLat().lng, lat: this.mapboxInstance.startEndMarkers.end.getLngLat().lat }) !== val) {
        this.toFieldSearchPlaceholder = val
      }

      this.mapboxInstance.setEndMarker({
        markerClassName: this.$props.markerOptions?.endMarkerClass,
        lng: val.split(',')[1].replace(/[()]+/, ''),
        lat: val.split(',')[0].replace(/[()]+/, '')
      }, () => this.routeQuery(this.fromLatLng, this.toLatLng))
    },
    setMapFeatureAsFrom () {
      if (this.currentMapFeature) {
        const val = {
          markerClassName: this.$props.markerOptions?.startMarkerClass,
          lng: this.currentMapFeature.geometry.coordinates[0],
          lat: this.currentMapFeature.geometry.coordinates[1]
        }

        if (!this.mapboxInstance.startEndMarkers.start || formatLngLatForView({ lng: this.mapboxInstance.startEndMarkers.start.getLngLat().lng, lat: this.mapboxInstance.startEndMarkers.start.getLngLat().lat }) !== formatLngLatForView(val)) {
          this.fromFieldSearchPlaceholder = this.currentMapFeature.properties.name
        }

        this.fromLatLng = formatLngLatForView({ lng: val.lng, lat: val.lat })
        this.mapboxInstance.setStartMarker(val, () => this.routeQuery(this.fromLatLng, this.toLatLng))
        this.modalBusStopDetails = false
      } else {
        this.addAlert('No map feature found', 'error')
      }
    },
    setMapFeatureAsTo () {
      if (this.currentMapFeature) {
        const val = {
          markerClassName: this.$props.markerOptions?.endMarkerClass,
          lng: this.currentMapFeature.geometry.coordinates[0],
          lat: this.currentMapFeature.geometry.coordinates[1]
        }

        if (!this.mapboxInstance.startEndMarkers.end || formatLngLatForView({ lng: this.mapboxInstance.startEndMarkers.end.getLngLat().lng, lat: this.mapboxInstance.startEndMarkers.end.getLngLat().lat }) !== formatLngLatForView(val)) {
          this.toFieldSearchPlaceholder = this.currentMapFeature.properties.name
        }

        this.toLatLng = formatLngLatForView({ lng: val.lng, lat: val.lat })
        this.mapboxInstance.setEndMarker(val, () => this.routeQuery(this.fromLatLng, this.toLatLng))
        this.modalBusStopDetails = false
      } else {
        this.addAlert('No map feature found', 'error')
      }
    },
    fromFieldSelectChangeHandle (val) {
      if (val) {
        this.fromLatLng = formatLngLatForView({ lng: val.lng, lat: val.lat })
        this.mapboxInstance.setStartMarker({ markerClassName: this.$props.markerOptions?.startMarkerClass, ...val }, () => this.routeQuery(this.fromLatLng, this.toLatLng))
      }

      this.fromFieldSelect = val
    },
    toFieldSelectChangeHandle (val) {
      if (val) {
        this.toLatLng = formatLngLatForView({ lng: val.lng, lat: val.lat })
        this.mapboxInstance.setEndMarker({ markerClassName: this.$props.markerOptions?.endMarkerClass, ...val }, () => this.routeQuery(this.fromLatLng, this.toLatLng))
      }

      this.toFieldSelect = val
    },
    sidebarDisplayedHandle (val) {
      this.sidebarDisplayed = val

      if (window) {
        if (this.triggerResizeHandle) {
          clearTimeout(this.triggerResizeHandle)
        }

        this.triggerResizeHandle = setTimeout(() => {
          window.dispatchEvent(new Event('resize'))
          this.triggerResizeHandle = null
        }, 500)
      }
    },
    refreshBusStops () {
      if (this.selectedRegion) {
        return this.$props.api.getBusStops({
          routerId: this.selectedRegion.routerId,
          longitude: this.mapboxInstance.currentMap.getCenter().lng,
          latitude: this.mapboxInstance.currentMap.getCenter().lat,
          searchradius: this.mapboxInstance.getMapRadius()
        }).then((stops) => {
          this.busStops = stops

          if (this.mapboxInstance.currentMap.loaded()) {
            this.mapboxInstance.updateBusStops(this.busStops, BUS_STOP_ZOOM_LEVEL)
            setTimeout(() => {
              window.dispatchEvent(new Event('xBusStopsRefreshed'))
              console.log('sent xBusStopsRefreshed')
            }, 1000)
          }
        })
      }
    },
    onResize ({ heightOffset, widthOffset } = {}) {
      if (this.$props.fullContainer) {
        this.mapDimensions.height = '100%'
        this.mapDimensions.width = '100%'
      } else {
        const topbarElement = this.$refs[`topbar${this.$props.containerId}`]
        const topbarElementHeight = topbarElement?.clientHeight || 0
        const height = window.innerHeight - topbarElementHeight

        if ((heightOffset || heightOffset === 0) || this.$props.heightOffset) {
          this.mapDimensions.height = (height - (heightOffset || this.$props.heightOffset || 0) - 22) + 'px'
        } else {
          this.mapDimensions.height = height + 'px'
        }
        if ((widthOffset || widthOffset === 0) || this.$props.widthOffset) {
          this.mapDimensions.width = (window.innerWidth - (widthOffset || this.$props.widthOffset || 0)) + 'px'
        } else {
          this.mapDimensions.width = '100%'
        }
      }
    },
    mapboxRouteQuery (startLngLat, endLngLat) {
      return this.mapboxClient.getDirections([
        { latitude: startLngLat.lat, longitude: startLngLat.lng },
        { latitude: endLngLat.lat, longitude: endLngLat.lng }
      ], {
        profile: 'driving',
        alternatives: true,
        overview: 'full',
        geometry: 'geojson'
      }).catch((err) => {
        this.addAlert(`Could not get mapbox route: ${typeof err === 'object' ? JSON.stringify(err) : err}`, 'error')
        // alert('Could not get mapbox route: ' + err.message)
      })
    },
    onRouteResult (routeResults) {
      console.log('routeResults', routeResults)
      this.routes = []
      let itineraries = []

      if ((routeResults?.plan?.itineraries?.length || 0)) {
        // get routes that at least involve a bus
        const busItineraries = routeResults.plan.itineraries.filter((itn) => {
          return itn.legs.find((o) => (o.mode === 'BUS'))
        })

        if (busItineraries.length) {
          itineraries = busItineraries
        } else { // no routes involving bus, so just use the walk route
          itineraries = routeResults.plan.itineraries
        }
      }

      itineraries.forEach((itinerary) => {
        let coordinates = []

        itinerary.legs.forEach((leg) => {
          coordinates = coordinates.concat(leg.legGeometry ? maputilities.convertPolylineToCoordinates(leg.legGeometry.points) : [])
        })

        if (coordinates.length) {
          itinerary.distanceKm = maputilities.distanceTravelled(coordinates)
        }
      })

      const startEndMarkers = this.mapboxInstance.startEndMarkers

      setMapBounds.call(
        this,
        [
          [startEndMarkers.start.getLngLat().lng, startEndMarkers.start.getLngLat().lat],
          [startEndMarkers.end.getLngLat().lng, startEndMarkers.end.getLngLat().lat]
        ]
      )

      if (itineraries.length) {
        this.mapRouteLines = this.mapRouteLines.filter((o) => o.category !== 'routeLegs')

        for (const itinerary of itineraries) {
          this.mapRouteLines.push({
            route: itinerary,
            category: 'routeLegs'
          })
        }

        this.retryWithNoWalkLimit = true
      } else if (this.retryWithNoWalkLimit) {
        console.log('RETRY_WITH_NO_WALK_LIMIT')
        this.routeQuery(this.fromLatLng, this.toLatLng, null)
        this.retryWithNoWalkLimit = false
      } else {
        // alert('No routes found for origin and destination, switching to mapbox route service')
        if (startEndMarkers.start && startEndMarkers.end) {
          if (process.env.NODE_ENV !== 'production') {
            this.mapboxRouteQuery(startEndMarkers.start.getLngLat(), startEndMarkers.end.getLngLat())
              .then((directionResults) => {
                console.log('directionResults', directionResults)
                if (directionResults && directionResults.entity.routes.length) {
                  // this.mapboxInstance.clearAllRouteLayers()
                  directionResults.entity.routes = _orderBy(directionResults.entity.routes, ['distance'], ['asc'])

                  directionResults.entity.routes.forEach((route) => {
                    let coordinates = route.geometry.coordinates
                    coordinates = coordinates.map(o => ([o[1], o[0]]))
                    route.legGeometry = maputilities.convertCoordinatesToPolyline(coordinates)
                    route.routeIdList = maputilities.generateRouteIdList(route)
                  })

                  this.mapRouteLines = this.mapRouteLines.filter((o) => o.category !== 'mapboxRouteLegs')

                  for (const itinerary of directionResults.entity.routes) {
                    this.mapRouteLines.push({
                      route: itinerary,
                      category: 'mapboxRouteLegs'
                    })
                  }
                  // const layerIds = this.mapboxInstance.generateRouteLinesFromMapbox(directionResults.entity.routes)
                  // this.addRoutes(directionResults.entity.routes, layerIds)
                } else {
                  alert('No route found')
                }
              })
          } else {
            alert('No route found')
          }
        } else {
          alert('No created marker pairs found')
        }

        this.retryWithNoWalkLimit = true
      }
    },
    routeQuery (fromLatLng, toLatLng, maxWalkDistance) {
      if (this.$props.api?.searchRoutes) {
        const date = moment() // moment.tz(moment().toDate().toISOString(), moment.tz.guess())
        maxWalkDistance = typeof maxWalkDistance === 'undefined' ? 500 : maxWalkDistance
        console.log('sending route query: ', fromLatLng, toLatLng)

        this.$props.api.searchRoutes({
          routeId: this.selectedRegion.routerId,
          routeParams: {
            fromPlace: fromLatLng,
            toPlace: toLatLng,
            date: date.tz(this.selectedRegion.timezone).format('YYYYMMDD'),
            time: this.regionTime,
            maxWalkDistance
          }
        }).then((result) => {
          this.onRouteResult(result)
        }).catch((err) => {
          if (err) {
            return this.addAlert(`Error in route query: ${typeof err === 'object' ? err.message : err}`, 'error')
          }
        })
      }
    },
    /**
     * @param {{description:string,lng:number,lat:number,distance?:number}[]} geocodeResults
     * @param {boolean} [isAlternateGeocoder=false]
     */
    onSearchResult (geocodeResults, isAlternateGeocoder) {
      console.log('geocodeResults', geocodeResults, 'isAlternateGeocoder', isAlternateGeocoder)
      if (this.activeSearchField === 'fromField') {
        if (isAlternateGeocoder) {
          this.fromFieldItems = this.fromFieldItems.filter((o) => (!o.value.alternateGeocoder))
        } else {
          this.fromFieldItems = this.fromFieldItems.filter((o) => (o.value.alternateGeocoder))
        }

        this.fromFieldItems = (this.fromFieldItems || []).concat(geocodeResults.map(o => {
          return { text: o.description + (isAlternateGeocoder ? '' : ` (${_round(o.distance / 1000, 2)}km)`), value: o, type: isAlternateGeocoder ? 'alternateGeocoder' : 'geocoder' }
        }))

        this.fromFieldItems = _uniqBy(this.fromFieldItems, (o) => (o.alternateGeocoder ? o.placeId : `${o.value.lng}${o.value.lat}`))
      } else if (this.activeSearchField === 'toField') {
        if (isAlternateGeocoder) {
          this.toFieldItems = this.toFieldItems.filter((o) => (!o.value.alternateGeocoder))
        } else {
          this.toFieldItems = this.toFieldItems.filter((o) => (o.value.alternateGeocoder))
        }

        this.toFieldItems = (this.toFieldItems || []).concat(geocodeResults.map(o => {
          return { text: o.description + (isAlternateGeocoder ? '' : ` (${_round(o.distance / 1000, 2)}km)`), value: o, type: isAlternateGeocoder ? 'alternateGeocoder' : 'geocoder' }
        }))

        this.toFieldItems = _uniqBy(this.toFieldItems, (o) => (o.alternateGeocoder ? o.placeId : `${o.value.lng}${o.value.lat}`))
      }
    },
    async geocodeQuery (val, fieldType) {
      if (fieldType === 'fromField') {
        this.isFromFieldLoading = true
      } else if (fieldType === 'toField') {
        this.isToFieldLoading = true
      }

      this.activeSearchField = fieldType

      if (!this.currentCoarseLocation) {
        this.currentCoarseLocation = await this.$props.api.getCoarseGeolocation()
      }

      console.log('emitting geocode request', val)

      this.$props.api.geocodeRequest(val, this.currentCoarseLocation && [this.currentCoarseLocation.longitude, this.currentCoarseLocation.latitude]).then((result) => {
        this.onSearchResult(result?.length ? _orderBy(result.map((o) => ({ description: o.name, lng: o.longlat[0], lat: o.longlat[1], distance: o.distance })), ['distance'], ['asc']) : [])
      }).catch((err) => {
        this.addAlert(`Error in geocode query: ${typeof err === 'object' ? JSON.stringify(err) : err}`, 'error')
      }).finally(() => {
        if (fieldType === 'fromField') {
          this.isFromFieldLoading = false
        } else if (fieldType === 'toField') {
          this.isToFieldLoading = false
        }
      })

      this.alternateGeocodeQuery(val, fieldType)
    },
    async alternateGeocodeQuery (address, fieldType) {
      if (!process.env.VUE_APP_ENV || process.env.VUE_APP_ENV === 'local' || process.env.VUE_APP_ENV === 'staging') {
        try {
          if (fieldType === 'fromField') {
            this.isFromFieldLoading = true
          } else if (fieldType === 'toField') {
            this.isToFieldLoading = true
          }

          if (!this.currentCoarseLocation) {
            this.currentCoarseLocation = await this.$props.api.getCoarseGeolocation()
          }

          const geocodeResults = await this.$props.api.googleGeocodeRequest({ address, location: this.currentCoarseLocation })

          if (geocodeResults) {
            const parsedResults = geocodeResults.map((geocodeResult) => {
              return {
                description: geocodeResult.address_components.length && geocodeResult.address_components.find((o) => (o.types.find((a) => geocodeResult.types.includes(a)))) ? geocodeResult.address_components.find((o) => (o.types.find((a) => geocodeResult.types.includes(a)))).short_name : geocodeResult.formatted_address,
                alternateGeocoder: true,
                placeId: geocodeResult.place_id,
                lng: geocodeResult.geometry.location.lng(),
                lat: geocodeResult.geometry.location.lat()
              }
            })

            this.onSearchResult(parsedResults, 'isAlternateGeocoder')
          }
        } catch (error) {
          console.log('alternate geocode error', error)
          this.addAlert(error.message, 'error')
        } finally {
          this.isToFieldLoading = false
        }
      }
    },
    addRoutes (routes, layerIds) {
      (routes || []).forEach((route, index) => {
        // console.log('route ' + index, JSON.stringify(route))
        if (Array.isArray(route)) {
          route = {
            routeIdList: maputilities.generateRouteIdList(route),
            coordinates: route,
            distanceKm: route.length ? _round(maputilities.distanceTravelled(route), 2) : 0
          }

          routes[index] = route
        } else {
          route.distanceKm = route.distanceKm || _round((route.distance || route.walkDistance) / 1000, 2)
        }

        if (layerIds && layerIds[index]) {
          route.layerIds = layerIds[index]
        }
      })

      this.routes = routes ? this.routes.concat(routes) : (this.routes || [])
    },
    syncTimeWithRegionTime (time) {
      this.regionTimeProhibitedText = ''
      this.displayRegionTime = false

      if (this.selectedRegion && time) {
        const date = moment.tz(`${moment().format('YYYY-MM-DD')} ${time}`, 'YYYY-MM-DD HH:mm', moment.tz.guess())
        const regionDateTime = date.tz(this.selectedRegion.timezone)
        this.regionTime = regionDateTime.format('HH:mm')
        this.regionTimeLabel = regionDateTime.format('hh:mma')
        this.displayRegionTime = this.selectedRegion.timezone !== moment.tz.guess()
        const startsHourMinute = this.selectedRegion.transitServiceStarts.split(':')
        const endsHourMinute = this.selectedRegion.transitServiceEnds.split(':')
        const regionStartDate = regionDateTime.clone().set({ hour: Number(startsHourMinute[0]), minute: Number(startsHourMinute[1]) })
        const regionEndDate = regionDateTime.clone().set({ hour: Number(endsHourMinute[0]), minute: Number(endsHourMinute[1]) })

        if (!regionDateTime.isBetween(regionStartDate, regionEndDate)) {
          this.regionTimeProhibitedText = `, buses only run between ${regionStartDate.format('hh:mma')} - ${regionEndDate.format('hh:mma')}`
        }
      }
    },
    setToCurrentTime () {
      this.timeLabel = moment().format('hh:mma')
    }
  },
  beforeDestroy: function () {
    this.componentWillBeDestroyed = true

    if (this.socketInstance) {
      this.socketInstance.disconnect()
    }

    if (this.mapboxInstance) {
      this.mapboxInstance.destroyListeners()
      if (this.mapboxInstance.currentMap) {
        this.mapboxInstance.currentMap.remove()
      }
    }

    clearInterval(this.realtimeMapLastUpdatedHandle)

    if (this.unwatchCurrentTrackedBus) {
      this.unwatchCurrentTrackedBus()
    }

    this.stopBusTrack()
    this.cancelMonitorBuses()
  }
}

function processRouteCoordinates (val) {
  if (val) {
    this.mapRouteLines = this.mapRouteLines.filter((o) => o.category !== 'coordinates')

    this.mapRouteLines.push({
      route: val,
      category: 'coordinates'
    })
  }
}

function processRouteGeometry (val) {
  if (val) {
    const coordinatesFromRouteGeometryProps = val.reduce((coordinates, polyline) => {
      coordinates = coordinates.concat(maputilities.convertPolylineToCoordinates(polyline))
      return coordinates
    }, [])

    this.mapRouteLines = this.mapRouteLines.filter((o) => o.category !== 'coordinates')

    this.mapRouteLines.push({
      startMarker: {
        longitude: coordinatesFromRouteGeometryProps[0][0],
        latitude: coordinatesFromRouteGeometryProps[0][1]
      },
      endMarker: {
        longitude: coordinatesFromRouteGeometryProps[coordinatesFromRouteGeometryProps.length - 1][0],
        latitude: coordinatesFromRouteGeometryProps[coordinatesFromRouteGeometryProps.length - 1][1]
      },
      route: coordinatesFromRouteGeometryProps,
      category: 'coordinates'
    })
  }
}

function processSelectedRoute (val) {
  if (val === 'none') {
    this.routes.forEach((route) => {
      if (route?.layerIds) {
        if (Array.isArray(route.layerIds)) {
          route.layerIds.forEach((id) => {
            this.mapboxInstance.showLayer(id)
          })
        } else {
          this.mapboxInstance.showLayer(route.layerIds)
        }
      }
    })

    if (typeof this.$props.onRouteSelected === 'function') {
      this.$props.onRouteSelected()
    }
  } else if (typeof val === 'number') {
    this.routes.forEach((route) => {
      if (route?.layerIds) {
        if (Array.isArray(route.layerIds)) {
          route.layerIds.forEach((id) => {
            this.mapboxInstance.hideLayer(id)
          })
        } else {
          this.mapboxInstance.hideLayer(route.layerIds)
        }
      }
    })

    const route = this.routes[val]

    console.log('route selected', route, { routes: this.routes })

    if (!route) {
      return
    }

    if (route?.layerIds) {
      if (Array.isArray(route.layerIds)) {
        route.layerIds.forEach((id) => {
          this.mapboxInstance.showLayer(id)
        })
      } else {
        this.mapboxInstance.showLayer(route.layerIds)
      }
    }

    if (typeof this.$props.onRouteSelected === 'function') {
      let routeGeometry = null

      if (route?.legGeometry) {
        routeGeometry = [route.legGeometry?.points || route.legGeometry]
      } else if (route?.legs) {
        routeGeometry = route.legs.map(o => {
          return o.legGeometry?.points || o.legGeometry
        })
      } else if (route?.routeIdList?.indexOf('mapbox') > -1) {
        let coordinates = route.geometry.coordinates
        coordinates = coordinates.map(o => ([o[1], o[0]]))
        routeGeometry = [maputilities.convertCoordinatesToPolyline(coordinates)]
      } else if (Array.isArray(route?.coordinates) && route.coordinates.length) {
        routeGeometry = [maputilities.convertCoordinatesToPolyline(route.coordinates)]
      }

      let from = this.fromFieldSelect?.description ? this.fromFieldSelect.description : this.fromFieldSearchPlaceholder || ''
      let to = this.toFieldSelect?.description ? this.toFieldSelect.description : this.toFieldSearchPlaceholder || ''

      if (!from && !to) {
        const label = route?.geometry || route?.legs ? maputilities.generateRouteIdListStartDestinationLabel(route.geometry ? route : route.legs) : ''
        from = label ? label.split(` ${maputilities.ROUTE_LABEL_CONNECTOR} `)[0] : ''
        to = label ? label.split(` ${maputilities.ROUTE_LABEL_CONNECTOR} `)[1] : ''
      }

      // needs to generate object: { routeIdList:string, from:string, to:string, geometry:string[] }
      this.$props.onRouteSelected({
        routeIdList: route.routeIdList,
        routeGeometry,
        from,
        to
      })
    }
  }
}

/**
 * Cause the map to fit any route line, etc. within the map's visible boundaries
 * @param {[number|string, number|string][]} coordinateSets
 */
function setMapBounds (coordinateSets) {
  const bounds = this.mapboxInstance.currentMap.getBounds()

  coordinateSets.forEach((coordinates) => {
    bounds.extend(coordinates)
  })

  this.mapboxInstance.currentMap.fitBounds(bounds, { padding: 20 })
}

function initMapAfterMapTiler () {
  this.$props.api.genericApiRequests('https://api.maptiler.com/maps/6862ebb6-86aa-4870-b141-8b76ed9273c8/style.json', {
    params: { key: this.$props.maptilerKey }
  }).then((tiles) => {
    this.mapTiles = tiles

    if (!this.componentWillBeDestroyed) {
      provisionMapInstance.call(this)
      // const bounds = this.mapboxInstance.currentMap.getBounds()
      // features.forEach(function(feature) {
      //   bounds.extend(feature.geometry.coordinates)
      // })
      // this.mapboxInstance.currentMap.fitBounds(bounds, { padding: 10 })
    }
  }).catch((err) => {
    this.addAlert(`Could not retrieve map tiles: ${err?.error ? err.error.message : (err?.message ? err.message : JSON.stringify(err))}`, 'error')
  })
}

// function initSocketInstance () {
//   if (this.$props.onSocketInstance) {
//     if (this.socketInstance) {
//       this.socketInstance.disconnect()
//     }
//
//     this.socketInstance = this.$props.onSocketInstance({ routerId: this.selectedRegion?.routerId, onSearchResult: this.onSearchResult, onRouteResult: this.onRouteResult })
//   }
// }

function onMapMove () {
  if (this.mapboxInstance?.currentMap) {
    if (this.onMapMoveHandle) {
      clearTimeout(this.onMapMoveHandle)
    }

    this.onMapMoveHandle = setTimeout(() => {
      if (this.mapboxInstance.currentMap.getZoom() >= BUS_STOP_ZOOM_LEVEL) {
        this.refreshBusStops()
      }
    }, 1000)

    if (typeof this.$props.onBoundsChanged === 'function') {
      this.$props.onBoundsChanged({
        lng: this.mapboxInstance.currentMap.getCenter().lng,
        lat: this.mapboxInstance.currentMap.getCenter().lat,
        radius: this.mapboxInstance.getMapRadius(),
        bounds: this.mapboxInstance.currentMap.getBounds()
      })
    }
  }
}

function onMapMoveDestroy () {
  this.mapboxInstance.currentMap.off('moveend', onMapMove)
}

function onBusStopHover (feature, e) {
  const popup = setPopup.call(this, feature, { closeButton: false, closeOnClick: false }, e)

  popup.addTo(this.mapboxInstance.currentMap)

  const callbackId = this.mapboxInstance.addBusStopMouseLeaveCallback(() => {
    popup.remove()

    if (this.mapboxInstance.onBusStopMouseLeaveCallbacks[callbackId]) {
      delete this.mapboxInstance.onBusStopMouseLeaveCallbacks[callbackId]
    }
  })
}

function provisionMapInstance (customOnLoadFunc) {
  const options = { style: this.mapTiles.data, container: this.$props.containerId, accessToken: this.$props.mapboxAccessToken }

  if (this.$props.defaultZoom || this.$props.defaultZoom === 0) {
    options.zoom = this.$props.defaultZoom
  }

  if (this.$props.centerAtLongLat) {
    options.center = this.$props.centerAtLongLat
    options.zoom = 15
  } else if (this.$props.defaultCenter) {
    options.center = this.$props.defaultCenter
  }

  this.mapboxInstance = new MapboxInstance(options, () => {
    onMapLoad.call(this)

    if (customOnLoadFunc) {
      customOnLoadFunc()
    }
  })
}

/**
 *
 * @param {object} feature - expects {geometry: {coordinates: []}, properties: {name}}
 * @param {object} popupOptions={} - options expected for the function mapbox.createPopup, in addition to custom properties: html, coordinateOffset
 * @param {object} e=undefined - expects {lngLat: {lng, lat}}
 */
function setPopup (feature, popupOptions = {}, e = undefined) {
  const coordinates = feature.geometry.coordinates

  if (e) {
    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += (e.lngLat.lng > coordinates[0] ? 360 : -360)
    }
  }

  popupOptions = Object.assign({ className: 'st-map--popup' }, popupOptions)
  const popup = this.mapboxInstance.createPopup(popupOptions)

  popup.setLngLat(coordinates)
    .setHTML(popupOptions.html
      ? popupOptions.html
      : `
      <h4>${feature.properties.name}</h4>
      <p><small>(${feature.geometry.coordinates.join(', ')})</small></p>
   `)

  return popup
}

function onBusStopClick (feature, e) {
  this.currentMapFeature = _cloneDeep(feature) // { feature, event: e }
  if (this.$props.onBusStopClicked && typeof this.$props.onBusStopClicked === 'function') {
    this.$props.onBusStopClicked(feature, e)
  } else {
    this.modalBusStopDetails = true
  }
}

async function generateRoutesFromRouteIdList (routeIdList) {
  if (this.selectedRegion) {
    try {
      const routeIdListObj = JSON.parse(routeIdList)
      let route = await this.$props.api.getRouteByRouteIdList(routeIdList)

      if (!route) {
        route = await this.$props.api.getRoute({
          routerId: routeIdListObj.routerId,
          params: {
            fromPlace: `(${routeIdListObj.from.lat},${routeIdListObj.from.lon})`,
            toPlace: `(${routeIdListObj.to.lat},${routeIdListObj.to.lon})`
          }
        })

        if (route) {
          route = route.plan.itineraries
          this.addAlert(`No route found for routeIdList (defaulting to route/s found): ${this.$props.routeIdList}`, 'error')
        }
      }

      if (route) {
        const firstLeg = routeIdListObj.from
        const lastLeg = routeIdListObj.to
        let startRouteLabel, endRouteLabel

        if (this.$props.routeIdList === routeIdList && this.$props.routeIdFrom && this.$props.routeIdTo) {
          startRouteLabel = this.$props.routeIdFrom
          endRouteLabel = this.$props.routeIdTo
        } else {
          const routeLabels = maputilities.generateRouteIdListStartDestinationLabel(routeIdListObj.mapbox ? route : route.legs)
          const labelResults = routeLabels.split(` ${maputilities.ROUTE_LABEL_CONNECTOR} `)
          startRouteLabel = labelResults[0]
          endRouteLabel = labelResults[1]
        }

        this.fromFieldSearchPlaceholder = startRouteLabel
        this.toFieldSearchPlaceholder = endRouteLabel
        this.mapboxInstance.setStartMarker({ markerClassName: this.$props.markerOptions?.startMarkerClass, lng: firstLeg.lon, lat: firstLeg.lat })
        this.mapboxInstance.setEndMarker({ markerClassName: this.$props.markerOptions?.endMarkerClass, lng: lastLeg.lon, lat: lastLeg.lat })
        this.fromLatLng = formatLngLatForView({ lng: firstLeg.lon, lat: firstLeg.lat })
        this.toLatLng = formatLngLatForView({ lng: lastLeg.lon, lat: lastLeg.lat })
        // $this.fromLatLngChangeHandle(formatLngLatForView({ lng: firstLeg.lon, lat: firstLeg.lat }))
        // $this.toLatLngChangeHandle(formatLngLatForView({ lng: lastLeg.lon, lat: lastLeg.lat }))
        const routeItineraries = Array.isArray(route) ? route : [route]

        if (routeIdListObj.mapbox) {
          this.mapRouteLines = this.mapRouteLines.filter((o) => o.category !== 'mapboxRouteLegs')

          routeItineraries.forEach((route) => {
            this.mapRouteLines.push({ route, category: 'mapboxRouteLegs' })
          })
          // layerIds = this.mapboxInstance.generateRouteLinesFromMapbox(routeItineraries)
        } else {
          routeItineraries.forEach((itinerary) => {
            let coordinates = []

            itinerary.legs.forEach((leg) => {
              coordinates = coordinates.concat(leg.legGeometry ? maputilities.convertPolylineToCoordinates(leg.legGeometry.points) : [])
            })

            if (coordinates.length) {
              itinerary.distanceKm = maputilities.distanceTravelled(coordinates)
            }
          })

          this.mapRouteLines = this.mapRouteLines.filter((o) => o.category !== 'routeLegs')

          routeItineraries.forEach((route) => {
            this.mapRouteLines.push({ route, category: 'routeLegs' })
          })
        }

        // this.addRoutes(routeItineraries, layerIds)
      } else {
        this.addAlert(`No route found for routeIdList: ${this.$props.routeIdList}`, 'error')
      }
    } catch (err) {
      console.log('ERR', err)
      this.addAlert(`Error occurred getting route: ${err?.error?.message ? err.error.message : (err?.message ? err.message : JSON.stringify(err))}`, 'error')
    }
  } else {
    this.addAlert('No region found for routeIdList', 'error')
  }
}

function onMapLoad () {
  this.mapboxInstance.currentMap.on('moveend', onMapMove.bind(this))
  this.mapboxInstance.onDestroy(onMapMoveDestroy.bind(this))
  this.mapboxInstance.addBusStopMouseHoverCallback(onBusStopHover.bind(this))
  this.mapboxInstance.addClickBusStopCallback(onBusStopClick.bind(this))
  this.initMonitorBusesHandle = true
  // this.mapboxInstance.currentMap.fitBounds(this.mapboxInstance.currentMap.getBounds(), { padding: 200 })

  const apiFunc = async () => {
    let regionsFound

    if (this.$props.api.getCachedRegions) {
      this.regionsLoading = true
      regionsFound = await this.$props.api.getCachedRegions()
      this.regionsLoading = false
    }

    this.regions = []

    if (regionsFound) {
      regionsFound.forEach((region) => {
        this.regions.push({ text: _upperFirst(region.routerId), value: region })
      })
    }

    this.selectedRegion = this.regions.length ? this.regions[0].value : null

    if (this.$props.routeIdList) {
      const routeIdListObject = JSON.parse(this.$props.routeIdList)
      let regionFromRouteIdList = routeIdListObject.routerId

      if (regionFromRouteIdList) {
        regionFromRouteIdList = this.regions.filter(o => o.value.routerId === regionFromRouteIdList)
        regionFromRouteIdList = regionFromRouteIdList.length ? regionFromRouteIdList[0].value : null
        this.selectedRegion = regionFromRouteIdList
      }

      generateRoutesFromRouteIdList.call(this, this.$props.routeIdList)
    }

    if (this.currentRealtimeMapByAccount) {
      // this.cancelMonitorBuses()
      this.initMonitorBusesHandle = true
      this.initMonitorBuses(this.currentRealtimeMapByAccount)
      this.initRealtimeMapLastUpdated()
    }

    this.initMonitorCurrentTrackedBusRoute()

    if (this.currentTrackedBus?.transportationProfileRouteId) {
      this.destroyRouteForBus()
      this.displayRouteForBus(this.currentTrackedBus.transportationProfileRouteId)
    }

    if (this.$props.routeGeometry) {
      processRouteGeometry.call(this, this.$props.routeGeometry)
    }

    if (this.$props.routeCoordinates) {
      processRouteCoordinates.call(this, this.$props.routeCoordinates)
    }

    if (typeof this.$props.onMapLoaded === 'function') {
      this.$props.onMapLoaded(this.mapboxInstance)
    }
  }

  apiFunc().catch((err) => {
    this.addAlert(err, 'error')
  }).finally(() => {
    this.onResize()
  })
}

function latLngRules (data) {
  let result = true
  if (data && !/^\(-?[0-9]+\.?[0-9]*,-?[0-9]+\.?[0-9]*\)$/.test(data)) {
    result = 'Coordinates must be in the form (xxx,xxx)'
  }
  this.routeLatLngInvalid = result && typeof result === 'string'
  return result
}

function formatLngLatForView ({ lng, lat }) {
  return `(${lat},${lng})`
}
