<template>
  <v-app>
    <v-navigation-drawer
      class="site-menu"
      persistent
      :mini-variant="miniVariant"
      v-model="drawer"
      enable-resize-watcher
      fixed
      app
      v-if="displayNavigationAndMenu"
    >
      <v-list>
        <v-list-tile class="site-menu__header" @click.stop="" :to="{ name: 'home' }">
          <v-list-tile-action>
            <img src="@/assets/logo-badge.png" style="height: 35px; border-radius: 5px;" alt=""/>
          </v-list-tile-action>
          <v-list-tile-content>
            <img src="@/assets/logo.png" style="width: 212px" alt="SmartTransit"/>
          </v-list-tile-content>
        </v-list-tile>
        <div v-for="(item, i) in items" :key="i">
          <v-list-tile
            v-if="!item.subItems || !item.subItems.length"
            value="true"
            @click.stop=""
            :to="{ name: item.routeName }"
          >
            <v-list-tile-action>
              <v-icon v-html="item.icon"></v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title v-text="item.title"></v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-group
              v-if="item.subItems && item.subItems.length"
              :prepend-icon="item.icon"
              :value="item.isDefaultOpen"
              :title="'isDefaultOpen' + item.isDefaultOpen"
          >
            <template v-slot:activator>
              <v-list-tile :title="item.title">
                <v-list-tile-title v-text="item.title"></v-list-tile-title>
              </v-list-tile>
            </template>
            <v-list-tile
                v-for="(subItem, a) in item.subItems"
                :key="a"
                value="true"
                @click="forceRootViewRefresh"
                :to="{ name: subItem.routeName, params: subItem.params }"
            >
              <v-list-tile-action class="pl-4">
                <v-icon v-html="subItem.icon"></v-icon>
              </v-list-tile-action>
              <v-list-tile-content class="pl-2">
                <v-list-tile-title v-text="subItem.title"></v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list-group>
        </div>
      </v-list>
    </v-navigation-drawer>
    <v-toolbar
      app
      absolute
      scroll-off-screen
      scroll-target="#appContent"
      v-if="displayNavigationAndMenu"
      v-resize="onResize"
      id="appToolbar"
      ref="appToolbar"
    >
      <v-toolbar-side-icon class="hidden-lg-and-up" @click.stop="drawer = !drawer"></v-toolbar-side-icon>
      <v-btn class="hidden-md-and-down" :title="miniVariant ? 'Expand side navigation' : 'Minimize side navigation'" icon @click.stop="miniVariant = !miniVariant">
        <v-icon v-html="miniVariant ? 'fal fa-expand-alt' : 'fal fa-compress-alt'"></v-icon>
      </v-btn>
      <v-btn class="hidden-lg-and-up mr-2" icon>
        <img src="@/assets/logo-badge.png" style="height: 35px; border-radius: 5px" alt=""/>
      </v-btn>
      <v-divider vertical class="hidden-md-and-down mr-2"></v-divider>
<!--      <v-autocomplete-->
<!--          @change="transportationOwnersChange"-->
<!--          :loading="isTransportationOwnersLoading"-->
<!--          :items="transportationOwnersList"-->
<!--          :search-input.sync="transportationOwnersSearch"-->
<!--          hide-no-data-->
<!--          hide-details-->
<!--          allow-overflow label="Transportation Owner Account"-->
<!--          placeholder="Select a transportation owner"-->
<!--      ></v-autocomplete>-->
      <v-btn icon title="Currently Selected Account" v-if="selectedAccount" @click="$router.push({ name: 'transportation-account', params: { accountId: selectedAccount.id } })">
        <v-icon>fal fa-users</v-icon>
      </v-btn>
      <v-toolbar-title title="Currently Selected Account" v-if="selectedAccount" v-text="selectedAccount.companyName" @click="$router.push({ name: 'transportation-account', params: { accountId: selectedAccount.id } })"></v-toolbar-title>
      <v-btn icon small v-if="selectedAccount" @click="clearSelectedAccount">
        <v-icon>fal fa-times</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <div>
        <site-alerts-tray></site-alerts-tray>
      </div>
    </v-toolbar>
    <div id="appContent"
         class="scroll-y">
      <v-content>
        <router-view :key="rootViewKey" :force-root-view-refresh="forceRootViewRefresh" :signed-in-user="signedInUser" />
      </v-content>
    </div>
    <v-footer :fixed="fixed" app class="px-2" id="appFooter" ref="appFooter">
      <span>&copy; SmartTransit 2018 - {{new Date().getFullYear()}}</span>
    </v-footer>
  </v-app>
</template>

<script>
import src from './src'
export default src
</script>
