import moment from 'moment'
import { apiRequests } from '../utilities/axios-factory'
import { C_SET_TOKEN, C_INVALIDATE_TOKEN, C_RENEW_TOKEN, C_SET_SIGNED_IN_USER, C_SET_SIGNED_USER_PROMISE } from '../utilities/mutation-types'
import {
  D_SIGN_IN,
  D_RENEW_TOKEN,
  D_SIGN_OUT,
  D_GET_SIGNED_IN_USER,
  D_FORGOT_PASSWORD_REQUEST,
  D_FORGOT_PASSWORD_SUBMIT
} from '../utilities/action-types'

const LOCAL_STORAGE_PREFIX = 'st_'

function getToken (state) {
  if (!state.accessToken) {
    const accessToken = localStorage.getItem(LOCAL_STORAGE_PREFIX + 'accessToken')
    if (accessToken) {
      state.accessToken = JSON.parse(accessToken)
    }
  }
  return state.accessToken
}

function getTokenId (state) {
  const accessToken = getToken(state)
  if (accessToken) {
    return accessToken.id
  }
}

export default {
  state: {
    user: null,
    userPromise: null,
    accessToken: null
  },
  getters: {
    getTokenId,
    getToken
  },
  mutations: {
    [C_SET_TOKEN] (state, { newAccessToken }) {
      if (newAccessToken) {
        const expireDate = moment.utc(newAccessToken.created).add(newAccessToken.ttl, 'seconds')
        newAccessToken.expires = expireDate.valueOf()
        newAccessToken.renews = expireDate.subtract(Math.ceil(newAccessToken.ttl / 2), 'seconds').valueOf()
      }
      state.accessToken = newAccessToken
      localStorage.setItem(LOCAL_STORAGE_PREFIX + 'accessToken', newAccessToken ? JSON.stringify(newAccessToken) : '')
    },
    [C_SET_SIGNED_IN_USER] (state, { newUser }) {
      state.user = newUser
    },
    [C_SET_SIGNED_USER_PROMISE] (state, { promise }) {
      state.userPromise = promise
    },
    [C_RENEW_TOKEN] (state, { newAccessToken }) {
      state.accessToken.created = newAccessToken.created
      state.accessToken.ttl = newAccessToken.ttl
      const expireDate = moment.utc(newAccessToken.created).add(newAccessToken.ttl, 'seconds')
      state.accessToken.expires = expireDate.valueOf()
      state.accessToken.renews = expireDate.subtract(Math.ceil(newAccessToken.ttl / 2), 'seconds').valueOf()
      localStorage.setItem(LOCAL_STORAGE_PREFIX + 'accessToken', JSON.stringify(state.accessToken))
    },
    [C_INVALIDATE_TOKEN] (state) {
      state.accessToken = null
      localStorage.setItem(LOCAL_STORAGE_PREFIX + 'accessToken', '')
      localStorage.removeItem(LOCAL_STORAGE_PREFIX + 'accessToken')
    }
  },
  actions: {
    [D_RENEW_TOKEN] ({ commit, getters }, token) {
      token = token || getters.getTokenId
      return apiRequests.put('/v1/st-user/token/renew/', {
        token
      }, {
        headers: { 'Authorization': getters.getTokenId }
      }).then((newAccessToken) => {
        commit(C_RENEW_TOKEN, { newAccessToken: newAccessToken.data })
      })
    },
    [D_SIGN_OUT] ({ commit, getters }) {
      return apiRequests.post('/v1/st-user/logout/', null, {
        headers: { 'Authorization': getters.getTokenId }
      }).then(() => {
        commit(C_INVALIDATE_TOKEN)
        commit(C_SET_SIGNED_IN_USER, { newUser: null })
      })
    },
    [D_SIGN_IN] ({ commit }, { email, phone, password }) {
      const userPromise = apiRequests.post('/v1/transportation-owner-user/login-token/', {
        email,
        phone,
        password
      })
      userPromise.then((tokenBundle) => {
        commit(C_SET_TOKEN, { newAccessToken: tokenBundle.data.token })
        commit(C_SET_SIGNED_IN_USER, { newUser: tokenBundle.data.stUser })
      }).finally(() => {
        commit(C_SET_SIGNED_USER_PROMISE, { promise: null })
      })
      commit(C_SET_SIGNED_USER_PROMISE, { promise: userPromise })
      return userPromise
    },
    [D_GET_SIGNED_IN_USER] ({ commit, getters }) {
      const token = getters.getToken
      if (token) {
        const userPromise = apiRequests.get(`/v1/st-user/${token.userId}/`, {
          headers: { 'Authorization': token.id },
          params: { filter: { include: [ { transportationOwnerUsers: 'transportationOwner' }, 'roles' ] } }
        })
        userPromise.then((user) => {
          commit(C_SET_SIGNED_IN_USER, { newUser: user ? user.data : null })
        }).finally(() => {
          commit(C_SET_SIGNED_USER_PROMISE, { promise: null })
        })
        commit(C_SET_SIGNED_USER_PROMISE, { promise: userPromise })
        return userPromise
      } else {
        const err = new Error('No token available to make request for signed in user')
        err.status = 401
        return Promise.reject(err)
      }
    },
    [D_FORGOT_PASSWORD_REQUEST] ({ commit, getters }, { email, phone }) {
      return apiRequests.put(`/v1/st-user/password-reset-request/`, { email, phone }).then(result => result.data)
    },
    [D_FORGOT_PASSWORD_SUBMIT] ({ commit, getters }, { verificationToken, password, confirmedPassword }) {
      return apiRequests.put(`/v1/st-user/password-reset/`, { verificationToken, password, confirmedPassword }).then(result => result.data)
    }
  }
}
