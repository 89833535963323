import { apiRequests } from '../utilities/axios-factory'
import store from '../store'
import { generateKeywordsQueryForOr } from '@smarttransit/common-client'

function getAgent ({ id, include, includeArchived }) {
  include = include || [{ stUser: 'roles' }, 'countryCurrency', 'agentMobileMoneyAccounts', 'agentProfileDocuments']

  return apiRequests.get(`/v1/${includeArchived ? 'raw-' : ''}agent-profile/` + id + '/join/', {
    params: { filter: { include } },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function getAgentSecureUrl ({ documentId }) {
  return apiRequests.get(`/v1/agent-profile-document/` + documentId + '/secure-url/', {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data && result.data.url
  })
}

function getAgentTemplate () {
  return {
    recoveryContact: '',
    customConversionRatePercent: null,
    countryId: 'GHA',
    idDocumentFront: null,
    idDocumentBack: null,
    idDocumentExpiryDate: null,
    stUser: { firstName: '', lastName: '', email: '', phone: '', timezone: '' }
  }
}

// At times we need this bc/ of "order by" restrictions in json sql when referring to join record columns
function findAgentsByUser ({ keywords, countryId, archived, invited, page, limit, sort, order }) {
  const offset = page === 1 ? 0 : (page * limit) - limit

  const filter = {
    limit,
    offset,
    where: {},
    join: { relation: archived ? 'rawAgentProfile' : 'agentProfile' },
    include: [archived ? 'rawAgentProfile' : 'agentProfile', 'roles']
  }

  if (sort) {
    filter.order = `${sort} ${order.toLowerCase() === 'desc' ? 'DESC' : 'ASC'}`
  }

  if (keywords) {
    filter.where.or = generateKeywordsQueryForOr(keywords, ['firstName', 'lastName', 'email', 'phone'])
  }

  if (invited) {
    filter.where.verificationToken = { neq: null }
  }

  if (countryId) {
    filter.join.scope = { where: { countryId } }
  }

  if (archived) {
    if (!filter.join.scope) {
      filter.join.scope = { where: {} }
    }

    filter.join.scope.where.dateArchived = { neq: null }
  }

  if (!Object.keys(filter.where).length) {
    delete filter.where
  }

  return apiRequests.get(`/v1/${archived ? 'raw-' : ''}st-user/join/`, {
    params: { filter },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data.map((user) => {
      // we want to mimic an agentProfile object bc/ requester expects this
      return {
        ...(user.rawAgentProfile || user.agentProfile),
        stUser: user
      }
    })
  })
}

function findAgents ({ keywords, countryId, archived, invited, page, limit, sort, order }) {
  const offset = page === 1 ? 0 : (page * limit) - limit

  const filter = {
    limit,
    offset,
    where: {},
    include: { stUser: 'roles' }
  }

  if (sort) {
    if (['firstName', 'lastName', 'email', 'phone'].includes(sort)) {
      return findAgentsByUser({ keywords, countryId, archived, invited, page, limit, sort, order })
    }

    filter.order = `${sort} ${order.toLowerCase() === 'desc' ? 'DESC' : 'ASC'}`
  }

  if (keywords) {
    filter.join = {
      relation: 'stUser',
      scope: { where: { or: generateKeywordsQueryForOr(keywords, ['firstName', 'lastName', 'email', 'phone']) } }
    }
  }

  if (invited) {
    if (!filter.join) {
      filter.join = {
        relation: 'stUser',
        scope: { where: {} }
      }
    }

    filter.join.scope.where.verificationToken = { neq: null }
  }

  if (countryId) {
    filter.where.countryId = countryId
  }

  if (archived) {
    filter.where.dateArchived = { neq: null }
  } else if (archived !== null) {
    filter.where.dateArchived = null
  }

  if (!Object.keys(filter.where).length) {
    delete filter.where
  }

  return apiRequests.get(`/v1/${archived ? 'raw-' : ''}agent-profile/join/`, {
    params: { filter },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function findTotalAgents ({ keywords, countryId, archived, invited }) {
  let where = {}
  let join

  if (keywords) {
    join = {
      relation: 'stUser',
      scope: { where: { or: generateKeywordsQueryForOr(keywords, ['firstName', 'lastName', 'email', 'phone']) } }
    }
  }

  if (invited) {
    if (!join) {
      join = {
        relation: 'stUser',
        scope: { where: {} }
      }
    }

    join.scope.where.verificationToken = { neq: null }
  }

  if (countryId) {
    where.countryId = countryId
  }

  if (archived) {
    where.dateArchived = { neq: null }
  } else if (archived !== null) {
    where.dateArchived = null
  }

  if (!Object.keys(where).length) {
    where = undefined
  }

  return apiRequests.get(`/v1/${archived ? 'raw-' : ''}agent-profile/count/join/`, {
    params: { where, join },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function archiveAgent (id) {
  return apiRequests.delete('/v1/agent-profile/' + id + '/', {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function unArchiveAgent (id) {
  return apiRequests.patch('/v1/agent-profile/' + id + '/unarchived/', {}, {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function toggleAgentStaff ({ id, asAgentStaff }) {
  return apiRequests.patch('/v1/agent-profile/' + id + '/role/', { asAgentStaff }, {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function registerAgentWithExistingUser ({
  stUserId,
  email,
  phoneNumber,
  idDocumentExpiryDate,
  idDocumentFrontFile,
  idDocumentBackFile,
  recoveryContact,
  customConversionRatePercent,
  countryId,
  timezone
}) {
  const bodyFormData = new FormData()

  if (stUserId) {
    bodyFormData.append('stUserId', stUserId)
  }

  if (email) {
    bodyFormData.append('email', email)
  }

  bodyFormData.append('phoneNumber', phoneNumber)
  bodyFormData.append('timezone', timezone)
  bodyFormData.append('idDocumentExpiryDate', idDocumentExpiryDate)

  if (recoveryContact) {
    bodyFormData.append('recoveryContact', recoveryContact)
  }

  if (customConversionRatePercent) {
    bodyFormData.append('customConversionRatePercent', customConversionRatePercent)
  }

  bodyFormData.append('countryId', countryId)
  bodyFormData.append('idDocumentFront', idDocumentFrontFile)
  bodyFormData.append('idDocumentBack', idDocumentBackFile)

  return apiRequests.post('/v1/agent-profile/existing-user/', bodyFormData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': store.getters.getTokenId
    }
  }).then((result) => {
    return result.data
  })
}

function registerAgent ({
  email,
  phoneNumber,
  firstName,
  lastName,
  idDocumentExpiryDate,
  idDocumentFrontFile,
  idDocumentBackFile,
  recoveryContact,
  customConversionRatePercent,
  countryId,
  timezone
}) {
  const bodyFormData = new FormData()

  if (email) {
    bodyFormData.append('email', email)
  }

  bodyFormData.append('phoneNumber', phoneNumber)
  bodyFormData.append('firstName', firstName)
  bodyFormData.append('lastName', lastName)
  bodyFormData.append('timezone', timezone)
  bodyFormData.append('idDocumentExpiryDate', idDocumentExpiryDate)

  if (recoveryContact) {
    bodyFormData.append('recoveryContact', recoveryContact)
  }

  if (customConversionRatePercent) {
    bodyFormData.append('customConversionRatePercent', customConversionRatePercent)
  }

  bodyFormData.append('countryId', countryId)
  bodyFormData.append('idDocumentFront', idDocumentFrontFile)
  bodyFormData.append('idDocumentBack', idDocumentBackFile)

  return apiRequests.post('/v1/agent-profile/', bodyFormData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': store.getters.getTokenId
    }
  }).then((result) => {
    return result.data
  })
}

function updateAgent ({
  id,
  email,
  phoneNumber,
  firstName,
  lastName,
  idDocumentExpiryDate,
  idDocumentFrontFile,
  idDocumentBackFile,
  deleteIdDocumentFront,
  deleteIdDocumentBack,
  recoveryContact,
  customConversionRatePercent,
  countryId,
  timezone
}) {
  const bodyFormData = new FormData()

  if (email) {
    bodyFormData.append('email', email)
  }

  if (phoneNumber) {
    bodyFormData.append('phoneNumber', phoneNumber)
  }

  if (firstName) {
    bodyFormData.append('firstName', firstName)
  }

  if (lastName) {
    bodyFormData.append('lastName', lastName)
  }

  if (idDocumentExpiryDate) {
    bodyFormData.append('idDocumentExpiryDate', idDocumentExpiryDate)
  }

  if (recoveryContact) {
    bodyFormData.append('recoveryContact', recoveryContact)
  }

  if (customConversionRatePercent) {
    bodyFormData.append('customConversionRatePercent', customConversionRatePercent)
  }

  if (countryId) {
    bodyFormData.append('countryId', countryId)
  }

  if (timezone) {
    bodyFormData.append('timezone', timezone)
  }

  if (idDocumentFrontFile) {
    bodyFormData.append('idDocumentFront', idDocumentFrontFile)
  }

  if (idDocumentBackFile) {
    bodyFormData.append('idDocumentBack', idDocumentBackFile)
  }

  if (deleteIdDocumentFront === true || deleteIdDocumentFront === false) {
    bodyFormData.append('deleteIdDocumentFront', Boolean(deleteIdDocumentFront))
  }

  if (deleteIdDocumentBack === true || deleteIdDocumentBack === false) {
    bodyFormData.append('deleteIdDocumentBack', Boolean(deleteIdDocumentBack))
  }

  return apiRequests.patch('/v1/agent-profile/' + id + '/', bodyFormData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': store.getters.getTokenId
    }
  }).then((result) => {
    return result.data
  })
}

function resendAgentInvite (id) {
  return apiRequests.patch('/v1/agent-profile/' + id + '/resend-invite/', {}, {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function cancelAgentInvite (id) {
  return apiRequests.delete('/v1/agent-profile/' + id + '/resend-invite/', {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

export {
  getAgent,
  findAgents,
  findTotalAgents,
  archiveAgent,
  unArchiveAgent,
  registerAgent,
  registerAgentWithExistingUser,
  updateAgent,
  resendAgentInvite,
  cancelAgentInvite,
  getAgentTemplate,
  getAgentSecureUrl,
  toggleAgentStaff
}
