export default {
  name: 'st-uploader',
  props: {
    childProps: Object,
    value: Object,
    name: {
      required: true,
      type: String
    },
    disabled: Boolean,
    timeout: Number,
    uploadLabel: String,
    mimeTypes: Array,
    onClearUpload: Function, // return false to cancel the clear operation
    multiple: Boolean,
    imageHeight: [Number, String],
    imageWidth: [Number, String],
    mimeTypesGroup: {
      validator: (value) => {
        if (value) {
          return ['images', 'all', 'video', 'audio', 'docs', 'text'].includes(value)
        }

        return true
      }
    }
  },
  data () {
    return {
      documentsData: { documentsToUpload: [], uploadedDocuments: [] }, // {documentsToUpload: [{isImage:boolean,blob:string,file:Object,name:string}, ...], uploadedDocuments: [{filename:string,url:string,size:number,type:string}, ''']}
      isEditable: true,
      refName: '',
      parsedTimeout: 3 * 60000,
      acceptedMimeTypes: MIME_TYPE_GROUPS.IMAGES,
      idDocumentsToUpload: [],
      isMultiple: false
    }
  },
  mounted () {
    if (this.$props.value) {
      this.documentsData = this.$props.value
    }

    this.isEditable = !this.$props.disabled

    this.refName = `${this.$props.name}Ref`

    if (typeof this.$props.timeout === 'number') {
      this.parsedTimeout = this.$props.timeout
    }

    if (this.$props.mimeTypesGroup) {
      this.setMimeTypeGroup(this.$props.mimeTypesGroup)
    }

    if (this.$props.mimeTypes && this.$props.mimeTypes.length) {
      this.acceptedMimeTypes = this.$props.mimeTypes
    }

    if (typeof this.$props.multiple === 'boolean') {
      this.isMultiple = this.$props.multiple
    }
  },
  computed: {
    acceptedMimeTypesString () {
      return this.acceptedMimeTypes.join(',')
    }
  },
  watch: {
    disabled (val) {
      this.isEditable = !val
    },
    documentsData: function (newVal) {
      this.$emit('input', newVal)
    },
    value (val) {
      this.documentsData = val
    },
    name (val) {
      this.refName = val
    },
    timeout (val) {
      this.parsedTimeout = val
    },
    mimeTypesGroup (val) {
      this.setMimeTypeGroup(val)
    },
    mimeTypes (val) {
      this.acceptedMimeTypes = val
    },
    multiple (val) {
      this.isMultiple = val
    },
    idDocumentsToUpload (newFiles) {
      const documentsToUpload = []

      if (newFiles) {
        newFiles.forEach((newFile) => {
          const URL = window.URL || window.webkitURL

          documentsToUpload.push({
            type: newFile.type,
            size: newFile.size,
            error: newFile.error,
            isImage: this.isImage(newFile.type),
            blob: URL.createObjectURL(newFile.file),
            file: newFile.file,
            name: newFile.name
          })
        })
      }

      this.documentsData = {
        ...this.documentsData,
        documentsToUpload
      }
    }
  },
  methods: {
    clearUpload () {
      let completed = true

      if (this.$props.onClearUpload) {
        completed = this.$props.onClearUpload()
      }

      if (completed) {
        this.$refs[this.refName].clear()

        this.documentsData = {
          ...this.documentsData,
          documentsToUpload: []
        }
      }
    },
    isImage (type) {
      return String(type).indexOf('image/') > -1
    },
    getDocumentIcon (type) {
      if (this.isImage(type)) {
        return 'far fa-file-image'
      } else if (type === 'application/pdf') {
        return 'far fa-file-pdf'
      } else if (MIME_TYPE_GROUPS.AUDIO.includes(type)) {
        return 'far fa-file-audio'
      } else if (MIME_TYPE_GROUPS.VIDEO.includes(type)) {
        return 'far fa-file-video'
      } else if (['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(type)) {
        return 'far fa-file-word'
      } else if (['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(type)) {
        return 'far fa-file-excel'
      } else if (['application/vnd.ms-powerpoint'].includes(type)) {
        return 'far fa-file-powerpoint'
      } else {
        return 'far fa-file-alt'
      }
    },
    setMimeTypeGroup (key) {
      switch (key) {
        case 'all':
          this.acceptedMimeTypes = MIME_TYPE_GROUPS.ALL_DOCS
          break
        case 'images':
          this.acceptedMimeTypes = MIME_TYPE_GROUPS.IMAGES
          break
        case 'video':
          this.acceptedMimeTypes = MIME_TYPE_GROUPS.VIDEO
          break
        case 'audio':
          this.acceptedMimeTypes = MIME_TYPE_GROUPS.AUDIO
          break
        case 'docs':
          this.acceptedMimeTypes = MIME_TYPE_GROUPS.DOCS
          break
        case 'text':
          this.acceptedMimeTypes = MIME_TYPE_GROUPS.TEXT
          break
      }
    }
  }
}

const MIME_TYPE_GROUPS = {
  ALL_DOCS: ['*/*'],
  IMAGES: ['image/png', 'image/gif', 'image/jpeg', 'image/webp', 'application/pdf'],
  VIDEO: ['video/x-msvideo', 'video/mp4', 'video/mpeg', 'video/ogg', 'video/webm'],
  AUDIO: ['audio/mpeg', 'audio/ogg', 'audio/wav', 'audio/webm'],
  TEXT: ['text/plain'],
  DOCS: ['application/pdf', 'application/msword', 'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/rtf', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.oasis.opendocument.presentation', 'application/vnd.oasis.opendocument.spreadsheet',
    'application/vnd.oasis.opendocument.text']
}
