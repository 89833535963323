<template>
    <v-container fluid>
        <v-slide-y-transition mode="out-in">
            <v-layout column align-center>
                <div v-if="getSignedInUser || getSignedInUser === undefined" style="text-align: center">
                    <img src="@/assets/logo.png" style="width: 350px;" alt="SmartTransit" class="mb-5">
                </div>
                <div style="width: 400px" v-if="getSignedInUser === null">
                    <sign-in-form :after-login="afterLoginForward"></sign-in-form>
                </div>
            </v-layout>
        </v-slide-y-transition>
    </v-container>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

<script>
import src from './src'
export default src
</script>
