import { Loader } from '@googlemaps/js-api-loader'
import { mapApiRequests } from '@/utilities/axios-factory'
import store from '@/store'

let googleMapsApi, geocoderApi

async function initGoogleMapsApi () {
  if (!googleMapsApi) {
    const loader = new Loader({
      apiKey: process.env.VUE_APP_GOOGLE_GEOCODER_API_KEY,
      version: 'weekly',
      libraries: ['places']
    })

    const google = await loader.load()
    googleMapsApi = google.maps
  }
}

/**
 * initialize (if necessary) and make geocode request from Google's APIs
 * @param {string} address
 * @param {{longitude:number,latitude:number}} [location]
 * @returns {Promise<[]>}
 */
async function googleGeocodeRequest ({ address, location }) {
  await initGoogleMapsApi()

  if (!geocoderApi) {
    geocoderApi = new googleMapsApi.Geocoder()
  }

  // https://developers.google.com/maps/documentation/javascript/geocoding
  return new Promise((resolve, reject) => {
    geocoderApi.geocode({
      address,
      location: location ? new googleMapsApi.LatLng({ lat: location.latitude, lng: location.longitude }) : undefined // LatLng
    }, function (results, status) {
      if (status === 'OK' || status === 'ZERO_RESULTS') {
        resolve(results || [])
      } else {
        reject(new Error('Geocode was not successful for the following reason: ' + status))
      }
    })
  })
}

// eslint-disable-next-line no-unused-vars
/**
 * Request long,lat from browser level
 * @returns {Promise<{longitude:number,latitude:number}>}
 */
function getCoarseGeolocation () {
  const GeoLocation = window.navigator.geolocation

  return new Promise((resolve) => {
    GeoLocation.getCurrentPosition((GeolocationPosition) => {
      resolve({
        longitude: GeolocationPosition.coords.longitude, // simulate drift
        latitude: GeolocationPosition.coords.latitude
      })
    }, (err) => {
      console.log('Browser geolocation error', err)
      resolve()
    },
    {
      enableHighAccuracy: true,
      maximumAge: 0
    })
  })
}

async function getBusStopData ({ routerId, longitude, latitude, radius }) {
  const result = await mapApiRequests.get(`/v1/geo-location/router/${routerId}/stops`, {
    params: {
      long: longitude,
      lat: latitude,
      searchradius: radius
    },
    headers: { 'Authorization': store.getters.getTokenId }
  })

  return result?.data
}

async function getMapRegions () {
  const result = await mapApiRequests.get('/v1/geo-location/routers', {
    headers: { 'Authorization': store.getters.getTokenId }
  })

  return result?.data
}

/**
 * @param {object} options
 * @param {string} [options.routerId] - e.g. "accra"
 * @param {object} [options.params] - All the options available in http://dev.opentripplanner.org/apidoc/1.0.0/resource_PlannerResource.html can be sent as an object
 * @returns {Promise<any>}
 */
async function getRoute ({ routerId, params }) {
  const result = await mapApiRequests.get('/v1/geo-location/admin/routes/' + routerId + '/', {
    params: { params },
    headers: { 'Authorization': store.getters.getTokenId }
  })

  return result?.data
}

async function getRouteByRouteIdList (routeIdList) {
  const result = await mapApiRequests.get('/v1/geo-location/admin/routes/by-route-id-list/', {
    params: { routeIdList },
    headers: { 'Authorization': store.getters.getTokenId }
  })

  return result?.data
}

export {
  googleGeocodeRequest,
  getCoarseGeolocation,
  getBusStopData,
  getMapRegions,
  getRoute,
  getRouteByRouteIdList
}
