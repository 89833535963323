import _isNil from 'lodash.isnil'
import { C_SET_CACHE, C_SET_USER_CACHE, C_SET_BROWSER_SETTING } from '../utilities/mutation-types'
import {
  D_GET_FROM_CACHE,
  D_GET_FROM_USER_CACHE,
  D_SET_TO_CACHE,
  D_SET_TO_USER_CACHE,
  D_GET_FROM_BROWSER_SETTING
} from '../utilities/action-types'

const LOCAL_STORAGE_PREFIX = 'st_'
let cacheTimeoutHandles = {}
let userCacheTimeoutHandles = {}

function _setCache (cacheKey, handle, { value, expiryInSeconds }) {
  expiryInSeconds = expiryInSeconds === undefined ? 60000 : expiryInSeconds // defaults to one minute
  if (expiryInSeconds) {
    handle[cacheKey] = setTimeout(() => {
      if (handle[cacheKey]) {
        clearTimeout(handle[cacheKey])
        handle[cacheKey] = undefined
      }
      let currentCache = localStorage.getItem(cacheKey)
      currentCache = currentCache ? (currentCache.indexOf('[') === 0 || currentCache.indexOf('{') === 0 ? JSON.parse(currentCache) : currentCache) : null
      if (currentCache) {
        localStorage.setItem(cacheKey, '')
      }
      localStorage.removeItem(cacheKey)
    }, expiryInSeconds * 1000)
  }
  localStorage.setItem(cacheKey, value && typeof value === 'object' ? JSON.stringify(value) : (value || ''))
  if (!value) {
    localStorage.removeItem(cacheKey)
  }
}

export default {
  state: {
    browserSettings: {}
  },
  getters: {},
  mutations: {
    [C_SET_CACHE] (state, { key, value, expiryInSeconds }) {
      _setCache(key, cacheTimeoutHandles, { value, expiryInSeconds })
    },
    [C_SET_USER_CACHE] (state, { key, value, expiryInSeconds }) {
      _setCache(key, userCacheTimeoutHandles, { value, expiryInSeconds })
    },
    [C_SET_BROWSER_SETTING] (state, { key, value }) {
      const storageKey = LOCAL_STORAGE_PREFIX + 'setting_' + key
      const currentValue = localStorage.getItem(storageKey)
      if (_isNil(value)) {
        if (state.browserSettings[key]) {
          delete state.browserSettings[key]
        }
        if (currentValue) {
          localStorage.setItem(storageKey, '')
          localStorage.removeItem(storageKey)
        }
      } else {
        state.browserSettings[key] = value
        localStorage.setItem(storageKey, value && typeof value === 'object' ? JSON.stringify(value) : (value || ''))
      }
    }
  },
  actions: {
    [D_GET_FROM_CACHE] ({ state, rootState }, key) {
      const cacheKey = LOCAL_STORAGE_PREFIX + 'cache_' + key
      let value = localStorage.getItem(cacheKey)
      return Promise.resolve(value ? (value.indexOf('[') === 0 || value.indexOf('{') === 0 ? JSON.parse(value) : value) : null)
    },
    [D_GET_FROM_USER_CACHE] ({ state, getters }, key) {
      const accessToken = getters.getToken
      if (accessToken) {
        const cacheKey = `${LOCAL_STORAGE_PREFIX}user_cache_${accessToken.userId}_${key}`
        let value = localStorage.getItem(cacheKey)
        return Promise.resolve(value ? (value.indexOf('[') === 0 || value.indexOf('{') === 0 ? JSON.parse(value) : value) : null)
      } else {
        return Promise.reject(new Error(`No access token available to get user cache for key: ${key}`))
      }
    },
    [D_GET_FROM_BROWSER_SETTING] ({ state }, key) {
      const storageKey = `${LOCAL_STORAGE_PREFIX}setting_${key}`
      if (state.browserSettings[key]) {
        return state.browserSettings[key]
      }
      let value = localStorage.getItem(storageKey)
      return Promise.resolve(value ? (value.indexOf('[') === 0 || value.indexOf('{') === 0 ? JSON.parse(value) : value) : null)
    },
    [D_SET_TO_CACHE] ({ state, commit }, { key, value, expiryInSeconds }) {
      const cacheKey = LOCAL_STORAGE_PREFIX + 'cache_' + key
      commit(C_SET_CACHE, { key: cacheKey, value, expiryInSeconds })
      return Promise.resolve(true)
    },
    [D_SET_TO_USER_CACHE] ({ state, commit, getters, rootState }, { key, value, expiryInSeconds }) {
      const accessToken = getters.getToken
      if (accessToken) {
        const cacheKey = `${LOCAL_STORAGE_PREFIX}user_cache_${accessToken.userId}_${key}`
        commit(C_SET_USER_CACHE, { key: cacheKey, value, expiryInSeconds })
        return Promise.resolve(true)
      } else {
        return Promise.reject(new Error(`No access token available to set user cache for key: ${key}`))
      }
    }
  }
}
