import { getTimezones } from '../../services/country-service'
import { C_ADD_SITE_ALERT } from '../../utilities/mutation-types'

let cachedTimezones = []

export default {
  name: 'timezone-selector',
  props: {
    value: String,
    childProps: Object
  },
  data () {
    return {
      timezones: [],
      selectedTimezone: null,
      apiInProgress: false
    }
  },
  watch: {
    value (newVal) {
      this.selectedTimezone = newVal
    },
    selectedTimezone (newVal) {
      this.$emit('input', (newVal || '').trim())
    }
  },
  mounted: function () {
    this.initTimezones()
  },
  methods: {
    async initTimezones () {
      try {
        if (!cachedTimezones.length) {
          this.apiInProgress = true
          cachedTimezones = await getTimezones()
          this.apiInProgress = false
        }
        this.timezones = cachedTimezones ? cachedTimezones.timezones : []
        this.selectedTimezone = this.$props.value
      } catch (err) {
        this.apiInProgress = false
        this.$store.commit(C_ADD_SITE_ALERT, {
          message: `${err && err.error ? err.error.message : (err && err.message ? err.message : JSON.stringify(err))}`,
          type: 'error'
        })
      }
    }
  },
  beforeDestroy () {
  }
}
